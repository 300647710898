import axios from "axios";
import HttpService from "./HttpService.js";

export default class UserService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/users`;
    }

    async registerUser(data) {
        const response = await axios.post(this._urlBase, data);
        return response.data;
    }

    async getUser(id) {
        const response = await axios.get(`${this._urlBase}/id/${id}`);
        return response.data;
    }

    async getByDocument(document) {
        const response = await axios.get(`${this._urlBase}/get_by_document/${document}`);
        return response.data;
    }

    async refreshUser(id) {
        const response = await axios.get(`${this._urlBase}/refresh/${id}`);
        return response.data;
    }

    async updateUser(id, data) {
        const response = await axios.put(`${this._urlBase}/id/${id}`, data);
        return response.data;
    }

    async getStoresWithInServiceRadius(id) {
        const response = await axios.get(`${this._urlBase}/get_store_with_in_service_radius/${id}`);
        return response.data;
    }

    async getMyLicensedConnections(id, page) {
        const queryParams = new URLSearchParams();
        if (page) queryParams.append('page', page.toString());
        const response = await axios.get(`${this._urlBase}/get_my_licensed_connections/${id}?${queryParams.toString()}`);
        return response.data;
    }

    async requestLicensedConnection(id, storeId) {
        const response = await axios.post(`${this._urlBase}/request_licensed_connection/${id}/${storeId}`);
        return response.data;
    }

    async updateLicensedConnection(connectionId, status) {
        const response = await axios.put(`${this._urlBase}/update_licensed_connection/${connectionId}`, {
            "status": status
        });
        return response.data;
    }

    async destroyLicensedConnection(connectionId) {
        const response = await axios.delete(`${this._urlBase}/licensed_connection/${connectionId}`);
        return response.data;
    }

    async updateLicensedFees(id, licensedFee, serviceRadius) {
        const response = await axios.put(`${this._urlBase}/update_licensed_fees/${id}`, {
            "serviceRadius": serviceRadius,
            "licensedFee": licensedFee
        });
        return response.data;
    }
}
