import { useContext, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faLocationDot, faPhone, faPlus, faStore, faTruck } from "@fortawesome/free-solid-svg-icons";
import Conversor from "../../../../../helper/Conversor";
import './style.scss';
import StoresForm from "./components/StoresForm";
import OptionsMenu from "./components/OptionsMenu";

export default function Stores() {
    const { stores } = useContext(UserContext);
    const [showForm, setShowForm] = useState(false);

    function renderAddressNumber(number) {
        return number ? `, nº${number}` : `, s/N`;
    }

    function renderAddressComplement(complement) {
        return complement ? `, ${complement}, ` : `, `;
    }

    return (
        <div className="shopkeeper-stores-container">
            {!showForm && 
            <div className="header-stores">
                <button type="button" onClick={() => setShowForm(true)}><FontAwesomeIcon icon={faPlus} style={{ height: 14, marginRight: 5 }} />Adicionar filial</button>
            </div>
            }
            {showForm ? <StoresForm onClose={() => setShowForm(false)}/> : 
            <div className="stores">
                {stores && stores.map((store) => (
                    <div className="store-box" key={store._id}>
                        <div className="store-profile">
                            <FontAwesomeIcon icon={faStore} />
                        </div>
                        <div className="store-details">
                            <div className="store-info"><FontAwesomeIcon icon={faStore} />{store.companyName}</div>
                            <div className="store-info"><FontAwesomeIcon icon={faIdCard} />{Conversor.converterCNPJ(store.cnpj)}</div>
                            <div className="store-info"><FontAwesomeIcon icon={faPhone} />{Conversor.converterFormatoTelefone(String(store.cellNumber))}</div>
                            <div className="store-info"><FontAwesomeIcon icon={faTruck} />Modo {store.mode === "CLOUD" ? "Nuvem" : "Parceiro"}</div>
                            <div className="store-info"><FontAwesomeIcon icon={faLocationDot} />
                                <span>
                                    {
                                        store.address.street +
                                        renderAddressNumber(store.address.number) + " - " +
                                        store.address.neighborhood +
                                        renderAddressComplement(store.address.complement) +
                                        store.address.city
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="store-actions">
                            <OptionsMenu store={store} />
                        </div>
                    </div>
                ))}
            </div>
        }
        </div>
    );
}