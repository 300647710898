import HttpService from "./HttpService.js";

export default class AuthService {
    constructor() {
        this._http = new HttpService();
        this._baseUrl = `${process.env.REACT_APP_DELIVERIES_API}/auth`;
    }

    async forgotPassword(email) {
        const response = await this._http.post(`${this._baseUrl}/forgot_password`, { "email": email });
        return response;
    }

    async checkToken(email, token) {
        const response = await this._http.post(`${this._baseUrl}/check_token`, { "email": email, "token": token });
        return response;
    }

    async resetPassword(email, token, password) {
        const response = await this._http.post(`${this._baseUrl}/reset_password`, { "email": email, "token": token, "password": password });
        return response;
    }
}
