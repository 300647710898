import React, { useContext } from 'react';
import '../assets/scss/filters.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../../context/UserContext';
import { FormControl, InputLabel, MenuItem, Select, TextField, Button, Checkbox, ListItemText } from '@mui/material';

const OrderFilters = ({ onApplyFilters, filters, setFilters, setPagination }) => {
    const { user, stores, selectedStore } = useContext(UserContext);
    const actualDate = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).format(new Date()).split('/').reverse().join('-');

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const formattedTomorrow = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).format(tomorrow).split('/').reverse().join('-');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPagination((prev) => ({
            ...prev,
            currentPage: 1,
        }));
        setFilters((prev) => ({
            ...prev,
            page: 1,
        }));
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const cleanFilters = () => {
        onApplyFilters({
            storeId: selectedStore,
            startDate: actualDate,
            endDate: formattedTomorrow,
            deliveryCode: "",
            customerName: "",
            page: 1
        });
    }

    const handleChangeStore = (event) => {
        const { value } = event.target;

        // Se "TODAS" for selecionada, define todas as lojas, senão mantém a seleção
        setPagination((prev) => ({
            ...prev,
            currentPage: 1,
        }));
        setFilters((prev) => ({
            ...prev,
            page: 1,
        }));
        if (value.includes("ALL")) {
            setFilters((prev) => ({
                ...prev,
                storeId: filters.storeId.length === stores.length
                    ?
                    user.type === "SHOPKEEPER" ? [selectedStore._id] : []
                    :
                    stores.map((store) => store._id)
            }));
        } else {
            setFilters((prev) => ({
                ...prev,
                storeId: value
            }));
        }
    };

    return (
        <div className="filters">
            <div className="filters-fields">
                {user.type !== "AFFILIATE" &&
                <FormControl fullWidth size="small">
                    <InputLabel>Loja</InputLabel>
                    <Select
                        label="Loja"
                        multiple
                        value={filters.storeId}
                        onChange={handleChangeStore}
                        renderValue={(selected) =>
                            selected.length === stores.length ? "TODAS" : selected.map(id => stores.find(store => store._id === id)?.companyName).join(", ")
                        }
                    >
                        <MenuItem value="ALL">
                            <Checkbox checked={filters.storeId.length === stores.length} />
                            <ListItemText primary="TODAS" />
                        </MenuItem>
                        {stores.map((store) => (
                            <MenuItem key={store._id} value={store._id}>
                                <Checkbox checked={filters.storeId.includes(store._id)} />
                                <ListItemText primary={store.companyName} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                }
                <TextField
                    label="Data Inicial"
                    type="date"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleChange}
                />

                <TextField
                    label="Data Final"
                    type="date"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleChange}
                />

                <TextField
                    label="Código do Pedido"
                    fullWidth
                    size="small"
                    name="deliveryCode"
                    value={filters.deliveryCode}
                    onChange={handleChange}
                    placeholder="Digite o código"
                />

                <TextField
                    label="Nome do Cliente"
                    fullWidth
                    size="small"
                    name="customerName"
                    value={filters.customerName}
                    onChange={handleChange}
                    placeholder="Digite o nome do cliente"
                />
            </div>

            <div className="filters-buttons">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={cleanFilters}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
            </div>
        </div>
    );
};

export default OrderFilters;
