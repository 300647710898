import React from "react";
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faBoxesPacking, faBoxOpen, faCheckSquare, faTruckLoading, faUserCheck } from '@fortawesome/free-solid-svg-icons';

const Notification = (props) => {
    try {
        const notificationData = props.notificationData;
        const order = notificationData.order;
        const deliveryMan = notificationData.deliveryman ? notificationData.deliveryman : null;
        const eventType = notificationData.eventType;  
        const isIFood = order.orderDetails.isIfood ? true : false;
    
        const event_map = {
            "NEW_ORDER": `Pedido #${order.orderDetails.deliveryCode} confirmado`,
            "CANCEL_ORDER": `Pedido #${isIFood ? order.orderDetails.ifoodDetails.displayId : order.orderDetails.deliveryCode} foi cancelado`,
            "ACCEPT_ORDER": `Entrega #${order.orderDetails.deliveryCode} foi aceita por ${deliveryMan ? deliveryMan.name : ''}`,
            "ARRIVED_ORDER": `O Entregador ${deliveryMan ? deliveryMan.name : ''} chegou na loja para retirar a entrega #${order.orderDetails.deliveryCode}`,
            "WITHDRAWN_ORDER": `O Entregador ${deliveryMan ? deliveryMan.name : ''} retirou a entrega #${order.orderDetails.deliveryCode}`,
            "DELIVERED_ORDER": `O Entregador ${deliveryMan ? deliveryMan.name : ''} concluiu a entrega #${order.orderDetails.deliveryCode}`
        }
    
        const icon_map = {
            "NEW_ORDER": <FontAwesomeIcon icon={faBoxesPacking} color='#168821ff' style={{ height: 15, marginRight: 10 }} />,
            "CANCEL_ORDER": <FontAwesomeIcon icon={faBan} color='#e52207ff' style={{ height: 15, marginRight: 10 }} />,
            "ACCEPT_ORDER": <FontAwesomeIcon icon={faUserCheck} color='#168821ff' style={{ height: 15, marginRight: 10 }} />,
            "ARRIVED_ORDER": <FontAwesomeIcon icon={faBoxOpen} color='#FFA500' style={{ height: 15, marginRight: 10 }} />,
            "WITHDRAWN_ORDER": <FontAwesomeIcon icon={faTruckLoading} color='#155bcbff' style={{ height: 15, marginRight: 10 }} />,
            "DELIVERED_ORDER": <FontAwesomeIcon icon={faCheckSquare}  color='#168821ff' style={{ height: 15, marginRight: 10 }} />
        }
    
        return (
            <div className="notification-container">
                <p className="notification-title">{icon_map[eventType]}Aviso</p>
                <p className="notification-text">{event_map[eventType]}</p>
            </div>
        );
    } catch (error) {
        console.error(error);
    }
};

export default Notification;
