import { useContext, useEffect, useMemo, useState } from "react";
import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrash, faMoneyBillTransfer, faMoneyBillTrendUp, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../context/UserContext";
import StoreService from "../../../../../services/StoreService";
import AdminService from "../../../../../services/AdminService";
import Swal from "sweetalert2";
import CreateDeliveryFee from "../ManageFees/components/CreateDeliveryFee";
import Conversor from "../../../../../helper/Conversor";
import { Oval } from "react-loader-spinner";
import { CurrencyInput } from 'react-currency-mask';
import { TextField, Tooltip } from '@mui/material';

export default function ManagePlatformFees(props) {
    const { stores } = useContext(UserContext);

    const cloudStore = stores.find(st => st.mode === "CLOUD");

    if (!cloudStore)
        props.handleBackMenu();

    const [spinner, setSpinner] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tarrifs, setTarrifs] = useState({
        ratePerKm: 0,
        serviceFee: 0,
        fees: []
    });

    const [modalOpen, setModalOpen] = useState(false);

    const storeService = useMemo(() => new StoreService(), []);
    const adminService = useMemo(() => new AdminService(), [])

    async function loadDeliveryFees() {
        if (loading)
            return;

        setLoading(true);
        setSpinner(true);

        const response = await storeService.getDeliveryFees(cloudStore._id);
        setTimeout(() => {
            setTarrifs(response.tariffs);
            setSpinner(false);
        }, 1000);
    }

    async function submitDeliveryFees() {
        const result = await Swal.fire({
            title: "Você deseja salvar as novas tarifas?",
            text: "Todas as lojas que estão no modo nuvem serão afetadas",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, confirmar!",
            confirmButtonColor: "#EA1D2C",
            cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
            setSpinner(true);
            await adminService.storeDeliveryFees(tarrifs);
            setTimeout(() => {
                Swal.fire({
                    title: 'Tarifas definidas com sucesso!',
                    text: 'As novas tarifas começam a valer a partir de agora',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                });
                setSpinner(false);
                setLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        loadDeliveryFees();
        // eslint-disable-next-line
    }, [loading]);

    function addDeliveryFee(fee) {
        console.log(tarrifs);
        setTarrifs(prevState => ({
            ...prevState,
            fees: [...prevState.fees, fee]
        }));
    }

    function removeDeliveryFee(indexToRemove) {
        setTarrifs(prevState => ({
            ...prevState,
            fees: prevState.fees.filter((_, index) => index !== indexToRemove)
        }));
    }

    return (
        <div className={`platform-fees-container ${spinner ? `loading-integration` : ``}`}>
            <div className="fees-header">
                <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <div className="fees-title">Tarifas da Plataforma</div>
            </div>
            {(spinner) ? (
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            ) : (
                <div className="fees-content">
                    <div className="delivery-fees-inputs">
                        <div className="fee-input">
                            <CurrencyInput
                                value={tarrifs.ratePerKm}
                                onChangeValue={(event, originalValue, maskedValue) => {
                                    setTarrifs(prev => ({
                                        ...prev,
                                        ratePerKm: originalValue > 0 ? originalValue : 0.01
                                    }));
                                }}
                                InputElement={<TextField label="Tarifa por KM" size="small" />}
                            />
                            <Tooltip title="Caso a loja não tiver uma regra de tarifa para aquela corrida, utiliza a tarifa por KM" arrow>
                                <span>
                                    <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                                </span>
                            </Tooltip>
                        </div>
                        <div className="fee-input">
                            <CurrencyInput
                                value={tarrifs.serviceFee}
                                onChangeValue={(event, originalValue, maskedValue) => {
                                    setTarrifs(prev => ({
                                        ...prev,
                                        serviceFee: originalValue > 0 ? originalValue : 0.01
                                    }));
                                }}
                                InputElement={<TextField label="Taxa de Serviço" size="small" />}
                            />
                            <Tooltip title="Essa é sua taxa de serviço de uso da plataforma Motum" arrow>
                                <span>
                                    <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                                </span>
                            </Tooltip>
                        </div>
                        <div className="delivery-fees-actions">
                            <button type="button" className="add-fee-btn" onClick={() => setModalOpen(true)}>
                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                Adicionar Tarifa
                            </button>
                            <button type="button" className="submit-fees-btn" onClick={submitDeliveryFees}>
                                <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                                Submeter Tarifas
                            </button>
                        </div>
                    </div>
                    <div className="delivery-fees-list">
                        <h4>Tarifas das Lojas</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Distância</th>
                                    <th>Valor</th>
                                    <th className="td-actions">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tarrifs && tarrifs.fees.map((deliveryFee, index) => (
                                    <tr key={index}>
                                        <td>{deliveryFee.minDistance}km - {deliveryFee.maxDistance}km</td>
                                        <td>{Conversor.converterStringReal(deliveryFee.fee)}</td>
                                        <td className="td-actions">
                                            <button type="button" className="fee-remove-btn" onClick={() => removeDeliveryFee(index)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <CreateDeliveryFee
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={addDeliveryFee}
            />

        </div>
    );
}