import axios from "axios";
import HttpService from "./HttpService.js";

export default class StoreService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/store`;
    }

    async getStores() {
        const storeResponse = await this._http.get(this._urlBase);
        return storeResponse;
    }

    async showStore(id) {
        const storeResponse = await this._http.get(this._urlBase+`/${id}`);
        return storeResponse;
    }

    async registerStore(data) {
        const resposta = await fetch(this._urlBase, {
            headers: { "Content-type": "application/json", "Access-Control-Allow-Origin": "*" },
            method: "POST",
            body: JSON.stringify(data),
        });
        return resposta.json();
    }

    async updateStore(store) {
        const response = await axios.put(`${this._urlBase}/${store._id}`, store);
        return response.data;
    }

    async removeStore(id) {
        const response = await this._http.delete(this._urlBase+`/${id}`);
        return response;
    }

    async myDrivers(id, page) {
        const response = await this._http.get(`${this._urlBase}/get_my_drivers/${id}/${page}`);
        return response;
    }

    async getMyConnection(id) {
        const response = await this._http.get(`${this._urlBase}/get_my_licensed_connection/${id}`);
        return response;
    }

    async updateConnectionStatus(id, status) {
        const response = await this._http.put(`${this._urlBase}/update_connection_status/${id}/${status}`);
        return response;
    }

    async removeConnection(id) {
        const response = await this._http.delete(`${this._urlBase}/remove_connection/${id}`);
        return response;
    }

    async getVacancy(id) {
        const response = await this._http.get(`${this._urlBase}/get_vacancy/${id}`);
        return response;
    }

    async createVacancy(id, data) {
        const response = await this._http.post(`${this._urlBase}/create_vacancy/${id}`, data);
        return response;
    }

    async updateVacancy(id, data) {
        const response = await this._http.put(`${this._urlBase}/update_vacancy/${id}`, data);
        return response;
    }

    async getDeliveryFees(id) {
        const response = await this._http.get(`${this._urlBase}/get_delivery_fees/${id}`);
        return response;
    }

    async storeDeliveryFees(id, tarrifs) {
        const response = await this._http.post(`${this._urlBase}/create_delivery_fees/${id}`, tarrifs);
        return response;
    }
}
