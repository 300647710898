import React from "react";

const CircularProgress = ({
  score,
  minScore = 0,
  maxScore = 100,
  level,
  size = 120,
  strokeWidth = 12
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  // Normaliza o score para o intervalo definido
  const normalizedScore = Math.max(minScore, Math.min(score, maxScore));
  const progress = ((normalizedScore - minScore) / (maxScore - minScore)) * circumference;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Fundo da barra */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="#e4e4e4"
        strokeWidth={strokeWidth}
        fill="none"
      />
      {/* Barra de progresso */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="#1a1a1a"
        strokeWidth={strokeWidth}
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={circumference - progress}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      {/* Nível no topo */}
      <text
        x="50%"
        y="35%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="14"
        fontWeight="bold"
        fill="gray"
      >
        Nível {level}
      </text>
      {/* Score no centro */}
      <text
        x="50%"
        y="55%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="28"
        fontWeight="bold"
        fill="black"
      >
        {normalizedScore}
      </text>
      <text
        x="50%"
        y="70%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="12"
        fontWeight="bold"
        fill="gray"
      >
        Pontos
      </text>
    </svg>
  );
};

export default CircularProgress;
