import { useContext, useState } from 'react';
import './style.scss';
import Integrations from './components/Integrations/Integrations';
import Drivers from './components/Drivers/Drivers';
import DeliveryFees from './components/DeliveryFees/DeliveryFees';
import { UserContext } from '../../context/UserContext';
import MyStores from './components/MyStores/MyStores';
import LicensedConnection from './components/LicensedConnection/LicensedConnection';
import Stores from './components/Stores/Stores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faNetworkWired, faStore, faTruck, faUserTie } from '@fortawesome/free-solid-svg-icons';

export default function Config() {
    const { user } = useContext(UserContext);
    const [content, setContent] = useState("INTEGRATIONS");

    function handleContent(content) {
        setContent(content);
    }

    return (
        <div className="content-container config-container">
            <div className="config-nav-bar">
                <div className="config-nav-item" onClick={() => handleContent("INTEGRATIONS")}>
                    <FontAwesomeIcon icon={faNetworkWired} />
                    Integrações
                </div>
                <div className="config-nav-item" onClick={() => handleContent("DRIVERS")}>
                    <FontAwesomeIcon icon={faTruck} />
                    Entregadores
                </div>
                <div className="config-nav-item" onClick={() => handleContent("DELIVERY_FEES")}>
                    <FontAwesomeIcon icon={faMoneyBill} />
                    Tarifas
                </div>
                {user.type !== "AFFILIATE" &&
                <div className="config-nav-item" onClick={() => handleContent("STORES")}>
                <FontAwesomeIcon icon={faStore} />
                    Filiais
                </div>
                }
                {(user.type === "LICENSED" || user.type === "HUB") &&
                    <div className="config-nav-item" onClick={() => handleContent("MY_STORES")}>
                        <FontAwesomeIcon icon={faStore} />
                        Lojas Licenciadas
                    </div>
                }
                {(user.type === "SHOPKEEPER") &&
                    <div className="config-nav-item" onClick={() => handleContent("MY_CONNECTION")}>
                        <FontAwesomeIcon icon={faUserTie}/>
                        Meu Licenciado
                    </div>
                }
            </div>
            <div className="config-content">
                {content === "INTEGRATIONS" && <Integrations />}
                {content === "DRIVERS" && <Drivers />}
                {content === "DELIVERY_FEES" && <DeliveryFees />}
                {content === "STORES" && <Stores />}
                {content === "MY_STORES" && <MyStores />}
                {content === "MY_CONNECTION" && <LicensedConnection />}
            </div>
        </div>
    );
}