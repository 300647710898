const status_map = {
    return: "Retornando a loja",
    delivered: "Concluído",
    withdrawn: "Saiu para entrega",
    arrived: "Em Retirada",
    accept: "Entrega aceita",
    pending: "Aguardando entregador",
    unconfirmed: "Em análise",
    canceled: "Cancelado",
};

const status_colors = {
    return: "#B3E0FF",
    unconfirmed: "#6c757d",
    delivered: "#28a745",
    withdrawn: "#007bff",
    arrived: "#ffc107",
    accept: "#fd7e14",
    pending: "#6c757d",
    canceled: "#dc3545",
};

const payment_map = {
    "credit_card": "CRÉDITO",
    "debit_card": "DÉBITO",
    "Cartão de Crédito": "CRÉDITO",
    "Cartão de Débito": "DÉBITO",
    "cash": "DINHEIRO",
    "paid": "ONLINE",
    "credito": "CRÉDITO",
    "debito": "DÉBITO",
    "dinheiro": "DINHEIRO",
    "Dinheiro": "DINHEIRO",
    "Pago": "ONLINE"
};

const statusMap = {
    "PLACED": <strong style={{ color: "#EA1D2C" }}>Pendente</strong>,
    "CONFIRMED": <strong style={{ color: "#50A773" }}>Pedido em preparo</strong>,
    "DISPATCHED": <strong style={{ color: "#50A773" }}>Despachado</strong>,
    "CONCLUDED": <strong style={{ color: "#000" }}>Concluído</strong>,
    "CANCELLED": <strong style={{ color: "#000" }}>Cancelado</strong>,
    "READY_TO_PICKUP": <strong style={{ color: "#50A773" }}>Pronto</strong>,
}

const anotaAiStatusMap = {
    0: <strong style={{ color: "#EA1D2C" }}>Pendente</strong>,
    1: <strong style={{ color: "#50A773" }}>Pedido em preparo</strong>,
    2: <strong style={{ color: "#50A773" }}>Pronto</strong>,
    3: <strong style={{ color: "#000" }}>Concluído</strong>,
    4: <strong style={{ color: "#000" }}>Cancelado</strong>,
}

const methodMap = {
    "CASH": "Dinheiro",
    "money": "Dinheiro",
    "card": "Cartão",
    "pix": "PIX",
    "CREDIT": "Crédito",
    "DEBIT": "Débito",
    "MEAL_VOUCHER": "Vale-refeição",
    "FOOD_VOUCHER": "Vale-alimentação",
    "GIFT_CARD": "Cartão presente",
    "DIGITAL_WALLET": "Carteira digital",
    "PIX": "PIX",
    "OTHER": "Outro"
}

const benefitMap = {
    "IFOOD": "O iFood pagará este valor para a sua loja",
    "MERCHANT": "Valores cobrados da sua loja"
}

export { status_map, status_colors, payment_map, statusMap, methodMap, benefitMap, anotaAiStatusMap };