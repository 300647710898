import axios from 'axios';
import CaracteresEspeciais from '../helper/CaracteresEspeciais.js';
import Address from '../model/Address.js';
import HttpService from './HttpService.js';

export default class CepService {
  constructor() {
    this._http = new HttpService();
    this._urlBase = 'https://viacep.com.br/ws/';
    this._formato = '/json';
  }

  async buscarEndereco(cep) {
    const cepSemMascara = this._removeMascara(cep);
    const jsonResultado = await this._http.get(this._urlBase + cepSemMascara + this._formato);
    if (jsonResultado.erro) throw new Error('CEP informafo não é valido');
    return this._createEnderecoFromJson(jsonResultado);
  }

  async buscarLatitudeLongitude(address) {
    const url = `${process.env.REACT_APP_DELIVERIES_API}/getLatLng?cep=${address.cep}&cidade=${CaracteresEspeciais.remover(address.city)}&bairro=${CaracteresEspeciais.remover(address.neighborhood)}&estado=${address.state}&numero=${address.number}&rua=${CaracteresEspeciais.remover(address.street)}`;
    const response = await axios.get(url);
    const lat = response.data.results[0].geometry.location.lat;
    const lng = response.data.results[0].geometry.location.lng;
    return [lat, lng];
  }

  _createEnderecoFromJson(json) {
    return new Address(json.cep, json.localidade, json.bairro, json.logradouro, '', json.uf, '');
  }

  _removeMascara(cep) {
    return cep.replace('-', '');
  }
}
