import { useContext, useEffect, useMemo, useState } from "react";
import StoreService from "../../../../../services/StoreService";
import { UserContext } from "../../../../context/UserContext";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faChevronLeft, faChevronRight, faClock, faPhone, faTruckFast, faWarning } from "@fortawesome/free-solid-svg-icons";
import helmetIcon from "../../../OrderManagement/assets/img/capacete-desenho.png";
import Conversor from "../../../../../helper/Conversor";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import CreateVacancy from "./components/CreateVacancy";
import '../../assets/scss/drivers.scss';


export default function Drivers(props) {
    const { selectedStore } = useContext(UserContext);
    const [formData, setFormData] = useState({
        status: "OPEN",
        observation: ""
    });
    const [vacancy, setVacancy] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [connections, setConnections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalConnections: 0,
        totalPages: 1,
    });

    const storeService = useMemo(() => new StoreService(), []);

    const previousPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage - 1
        }));
        setLoading(false);
    }

    const nextPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage + 1
        }));
        setLoading(false);
    }

    async function loadConnections() {
        if (loading)
            return;

        setLoading(true);
        setSpinner(true);

        const response = await storeService.myDrivers(selectedStore._id, pagination.currentPage);

        const vacancy = await storeService.getVacancy(selectedStore._id);

        setTimeout(() => {
            if (vacancy.observation !== undefined) {
                setFormData(vacancy);
                setVacancy(vacancy);
            }
            setConnections(response.data);
            setPagination(response.pagination);
            setSpinner(false);
        }, 1000);
    }

    async function updateConnectionStatus(id, status) {
        const result = await Swal.fire({
            title: `Você deseja ${status === "DISAPPROVE" ? "reprovar" : "aprovar"} esta conexão?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Sim, ${status === "DISAPPROVE" ? "reprovar" : "aprovar"}!`,
            confirmButtonColor: `${status === "DISAPPROVE" ? "#EA1D2C" : "#50A773"}`,
            cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
            setSpinner(true);
            await storeService.updateConnectionStatus(id, status);
            setTimeout(() => {
                Swal.fire({
                    title: `Conexão atualizada com sucesso`,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                });
                setSpinner(false);
                setLoading(false);
            }, 1000);
        }

    }

    async function removeConnection(id) {
        const result = await Swal.fire({
            title: "Você deseja remover essa conexão?",
            text: "O entregador terá que solicitar novamente numa próxima oportunidade",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, remover!",
            confirmButtonColor: "#EA1D2C",
            cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
            setSpinner(true);
            await storeService.removeConnection(id);
            setTimeout(() => {
                Swal.fire({
                    title: `Conexão removida com sucesso`,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                });
                setSpinner(false);
                setLoading(false);
            }, 1000);
        }
    }

    async function handleCreateVacancy(data) {
        setSpinner(true);

        if (vacancy)
            await storeService.updateVacancy(vacancy._id, data);
        else
            await storeService.createVacancy(selectedStore._id, data);
        setTimeout(() => {
            Swal.fire({
                title: `Vaga anunciada com sucesso`,
                text: "Os entregadores agora podem visualizar seu anúncio",
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            });
            setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        loadConnections();
        // eslint-disable-next-line
    }, [loading]);

    return (
        <div className={`my-drivers-container ${spinner ? `loading-integration` : ``}`}>
            {spinner ? (
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            ) : (
                <div className="connection-list">
                    <div className="vacancy">
                        <button type="button" className="show-vacancy-btn" onClick={() => setModalOpen(true)}>{vacancy ? "Visualizar Vaga" : "Anunciar Vaga"}</button>
                    </div>
                    <div className="connection-box-list">
                        {connections.map((connection) => (
                            <div className="connection-box">
                                <div className="driver-img">
                                    <div className="profile">
                                        <img src={helmetIcon} alt="Ícone de capacete" />
                                    </div>
                                </div>
                                <div className="driver-details">
                                    <div className="driver-name">
                                        {connection.driver.name}
                                        {connection.status === "PENDING" ?
                                            <Tooltip title="Essa conexão está pendente de aprovação" arrow>
                                                <span>
                                                    <FontAwesomeIcon icon={faWarning} color="#DBA827" />
                                                </span>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Clique aqui para remover a conexão" arrow onClick={() => removeConnection(connection._id)}>
                                                <span>
                                                    <FontAwesomeIcon icon={faBan} color="#EA1D2C" />
                                                </span>
                                            </Tooltip>
                                        }
                                    </div>
                                    <div className="driver-cell"><FontAwesomeIcon icon={faPhone} />{Conversor.converterFormatoTelefone(String(connection.driver.cellNumber))}</div>
                                    <div className="driver-count-deliveries"><FontAwesomeIcon icon={faTruckFast} />{connection.driver.metadata.deliveredOrders} entregas realizadas</div>
                                    <div className="driver-count-deliveries"><FontAwesomeIcon icon={faClock} />{connection.driver.metadata.drivingTime.hours} horas dirigidas</div>
                                    <div className="driver-actions">
                                        {connection.status === "PENDING" && <button className="accept-driver" type="button" onClick={() => updateConnectionStatus(connection._id, "DISCONNECTED")}>Aceitar</button>}
                                        {connection.status === "PENDING" && <button className="refuse-driver" type="button" onClick={() => updateConnectionStatus(connection._id, "DISAPPROVE")}>Recusar</button>}
                                        {connection.status === "CONNECTED" && <div className="connected-driver">CONECTADO</div>}
                                        {connection.status === "DISCONNECTED" && <div className="disconnected-driver">DESCONECTADO</div>}
                                        {connection.status === "DISAPPROVE" && <div className="disapprove-driver">REPROVADO</div>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
            }
            <div className="pagination-container">
                {pagination.currentPage > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                {pagination.totalPages > 1 && <span>{pagination.currentPage}</span>}
                {pagination.currentPage < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
            </div>
            <CreateVacancy
                formData={formData}
                setFormData={setFormData}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleCreateVacancy}
                isUpdated={vacancy != null}
            />
        </div>
    );
}