import React, { useContext, useMemo, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StoreService from "../../../../../../services/StoreService";
import Swal from "sweetalert2";
import { UserContext } from "../../../../../context/UserContext";
import UserService from "../../../../../../services/UserService";
import UserModal from "./UserModal";

const OptionsMenu = (props) => {
    const { removeStore } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [userModal, setUserModal] = useState(false);
    const [user, setUser] = useState(null);
    const open = Boolean(anchorEl);
    const store = props.store;

    const storeService = useMemo(() => new StoreService(), []);
    const userService = useMemo(() => new UserService(), []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showUser = async () => {
        handleClose();
        try {
            const user = await userService.getByDocument(store.cnpj);
            if (user) {
                setUser(user);
                setUserModal(true);
            }
        } catch (error) {
            Swal.fire({
                title: `Ocorreu um erro ao exibir o usuário`,
                text: "Certifique-se de que essa loja não é a matriz ou contate o suporte",
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            });
        }

    }

    const destroy = async () => {
        handleClose();
        const result = await Swal.fire({
            title: `Deseja excluir a filial ${store.companyName}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Voltar',
        });
        if (result.isConfirmed) {
            try {
                await storeService.removeStore(store._id);
                removeStore(store._id);
    
                setTimeout(() => {
                    Swal.fire({
                        title: `Filial removida com sucesso`,
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                    });
                }, 1000);
            } catch (error) {
                Swal.fire({
                    title: `Ocorreu um erro ao remover a filial`,
                    text: error.message,
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                });
            }

        }
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={showUser}>Detalhes do usuário</MenuItem>
                <MenuItem onClick={destroy}>Excluir</MenuItem>
            </Menu>
            {user && <UserModal user={user} openModal={userModal} setOpenModal={setUserModal}/>}
        </div>
    );
};

export default OptionsMenu;
