import axios from "axios";
import HttpService from "./HttpService.js";

export default class IntegrationService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/integration`;
    }

    async getIntegrations() {
        const response = await axios.get(this._urlBase+"/");
        return response;
    }

    async getIntegrationsByUser(page, stores) {
        const queryParams = new URLSearchParams();
        if (page) queryParams.append('page', page.toString());

        const url = `${this._urlBase}/support?${queryParams.toString()}`;
        const response = await axios.get(url, {
            params: { stores }
        });
        return response.data;
    }

    async createIntegration(data) {
        const response = await axios.post(this._urlBase+"/", data);
        return response;
    }

    async updateIntegrationStatus(integrationId, status) {
        const response = await axios.put(`${this._urlBase}/${integrationId}/${status}`);
        return response;
    }

    async enableAndDisable(id, active) {
        const response = await axios.put(`${this._urlBase}/enable_disable`, {
            "id": id,
            "active": active
        });
        return response;
    }

    async destroy(integrationId) {
        const response = await axios.delete(`${this._urlBase}/${integrationId}`);
        return response;
    }
}
