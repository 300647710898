import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [stayConnected, setStayConnected] = useState(false);

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    return storedToken ? JSON.parse(storedToken) : "";
  });

  const [stores, setStores] = useState(() => {
    const storedStores = localStorage.getItem('stores');
    return storedStores ? JSON.parse(storedStores) : [];
  });

  const [selectedStore, setSelectedStore] = useState(() => {
    const storedStore = localStorage.getItem('store');
    return storedStore ? JSON.parse(storedStore) : null;
  });

  const [hideSidebar, setHideSidebar] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? false : true;
  });

  useEffect(() => {
    // Salva o usuário no localStorage sempre que ele for atualizado
    if (stayConnected) { // manter conectado?
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user, stayConnected]);

  useEffect(() => {
    // Salva o usuário no localStorage sempre que ele for atualizado
    if (stayConnected) { // manter conectado?
      localStorage.setItem('store', JSON.stringify(selectedStore));
    }
  }, [stayConnected, selectedStore]);

  useEffect(() => {
    // Salva o usuário no localStorage sempre que ele for atualizado
    if (stayConnected) { // manter conectado?
      localStorage.setItem('stores', JSON.stringify(stores));
    }
  }, [stayConnected, stores]);

  const updateStayConnected = (isStayConnected) => {
    setStayConnected(isStayConnected);
  }

  const updateStore = (id) => {
    console.log('updaStore', id);
    localStorage.removeItem('store');
    setSelectedStore(stores[stores.findIndex(store => store._id === id)]);
    localStorage.setItem('store', JSON.stringify(stores[stores.findIndex(store => store._id === id)]));
  }

  const updateUser = (userData, refresh = false) => {
    setHideSidebar(false);
    setUser(userData.user);
    if (!refresh) setToken(userData.token);
    setStores(userData.stores);
    if (userData.user.type === "SHOPKEEPER" || userData.user.type === "HUB" || userData.user.type === "LICENSED")
      setSelectedStore(userData.stores.find(st => st.userId === userData.user._id));
    else
      setSelectedStore(userData.stores[0]);
    if (stayConnected) { // manter conectado?
      localStorage.setItem('user', JSON.stringify(user));
      if (!refresh) localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('store', JSON.stringify(selectedStore));
      localStorage.setItem('stores', JSON.stringify(stores));
    }
  };

  useEffect(() => {
    localStorage.removeItem('stores');
    localStorage.setItem('stores', JSON.stringify(stores));
  }, [stores]);

  const addStore = (store) => {
    setStores((prev) => [...prev, store]);
  }

  const removeStore = (store) => {
    setStores((prev) => [...prev.filter(st => st._id !== store._id)]);
  }

  return (
    <UserContext.Provider value={{ user, token, stores, selectedStore, hideSidebar, setHideSidebar, updateUser, updateStore, updateStayConnected, setUser, addStore, removeStore }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
