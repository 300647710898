import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { faBan, faChartSimple, faEnvelope, faHeadset, faIdCard, faMoneyBills, faMoneyBillTrendUp, faNetworkWired, faPhone, faUser, faUserCheck, faUserPen, faWallet, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useMemo, useState } from 'react';
import AdminService from '../../../services/AdminService';
import PendingUsers from './components/PendingUsers/PendingUsers';
import Integrations from './components/Integrations/Integrations';
import ManageUsers from './components/ManageUsers/ManageUsers';
import ManageWallet from './components/ManageWallet/ManageWallet';
import RegisterSupport from './components/RegisterSupport/RegisterSupport';
import Reports from './components/Reports/Reports';
import Select from 'react-select';
import Swal from 'sweetalert2';
import Logs from './components/Logs/Logs';
import { UserContext } from '../../context/UserContext';
import ManageFees from './components/ManageFees/ManageFees';
import Conversor from '../../../helper/Conversor';
import CircularProgress from './components/CircularProgress';
import ManagePlatformFees from './components/ManagePlatformFees/ManagePlatformFees';

export default function Admin(props) {
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const { user } = useContext(UserContext);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [selectedPage, setSelectedPage] = useState("MENU");
    const adminService = useMemo(() => new AdminService(), []);

    async function loadUsers() {
        if (loadingUsers) {
            return;
        }
        setLoadingUsers(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getUsers(user._id);
            setUsers(response);
            props.setSpinner(false)
        }, 2000);
    }

    async function blockUser() {
        const result = await Swal.fire({
            title: `Deseja ${selectedUser.status === "APROVED" ? "bloquear" : "desbloquear"} esse usuário?`,
            text: selectedUser.status === "APROVED" ? 'Usuários bloqueados serão impedidos de sacar fundos. O usuário receberá um email informando o bloqueio!' : "",
            icon: 'warning',
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            showCancelButton: true,
            confirmButtonText: `Sim, ${selectedUser.status === "APROVED" ? "bloquear" : "desbloquear"}!`,
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            const response = await adminService.updateUserStatus(selectedUser._id,
                selectedUser.status === "APROVED" ? "BLOCKED" : "APROVED",
                selectedUser.email
            );
            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: `Usuário ${selectedUser.status === "APROVED" ? "bloqueado" : "desbloqueado"} com sucesso!`,
                    showConfirmButton: false,
                    timer: 2000,
                    didClose: () => {
                        setLoadingUsers(false);
                    }
                });
            }
        }
    }

    useEffect(() => {
        if (selectedUser && users.length > 0) setSelectedUser(users.find(u => u._id === selectedUser._id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers]);

    function handleBackMenu() {
        setSelectedPage("MENU");
    }

    function handleChangeUser(userOption) {
        props.setSpinner(true);
        setSelectedUser(users[users.findIndex(user => user._id === userOption.value)]);
        setTimeout(() => {
            props.setSpinner(false);
        }, 2000)
    }

    function handlePage(page) {
        if (
            ((page === "MANAGE_USERS" || page === "MANAGE_WALLET" || page === "REGISTER_SUPPORT" || page === "MANAGE_FEES") && !selectedUser) ||
            (selectedUser && selectedUser.type === "DRIVER" && page === "MANAGE_FEES")
        ) {
            Swal.fire({
                icon: 'error',
                title: selectedUser && selectedUser.type === "DRIVER" && page === "MANAGE_FEES" ? "Selecione um lojista" : 'Selecione um usuário',
                showConfirmButton: false,
                timer: 2000
            });
            return;
        }
        setSelectedPage(page)
    }

    const usersOptions = users.map(({ _id, name }) => ({ value: _id, label: name }));

    const user_type_map = {
        "SHOPKEEPER": "LOJISTA",
        "HUB": "HUB",
        "LICENSED": "LICENCIADO",
        "DRIVER": "ENTREGADOR"
    }

    const score_max_map = {
        0: 90,
        1: 234,
        2: 390,
        3: 540
    }

    const score_min_map = {
        0: 0,
        1: 90,
        2: 234,
        3: 390
    }

    if (selectedPage === "APROVE_USERS")
        return <div className="admin-container"><PendingUsers handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "INTEGRATIONS")
        return <div className="admin-container"><Integrations handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "MANAGE_USERS")
        return <div className="admin-container"><ManageUsers selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "MANAGE_WALLET")
        return <div className="admin-container"><ManageWallet selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "REGISTER_SUPPORT")
        return <div className="admin-container"><RegisterSupport selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "MANAGE_FEES")
        return <div className="admin-container"><ManageFees selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "LOGS")
        return <div className="admin-container"><Logs selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "REPORTS")
        return <div className="admin-container"><Reports selectedUser={selectedUser} handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>;
    else if (selectedPage === "MANAGE_PLATFORM_FEES")
        return <div className="admin-container"><ManagePlatformFees handleBackMenu={handleBackMenu} setSpinner={props.setSpinner} setTextSpinner={props.setTextSpinner} windowWidth={props.windowWidth} /></div>
    else if (selectedPage === "MENU")
        return (
            <div className="admin-container">
                <div className="options-container">
                    <div className='page-title'>Suporte ao usuário</div>
                    <div className="select-user-container">
                        <Select
                            options={usersOptions}
                            onChange={handleChangeUser}
                            value={selectedUser ? usersOptions.find((user) => user.value === selectedUser._id) : null}
                            placeholder="Selecione um usuário"
                            className="select-user"
                        />
                    </div>
                    <div className="menu">
                        <div className="support-actions-container">
                            <div className="action-box" onClick={() => handlePage("APROVE_USERS")}>
                                <FontAwesomeIcon className="action-icon" icon={faUserCheck} />
                                <div className="action-label">Aprovar usuários</div>
                            </div>
                            <div className="action-box" onClick={() => handlePage("INTEGRATIONS")}>
                                <FontAwesomeIcon className="action-icon" icon={faNetworkWired} />
                                <div className="action-label">Integrações</div>
                            </div>
                            {(user.type === "ADMIN" || user.type === "SUPPORT") &&
                            <div className="action-box" onClick={() => handlePage("MANAGE_PLATFORM_FEES")}>
                                <FontAwesomeIcon className="action-icon" icon={faMoneyBillTrendUp} />
                                <div className="action-label">Tarifas da Plataforma</div>
                            </div>}
                            <div className="action-box" onClick={() => handlePage("REGISTER_SUPPORT")}>
                                <FontAwesomeIcon className="action-icon" icon={faHeadset} />
                                <div className="action-label">Cadastrar Suporte</div>
                            </div>
                            {(user.type === "ADMIN" || user.type === "SUPPORT") &&
                            <div className="action-box" onClick={() => handlePage("REPORTS")}>
                                <FontAwesomeIcon className="action-icon" icon={faChartSimple} />
                                <div className="action-label">Relatórios</div>
                            </div>}
                            {(user.type === "ADMIN" || user.type === "SUPPORT") &&
                            <div className="action-box" onClick={() => handlePage("LOGS")}>
                                <FontAwesomeIcon className="action-icon" icon={faWaveSquare} />
                                <div className="action-label">Logs do Sistema</div>
                            </div>}
                        </div>
                    </div>
                </div>
                {selectedUser &&
                    <div className="details-container">
                        <div className="details-header">
                            <CircularProgress
                                score={selectedUser.score}
                                minScore={score_min_map[selectedUser.level]}
                                maxScore={score_max_map[selectedUser.level]}
                                level={selectedUser.level}
                            />
                            <div className="details-info">
                                <div className="user-info"><FontAwesomeIcon icon={faUser} /> {selectedUser.name.toUpperCase()} - {user_type_map[selectedUser.type]}</div>
                                <div className="user-info"><FontAwesomeIcon icon={faIdCard} /> {Conversor.converterCpf(selectedUser.cpf)}</div>
                                <div className="user-info"><FontAwesomeIcon icon={faEnvelope} /> {selectedUser.email.toUpperCase()}</div>
                                <div className="user-info"><FontAwesomeIcon icon={faPhone} /> {Conversor.converterFormatoTelefone(String(selectedUser.cellNumber))}</div>
                            </div>
                        </div>
                        {(selectedUser.type !== "DRIVER") &&
                            <div className="details-address">
                                <div className="details-title">Endereço</div>
                                <hr />
                                <div className="details-content">
                                    {selectedUser.address.street} ,
                                    {' ' + selectedUser.address.address_number}  -
                                    {' ' + selectedUser.address.neighborhood}  -
                                    {' ' + selectedUser.address.city} -
                                    {' ' + selectedUser.address.complement}
                                </div>
                            </div>
                        }
                        <div className="details-actions">
                            <div className="action-box box-info" onClick={() => handlePage("MANAGE_USERS")}>
                                <div className="action-icon"><FontAwesomeIcon icon={faUserPen} /></div>
                                <div className="action-label">Alterar dados</div>
                            </div>
                            <div className="action-box box-info" onClick={() => handlePage("MANAGE_WALLET")}>
                                <div className="action-icon"><FontAwesomeIcon icon={faWallet} /></div>
                                <div className="action-label">Gerenciar carteira</div>
                            </div>
                            <div className="action-box box-info" onClick={() => handlePage("MANAGE_FEES")}>
                                <div className="action-icon"><FontAwesomeIcon icon={faMoneyBills} /></div>
                                <div className="action-label">Gerenciar tarifas</div>
                            </div>
                            {(user.type === "ADMIN" || user.type === "SUPPORT") &&
                                <div className={`action-box ${selectedUser.status === "APROVED" ? "box-danger" : "box-success"}`} onClick={blockUser}>
                                    <div className="action-icon"><FontAwesomeIcon icon={selectedUser.status === "APROVED" ? faBan : faUserCheck} /></div>
                                    <div className="action-label">{selectedUser.status === "APROVED" ? "Bloquear" : "Desbloquear"} usuário</div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
}