import React from "react";
import "./css/services.scss"
import Table from "../img/table.svg"
import Motoboy from "../img/motoboy.svg"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeliveryBoy from "../img/man-draw.svg"
import AnotaAi from '../../../../assets/img/anota_ai_logo.png'
import Ifood from '../img/ifood-logo-3.png'
import Logos from "../img/motum-ifood.svg"
import BoltIcon from '@mui/icons-material/Bolt';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

export default function Services() {
    return (
        <div className="services-container">
            <div className="section1-services">
                <div className="sub-section1-services">
                    <img src={Table} className="table-section1" alt="Table" />
                </div>
                <div className="sub2-section1-services">
                    <div className="title-section1-services">
                        <BoltIcon sx={{
                            fontSize: { xs: 26, lg: 33 },
                            paddingTop: { xs: 0, lg: 0 },
                            mr: "6px"
                        }} />
                        Motum Hub
                        <div className="p1-section1-services">
                            Envios a partir de R$5,49
                        </div>
                        <div className="subp-section1-services">
                            Encontre o MotumHub mais próximo de você e comece a ter envios rápidos e econômicos.
                        </div>
                    </div>
                </div>
            </div>
            <div className="section2-services">
                <div className="sub2-section2-services">
                    <div className="title-section2-services">
                        <LocationOnIcon sx={{
                            fontSize: { xs: 26, lg: 33 },
                            paddingTop: { xs: 0, lg: 0 },
                            mr: "6px"
                        }} />
                        Motum Cidades
                        <div className="p1-section2-services">
                            Envie Para Qualquer Lugar do Brasil com Motum Cidades
                        </div>
                        <div className="subp-section2-services">
                            Facilite seu dia enviando suas encomendas para onde quiser com poucos cliques ou de forma automatizada, SEM CLIQUES.
                        </div>
                    </div>
                </div>
                <div className="sub-section2-services">
                    <img src={Motoboy} className="image-section2" alt="Motoboy" />
                </div>
            </div>
            <div className="section1-services">
                <div className="sub-section3-services">
                    <img src={DeliveryBoy} className="deliveryboy-section3" alt="Delivery Boy" />
                    <div className="subp-section3-services">
                        Aproveite a nossa integração com outras plataformas para adicionar os seus pedidos automaticamente.
                    </div>
                    <div className="subtitle-left-container">
                        <img src={AnotaAi} className="icon-left-container-anotaai" alt='Anota Ai Logo' />
                        <img src={Ifood} className="icon-left-container-ifood" alt='Ifood Logo' />
                    </div>
                </div>
                <div className="sub2-section1-services">
                    <div className="title-section1-services">
                        <AccessTimeFilledIcon sx={{
                            fontSize: { xs: 26, lg: 33 },
                            paddingTop: { xs: 0, lg: 0 },
                            mr: "6px"
                        }} />
                        Motum Xpress
                        <div className="p1-section1-services">
                            Entregando seu pedido em até 30 minutos
                        </div>
                        <div className="subp-section1-services">
                            Recomendado para restaurantes que não querem deixar o cliente esperando.
                        </div>
                    </div>
                </div>
            </div>
            <div className="section4-services">
                <div className="title-section4-services">
                    Integração com o iFood
                    <div className="p1-section4-services">
                        A maior plataforma de comercio de alimentos da America Latina
                    </div>
                    <div className="minidiv-section4">
                        <img src={Logos} className="image-section4" alt="Logos" />
                    </div>
                    <div className="subp-section4-services">
                        Seus pedidos são feitos de forma automática sem necessidade de interação humana.
                    </div>
                </div>
            </div>
        </div>
    );
}