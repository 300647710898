import { useContext, useEffect, useMemo, useState } from "react";
import UserService from "../../../../../../services/UserService";
import { UserContext } from "../../../../../context/UserContext";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone, faStore } from "@fortawesome/free-solid-svg-icons";
import Conversor from "../../../../../../helper/Conversor";
import Swal from "sweetalert2";

export default function NearbyStores() {
    const { user } = useContext(UserContext);
    const [nearbyStores, setNearbyStores] = useState([]);
    const [loading, setLoading] = useState(true);

    const userService = useMemo(() => new UserService(), []);

    async function getNearbyStores() {
        setLoading(true);

        const stores = await userService.getStoresWithInServiceRadius(user._id);

        setTimeout(() => {
            setNearbyStores(stores);
            setLoading(false);
        }, 1000);
    }

    async function requestConnection(storeId) {
        setLoading(true);

        await userService.requestLicensedConnection(user._id, storeId);

        setTimeout(() => {
            setLoading(false);
            Swal.fire({
                title: `Conexão solicitada com sucesso`,
                text: "É necessário o lojista aceitar sua conexão, recomendamos que faça uma visita ao estabelecimento",
                icon: 'success',
                timer: 2000,
                didClose: () => {
                    getNearbyStores();
                },
                showConfirmButton: false,
            });
        }, 1000);
    }

    useEffect(() => {
        getNearbyStores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    if (loading) {
        return (
            <div className="loading-oval-content">
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            </div>
        );
    }

    function renderAddressNumber(number) {
        return number ? `, nº${number}` : `, s/N`;
    }

    function renderAddressComplement(complement) {
        return complement ? `, ${complement}, ` : `, `;
    }

    return (
        <div className="licensed-nearby-stores">
            {nearbyStores && nearbyStores.map((store) => (
                <div className="store-box">
                    <div className="store-img">
                        <div className="profile">
                            <FontAwesomeIcon icon={faStore} />
                        </div>
                    </div>
                    <div className="store-details">
                        <div className="store-name">
                            {store.companyName}
                        </div>
                        <div className="store-cell"><FontAwesomeIcon icon={faPhone} />{Conversor.converterFormatoTelefone(String(store.cellNumber))}</div>
                        <div className="store-address"><FontAwesomeIcon icon={faLocationDot} />
                            <span>
                                {
                                    store.address.street +
                                    renderAddressNumber(store.address.number) + " - " +
                                    store.address.neighborhood +
                                    renderAddressComplement(store.address.complement) +
                                    store.address.city + " - " +
                                    store.address.state
                                }
                            </span>
                        </div>
                        <div className="store-actions">
                            <button className="connect-store" type="button" onClick={() => requestConnection(store._id)}>Conectar</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}