import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import Conversor from '../../../../../../helper/Conversor';
import '../assets/scss/list.scss';

const PendingUserList = ({ users, aproveUser, disaproveUser }) => {
  return (
    <table className='pending-users-table'>
      <thead>
        <tr>
          <th className='th-delivery-code'>Data de Cadastro</th>
          <th>Tipo</th>
          <th>Nome</th>
          <th className='lg-column'>CPF</th>
          <th>Loja/Veículo</th>
          <th className='lg-column'>CPNJ/Placa</th>
          <th style={{ width: "10%", textAlign: 'center' }}>Aprovar/Reprovar</th>
        </tr>
      </thead>
      <tbody>
        {users.map((data) => {
          if (data.user.type !== "DRIVER") {
            return (
              <tr key={data.user._id}>
                <td className='td-delivery-code'>{Conversor.converterData(data.user.created)}</td>
                <td>Lojista</td>
                <td>{data.user.name}</td>
                <td>{Conversor.converterCpf(data.user.cpf.toString())}</td>
                <td>{data.store.companyName}</td>
                <td>{Conversor.converterCNPJ(data.store.cnpj)}</td>
                <td className='td-actions-buttons' style={{ textAlign: 'center' }}>
                  <button className="aprove-user-btn" onClick={() => aproveUser(data.user._id, data.user.email)}>
                    <FontAwesomeIcon icon={faCheck} title='Aprovar Usuário' color='#fff' style={{ marginRight: 10, height: 15 }} />
                    Aprovar
                  </button>
                  <button className="disaprove-user-btn" onClick={() => disaproveUser(data.user._id, data.user.email)}>
                    <FontAwesomeIcon icon={faTimes} color='#fff' title='Reprovar Usuário' style={{ marginRight: 10, height: 15 }} />
                    Reprovar
                  </button>
                </td>
              </tr>
            );
          } else {
            return (
              <tr key={data.user._id}>
                <td className='td-delivery-code'>{Conversor.converterData(data.user.created)}</td>
                <td>Entregador</td>
                <td>{data.user.name}</td>
                <td>{Conversor.converterCpf(data.user.cpf.toString())}</td>
                <td>{data.user.metadata.vehicle.model}</td>
                <td>{data.user.metadata.vehicle.vehiclePlate}</td>
                <td className='td-actions-buttons' style={{ textAlign: 'center', display: 'inline-flex' }}>
                  <button className="aprove-user-btn" onClick={() => aproveUser(data.user._id, data.user.email)}>
                    <FontAwesomeIcon icon={faCheck} title='Aprovar Usuário' color='#fff' style={{ marginRight: 10, height: 10 }} />
                    Aprovar
                  </button>
                  <button className="disaprove-user-btn" onClick={() => disaproveUser(data.user._id, data.user.email)}>
                    <FontAwesomeIcon icon={faTimes} color='#fff' title='Reprovar Usuário' style={{ marginRight: 10, height: 10 }} />
                    Reprovar
                  </button>
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default PendingUserList;