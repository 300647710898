import { useState, useContext, useMemo, useEffect } from "react";
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Typography, Box, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../style.scss";
import { UserContext } from "../../../../../context/UserContext";
import CepService from "../../../../../../services/CepService";
import Swal from "sweetalert2";
import StoreService from "../../../../../../services/StoreService";
import createUserName from "../../../../Register/utils/createUserName";
import UserService from "../../../../../../services/UserService";

export default function StoresForm({ onClose }) {
  const { addStore, user, selectedStore } = useContext(UserContext);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    companyName: "",
    fantasyName: "",
    cnpj: "",
    cellNumber: "",
    mode: "CLOUD",
    type: selectedStore.type,
    status: "APROVED",
    address: {
      cep: "",
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
      city: "",
      state: ""
    }
  });

  useEffect(() => {
    if (formData.password !== confirmPassword) {
      setMatchPassword(false);
    } else {
      setMatchPassword(true);
    }
  }, [formData.password, confirmPassword])

  function handlePassword(event) {
    const { name, value } = event.target;
    if (name === "password")
      setFormData(prevState => ({
        ...prevState,
        password: value
      }));
    else
      setConfirmPassword(value);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "companyName") {
      setFormData(prevState => ({
        ...prevState,
        companyName: value,
        fantasyName: value,
        username: createUserName(value)
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }

  const cepService = useMemo(() => new CepService(), []);
  const storeService = useMemo(() => new StoreService(), []);
  const userService = useMemo(() => new UserService(), []);

  async function handleAddressChange(event) {
    const { name, value } = event.target;
    let valueConvert = value.replace(/\D/g, '');
    if (name === "cep" && valueConvert.length === 8) {
      const addressFromCep = await cepService.buscarEndereco(valueConvert);
      setFormData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          cep: valueConvert,
          city: addressFromCep._city,
          complement: addressFromCep._complement,
          neighborhood: addressFromCep._neighborhood,
          number: addressFromCep._number,
          street: addressFromCep._street,
          state: addressFromCep._state
        }
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [name]: value
        }
      }));
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let addressFromStore = {
      cep: formData.address.cep.replace(/\D/g, ''),
      city: formData.address.city,
      street: formData.address.street,
      number: formData.address.number,
      neighborhood: formData.address.neighborhood,
      complement: formData.address.complement,
      state: formData.address.state
    };

    const coordinates = await cepService.buscarLatitudeLongitude(addressFromStore);

    addressFromStore.location = {
      type: "Point",
      coordinates: coordinates
    }

    const formUserDataToSend = {
      name: formData.companyName,
      email: formData.email,
      cpf: formData.cnpj.replace(/\D/g, ''),
      cellNumber: formData.cellNumber.replace(/\D/g, ''),
      address: {
        cep: formData.address.cep.replace(/\D/g, ''),
        city: formData.address.city,
        street: formData.address.street,
        address_number: formData.address.number,
        neighborhood: formData.address.neighborhood,
        complement: formData.address.complement
      },
      type: 'AFFILIATE',
      status: formData.status,
      password: formData.password,
    };

    try {
      const userResponse = await userService.registerUser(formUserDataToSend);

      if (userResponse) {
        const formStoreDataToSend = {
          companyName: formData.companyName,
          fantasyName: formData.fantasyName,
          username: formData.username,
          cnpj: formData.cnpj.replace(/\D/g, ''),
          cellNumber: formData.cellNumber.replace(/\D/g, ''),
          status: formData.status,
          userId: user._id,
          type: formData.type,
          address: addressFromStore
        };

        const response = await storeService.registerStore(formStoreDataToSend);

        if (response.id) {
          addStore(response.store);
          Swal.fire({
            title: 'Filial cadastrada com sucesso!',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            didClose: () => {
              onClose();
            }
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocorreu um erro!',
        text: error.message,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2, margin: "auto", overflowY: 'auto', height: '100%' }} fullWidth>
      <Typography variant="h6">Dados de acesso</Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField type="email" label="E-mail" name="email" value={formData.email} onChange={handleChange} required fullWidth />
        </Grid>
        <Grid item xs={3}>
          <TextField type="password" error={!matchPassword} helperText={`${!matchPassword ? 'As senhas não coincidem' : ''}`} label="Senha" name="password" value={formData.password} onChange={handlePassword} required fullWidth />
        </Grid>
        <Grid item xs={3}>
          <TextField type="password" error={!matchPassword} helperText={`${!matchPassword ? 'As senhas não coincidem' : ''}`} label="Confirme a senha" name="confirmPassword" value={confirmPassword} onChange={handlePassword} required fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6">Dados da loja</Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField label="Nome da Filial" name="companyName" value={formData.companyName} onChange={handleChange} required fullWidth />
        </Grid>
        <Grid item xs={6}>
          <InputMask mask="99.999.999/9999-99" value={formData.cnpj} onChange={handleChange}>
            {(inputProps) => <TextField {...inputProps} label="CNPJ" name="cnpj" fullWidth />}
          </InputMask>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputMask mask="(99) 99999-9999" value={formData.cellNumber} onChange={handleChange}>
            {(inputProps) => <TextField {...inputProps} label="Telefone" name="cellNumber" required fullWidth />}
          </InputMask>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Modo de Operação</InputLabel>
            <Select
              label="Modo de Operação"
              name="mode"
              value={formData.mode}
              onChange={handleChange}>
              <MenuItem value="CLOUD">Nuvem</MenuItem>
              <MenuItem value="DEDICATED">Parceiro</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography variant="h6">Endereço</Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputMask mask="99999-999" value={formData.address.cep} onChange={handleAddressChange}>
            {(inputProps) => <TextField {...inputProps} label="CEP" name="cep" required fullWidth />}
          </InputMask>
        </Grid>
        <Grid item xs={6}>
          <TextField label="Rua" name="street" value={formData.address.street} onChange={handleAddressChange} required fullWidth />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Número" name="number" value={formData.address.number} onChange={handleAddressChange} required fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField label="Bairro" name="neighborhood" value={formData.address.neighborhood} onChange={handleAddressChange} required fullWidth />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Complemento" name="complement" value={formData.address.complement} onChange={handleAddressChange} fullWidth />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Cidade" name="city" value={formData.address.city} onChange={handleAddressChange} required fullWidth />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Estado" name="state" value={formData.address.state} onChange={handleAddressChange} required fullWidth />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="space-between">
        <Button type="submit" variant="contained" color="primary" style={{ background: '#000' }} startIcon={<FontAwesomeIcon icon={faSave} />}>Salvar</Button>
        <Button type="button" variant="outlined" color="#ccc" onClick={onClose} startIcon={<FontAwesomeIcon icon={faTimes} />}>Cancelar</Button>
      </Box>
    </Box>
  );
}
