import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import UserService from "../../../../../../services/UserService";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone, faStore, faWarning } from "@fortawesome/free-solid-svg-icons";
import Conversor from "../../../../../../helper/Conversor";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";

export default function MyConnections() {
    const { user } = useContext(UserContext);
    const [connections, setConnections] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalConnections: 0,
        totalPages: 1,
    });
    const [loading, setLoading] = useState(true);

    const userService = useMemo(() => new UserService(), []);

    async function getConnections() {
        setLoading(true);

        const response = await userService.getMyLicensedConnections(user._id, pagination.currentPage);

        setTimeout(() => {
            setLoading(false);
            setConnections(response.data);
            setPagination(response.pagination);
        }, 1000);
    }

    async function removeConnection(connectionId) {
        const result = await Swal.fire({
            title: 'Deseja desconectar da loja?',
            text: 'Você perderá a taxa de serviço dessa loja. Caso queria conectar-se novamente terá que solicitar conexão',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#EA1D2C',
            cancelButtonColor: '#000',
            confirmButtonText: 'Sim, desconectar!',
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            setLoading(true);

            const response = await userService.destroyLicensedConnection(connectionId);

            setTimeout(() => {
                Swal.fire({
                    title: response.message,
                    icon: 'success',
                    timer: 2000,
                    didClose: () => {
                        getConnections();
                    },
                    showConfirmButton: false,
                });
                setLoading(false);
            }, 1000);
        }

    }

    useEffect(() => {
        getConnections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    function renderAddressNumber(number) {
        return number ? `, nº${number}` : `, s/N`;
    }

    function renderAddressComplement(complement) {
        return complement ? `, ${complement}, ` : `, `;
    }

    if (loading) {
        return (
            <div className="loading-oval-content">
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            </div>
        );
    }

    return (
        <div className="licensed-nearby-stores">
            {connections && connections.map((connection) => (
                <div className="store-box">
                    <div className="store-img">
                        <div className="profile">
                            <FontAwesomeIcon icon={faStore} />
                        </div>
                    </div>
                    <div className="store-details">
                        <div className="store-name">
                            {connection.store.companyName}
                            {connection.status === "PENDING" &&
                                <Tooltip title="Essa conexão está pendente de aprovação. Visite o lojista para que ele possa aprovar a conexão" arrow>
                                    <span>
                                        <FontAwesomeIcon icon={faWarning} color="#DBA827" />
                                    </span>
                                </Tooltip>
                            }
                        </div>
                        <div className="store-cell"><FontAwesomeIcon icon={faPhone} />{Conversor.converterFormatoTelefone(String(connection.store.cellNumber))}</div>
                        <div className="store-address"><FontAwesomeIcon icon={faLocationDot} />
                            <span>
                                {
                                    connection.store.address.street +
                                    renderAddressNumber(connection.store.address.number) + " - " +
                                    connection.store.address.neighborhood +
                                    renderAddressComplement(connection.store.address.complement) +
                                    connection.store.address.city + " - " +
                                    connection.store.address.state
                                }
                            </span>
                        </div>
                        <div className="store-actions">
                            {connection.status === "PENDING" && <div className="status-connection" style={{ backgroundColor: '#ccc' }}>PENDENTE</div>}
                            {connection.status === "ACCEPTED" && <button type="button" className="status-connection" style={{ backgroundColor: '#EA1D2C' }} onClick={() => removeConnection(connection._id)}>DESCONECTAR</button>}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}