import React from 'react';
import motumIcon from '../../assets/img/motum.svg';
import { TailSpin } from 'react-loader-spinner';

const LoadingSpinner = (props) => {
    if (!props.visible) return null;

    return (
        <div className="loading-overlay">
            <div className="loading-container">
                <div className="spinner-wrapper">
                    <TailSpin
                        visible={true}
                        height="150"
                        width="150"
                        color="#fff"
                        ariaLabel="tail-spin-loading"
                    />
                    <img src={motumIcon} alt="Loading" className="loading-image" />
                </div>
            </div>
        </div>
    );
};

export default LoadingSpinner;
