import { Component } from "react";
import Field from "../../components/Fieldset/Fieldset";
import Select from "../../components/Select/Select";

class PaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableChange: true,
            price: "",
            change: "",
            changeError: "",
        };
        this.props = props;
    }

    setDisableChange(value) {
        this.setState({ 
            disableChange: value
        });

        if (value) {
            this.setState({ change: "0,00" });
        }
    }

    formatCurrency(value) {
        const numericValue = value.replace(/\D/g, ""); // Remove tudo que não for número
        const floatValue = parseFloat(numericValue) / 100; // Transforma em decimal
        return floatValue.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    }

    handlePriceChange(event) {
        const formattedValue = this.formatCurrency(event.target.value);
        this.setState({ price: formattedValue });
    }

    handleChangeChange(event) {
        const formattedValue = this.formatCurrency(event.target.value);
        this.setState({ change: formattedValue }, () => {
            this.validateChange(event.target);
        });
    }

    validateChange(inputElement) {  
        const priceValue = parseFloat(this.state.price.replace("R$", "").replace(".", "").replace(",", ".") || 0);
        const changeValue = parseFloat(this.state.change.replace("R$", "").replace(".", "").replace(",", ".") || 0);

        if (!this.state.disableChange && changeValue < priceValue) {
            inputElement.setCustomValidity("O valor a receber deve ser maior que o valor do pedido.");
            this.setState({ 
                changeError: "O valor a receber deve ser maior que o valor do pedido."
            });
        } else {
            inputElement.setCustomValidity("");
            this.setState({ 
                changeError: ""
            });
        }
    }

    getChangePattern() {
        if (this.state.disableChange || !this.state.price) return ".*"; // Aceita qualquer valor
        const priceValue = parseFloat(this.state.price.replace("R$", "").replace(".", "").replace(",", ".") || 0);
        return `^(R\\$\\s*)?([1-9]\\d{0,2}(\\.\\d{3})*|0)?(,\\d{2})?$|^${(priceValue + 0.01).toFixed(2).replace(".", ",")}$`;
    }

    render() {
        return (
            <fieldset className="payment">
                <legend className="address-form__legend address-form__legend--minor">Dados do Pagamento</legend>
                <Field 
                    content="Valor do pedido" 
                    labelAnimation="animation--opacity-0" 
                    name="price" 
                    id="price" 
                    inputMode="numeric" 
                    placeholder="" 
                    required={true}
                    value={this.state.price}
                    onChange={this.handlePriceChange.bind(this)}
                />
                <Select
                    content="Forma de pagamento"
                    name="payment"
                    options={[
                        { name: "Dinheiro", value: "dinheiro" },
                        { name: "Cartão de crédito", value: "credito" },
                        { name: "Cartão de débito", value: "debito" },
                        { name: "Pago", value: "Pago" },
                        { name: "Apenas receber entrega", value: "Apenas entrega" },
                    ]}
                    labelAnimation="animation--opacity-0"
                    paymentValue={this.state.paymentValue}
                    setDisableChange={this.setDisableChange.bind(this)}
                />
                <Field 
                    content="Troco para" 
                    labelAnimation="animation--opacity-0" 
                    blockChange={this.state.disableChange} 
                    name="change" 
                    id="change" 
                    inputMode="numeric"
                    value={this.state.change}
                    onChange={this.handleChangeChange.bind(this)}
                    pattern={this.getChangePattern()} // Adiciona a validação via pattern
                    messageErro={this.state.changeError}
                />
            </fieldset>
        );
    }
}

export default PaymentForm;
