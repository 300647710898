import React, { useContext, useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, Navigate } from "react-router-dom";
import { Logout, DensitySmall, CurrencyExchangeOutlined, LocalShipping, Task, SupportAgent, Settings } from "@mui/icons-material";
import { UserContext } from "../../context/UserContext";
import { FormControl, InputLabel, Select } from "@mui/material";
import { AppContext } from "../../context/AppContext";
import logo_map from "../../../helper/logos";
import "./style.scss";

const CSidebar = () => {
    const [collapsed, setCollapsed] = useState(window.innerWidth < 1024);
    const { user, setHideSidebar, selectedStore, stores, updateStore } = useContext(UserContext);
    const { hostname } = useContext(AppContext);
    const logoPath = logo_map[hostname] || logo_map['motumbrasil.com'];

    useEffect(() => {
        const handleResize = () => {
            setCollapsed(window.innerWidth < 1024);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    function logout() {
        localStorage.clear();
        setHideSidebar(true);
        window.location.href = window.location.origin + "/login";
    }

    function handleChangeStore(event) {
        const { value } = event.target;
        updateStore(value);
    }

    if (!user) return <Navigate to="/login" />;

    return (
        <div className={`custom-sidebar ${collapsed ? "collapsed-sidebar" : ""}`}>
            <Sidebar className="sidebar" collapsed={collapsed}>
                <div className="logo" onClick={toggleSidebar}>
                    {!collapsed && <img src={logoPath} alt="Motum" className="rodape__logo" />}
                    <h1>{collapsed ? <DensitySmall /> : `Olá, ${user ? user.name : ""}`}</h1>
                </div>
                <Menu className="menu">
                    <div className="sidebar-main">
                        {user.type !== "ADMIN" && (
                            <Link to="/request">
                                <MenuItem className="menu-item" icon={<LocalShipping />}>
                                    Solicitar entrega
                                </MenuItem>
                            </Link>
                        )}

                        <Link to="/ordermanagement">
                            <MenuItem className="menu-item" icon={<Task />}>
                                Acompanhamento
                            </MenuItem>
                        </Link>

                        {user.type !== "AFFILIATE" && (
                            <Link to="/wallet">
                                <MenuItem className="menu-item" icon={<CurrencyExchangeOutlined />}>
                                    Carteira Digital
                                </MenuItem>
                            </Link>
                        )}

                        {(user.type === "SHOPKEEPER" || user.type === "HUB" || user.type === "LICENSED" || user.type === "AFFILIATE") && (
                            <Link to="/configuration">
                                <MenuItem className="menu-item" icon={<Settings />}>
                                    Configurações
                                </MenuItem>
                            </Link>
                        )}

                        {(user.type === "ADMIN" || user.type === "SUPPORT" || user.type === "HUB" || user.type === "LICENSED") && (
                            <Link to="/support">
                                <MenuItem className="menu-item" icon={<SupportAgent />}>
                                    Suporte ao Usuário
                                </MenuItem>
                            </Link>
                        )}
                    </div>

                    <div className="sidebar-footer">
                        <FormControl className="select-store">
                            <InputLabel>Filial</InputLabel>
                            <Select
                                disabled={user.type === "AFFILIATE"}
                                label="Filial"
                                name="store"
                                value={selectedStore._id}
                                onChange={handleChangeStore}
                            >
                                {stores.map((store) => (
                                    <MenuItem className="menu-item" key={store._id} value={store._id}>{store.companyName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Link to="/" className="logout" onClick={logout}>
                            <MenuItem className="menu-item" icon={<Logout />}>
                                Sair
                            </MenuItem>
                        </Link>
                    </div>
                </Menu>
            </Sidebar>
        </div>
    );
};

export default CSidebar;