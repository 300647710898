import React, { useState, useContext, useEffect, useMemo } from "react";
import { Navigate } from 'react-router';
import { UserContext } from "../../../../context/UserContext";
import Swal from "sweetalert2";
import AdminService from "../../../../../services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import PendingUsersFilters from "./components/PendingUsersFilters";
import PendingUserList from "./components/PendingUserList";
import './assets/scss/main.scss';
import './assets/scss/list.scss';

const PendingUsers = (props) => {
    const { user } = useContext(UserContext);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [filters, setFilters] = useState({
            name: "",
            cpf: ""
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPendingUsers: 0,
        totalPages: 1,
    });

    const adminService = useMemo(() => new AdminService(), []);

    const handleFilters = (query) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            name: query.name,
            cpf: query.cpf
        }));
    };

    const previousPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage - 1
        }));
        setLoading(false);
    }

    const nextPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage + 1
        }));
        setLoading(false);
    }

    async function loadUsers() {
        if (loading) {
            return;
        }
        setLoading(true);
        setSpinner(true);
        const response = await adminService.getPendingUsers(user._id, pagination.currentPage, filters);
        console.log(response.data)
        setTimeout(async () => {
            setPagination((prevFilters) => ({
                ...prevFilters,
                totalPendingUsers: response.pagination.totalPendingUsers,
                totalPages: response.pagination.totalPages
            }));
            setUsers(response.data);
            setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    async function aproveUser(id, email) {
        const result = await Swal.fire({
            title: 'Deseja aprovar esse usuário?',
            text: 'O usuário receberá um email de aprovação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Confirmar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            setSpinner(true);
            setTimeout(async () => {
                try {
                    const response = await adminService.updateUserStatus(id, 'APROVED', email);
                    if (response.ok) {
                        Swal.fire({
                            title: 'Usuário aprovado!',
                            text: 'Tudo certo! O usuário agora poderá fazer login na plataforma.',
                            icon: 'success',
                            timer: 3000,
                            showConfirmButton: false,
                            didClose: () => {
                                loadUsers();
                                setLoading(false);
                            }
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Ocorreu um erro!',
                        text: error,
                        icon: 'error',
                        timer: 3000,
                        showConfirmButton: false,
                        didClose: () => {
                            loadUsers();
                            setLoading(false);
                        }
                    });
                }
            }, 1000);
        }
    }

    async function disaproveUser(id, email) {
        const result = await Swal.fire({
            title: 'Deseja reprovar esse usuário?',
            text: 'O usuário receberá um email informando a reprovação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Confirmar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            setSpinner(true);
            setTimeout(async () => {
                try {
                    const response = await adminService.updateUserStatus(id, 'DISAPPROVED', email);
                    if (response.ok) {
                        Swal.fire({
                            title: 'Usuário reprovado!',
                            icon: 'success',
                            timer: 3000,
                            showConfirmButton: false,
                            didClose: () => {
                                loadUsers();
                                setLoading(false);
                            }
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Ocorreu um erro!',
                        text: error,
                        icon: 'error',
                        timer: 3000,
                        showConfirmButton: false,
                        didClose: () => {
                            loadUsers();
                            setLoading(false);
                        }
                    });
                }
            }, 1000);
        }
    }

    useEffect(() => {
        if (filters.cpf.length === 0 || filters.cpf.length === 11)
            loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        document.title = `Aprovar Usuários | Motum`;
        // eslint-disable-next-line
    }, []);

    if (!user) {
        return <Navigate to={'/login'} />;
    }

    return (
        <div className={`pending-users-container ${spinner ? `loading-integration` : ``}`}>
            <div className="pending-users-header">
                <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <div className="pending-users-title">Usuários Pendentes</div>
            </div>
            <div className="pending-user-filters">
                <PendingUsersFilters onApplyFilters={handleFilters} filters={filters} setFilters={setFilters}/>
            </div>
            <div className={`${spinner ? "pending-orders-list-loading" : "pending-orders-list"}`}>
                {spinner ? (
                    <Oval
                        visible={loading}
                        height="50"
                        width="50"
                        color="#000"
                        secondaryColor="#ccc"
                        radius="1"
                    />
                ) :
                    <PendingUserList
                        users={users}
                        aproveUser={aproveUser}
                        disaproveUser={disaproveUser}
                    />
                }
            </div>
            <div className="pagination-container">
                {pagination.currentPage > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                {pagination.totalPages > 1 && <span>{pagination.currentPage}</span>}
                {pagination.currentPage < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
            </div>
        </div>
    );
}

export default PendingUsers;