import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";

const CopyInput = ({ integration }) => {
    const [copied, setCopied] = useState(false);
    const value =
        integration.type === "IFOOD" ? integration.platformId : integration.token;

    const handleCopy = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
            <input
                type="text"
                value={value}
                readOnly
                disabled
                style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    padding: "10px 40px 10px 10px",
                    fontSize: "16px",
                    backgroundColor: '#e8e8e8',
                    opacity: 1
                }}
            />
            <button
                onClick={handleCopy}
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                }}
            >
                <FontAwesomeIcon icon={copied ? faCheck : faCopy} size="lg" />
            </button>
        </div>
    );
};

export default CopyInput;
