import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import AdminService from "../../../../../services/AdminService";
import Conversor from "../../../../../helper/Conversor";
import Swal from "sweetalert2";
import './style.scss';
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function RegisterSupport(props) {
    const { user } = useContext(UserContext);

    const [formData, setFormData] = useState({
        userId: props.selectedUser._id,
        description: '',
        status: 'Open',
        assignedTo: user._id
    });
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalSupports: 0,
        totalPages: 1,
    });
    const [supports, setSupports] = useState([]);

    const adminService = useMemo(() => new AdminService(), []);

    const previousPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage - 1
        }));
        setLoading(false);
    }

    const nextPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage + 1
        }));
        setLoading(false);
    }


    async function loadSupports() {
        if (loading) {
            return;
        }
        setLoading(true);
        setSpinner(true);
        const response = await adminService.getSupports(props.selectedUser._id, pagination.currentPage);
        setSupports(response.data);
        setPagination(response.pagination)
        setTimeout(async () => {
            setSpinner(false)
        }, 2000);
    }

    useEffect(() => {
        loadSupports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            props.setSpinner(true);
            await adminService.createSupport(formData);
            props.setSpinner(false);
            Swal.fire({
                icon: 'success',
                title: 'Suporte adicionado!',
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro',
                text: error.message,
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    const status_map = {
        "Open": "Aberto",
        "In Progress": "Em andamento",
        "Closed": "Fechado"
    }

    return (
        <div className={`register-support-container`}>
            <div className="support-form">
                <div className="title">
                    <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    Cadastrar novo suporte
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-control">
                        <label htmlFor="userId">Usuário:</label>
                        <input
                            disabled={true}
                            id="userId"
                            name="userId"
                            type="text"
                            value={props.selectedUser.name}
                            required
                        />
                    </div>

                    <div className="form-control">
                        <label htmlFor="description">Descrição:</label>
                        <textarea
                            id="description"
                            name="description"
                            placeholder="Detalhe qual ajuda você concedeu ao usuário"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit">Cadastrar</button>
                </form>
            </div>
            <div className={`support-list ${spinner ? `loading-supports` : ``}`}>
                <div className="title">Últimos suportes</div>
                {spinner ? (
                    <Oval
                        visible={loading}
                        height="50"
                        width="50"
                        color="#000"
                        secondaryColor="#ccc"
                        radius="1"
                    />
                ) : (
                    <table className="support-list-table">
                        <thead>
                            <tr>
                                <th>Usuário</th>
                                <th>Descrição</th>
                                <th>Status</th>
                                <th>Criado em</th>
                                <th>Última atualização</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supports.map((support) => (
                                <tr key={support._id}>
                                    <td>{props.selectedUser.name}</td>
                                    <td>{support.description}</td>
                                    <td>{status_map[support.status]}</td>
                                    <td>{Conversor.converterData(support.createdAt)}</td>
                                    <td>{Conversor.converterData(support.updatedAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <div className="pagination-container">
                    {pagination.currentPage > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                    {pagination.totalPages > 1 && <span>{pagination.currentPage}</span>}
                    {pagination.currentPage < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
                </div>
            </div>
        </div>
    );
}