import React  from 'react';
import { TextField, Button } from '@mui/material';
import '../assets/scss/filters.scss';

const WalletFilters = ({ filters, setFilters }) => {
    const actualDate = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).format(new Date()).split('/').reverse().join('-');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const cleanFilters = () => {
        setFilters({
            startDate: actualDate,
            endDate: actualDate
        });
    }

    return (
        <div className="wallet-filters">
            <div className="filters-fields">
                <TextField
                    label="Data Inicial"
                    type="date"
                    fullWidth
                    size="small"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleChange}
                />

                <TextField
                    label="Data Final"
                    type="date"
                    fullWidth
                    size="small"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleChange}
                />
            </div>

            <div className="filters-buttons">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={cleanFilters}
                >
                    Limpar
                </Button>
            </div>
        </div>
    );
};

export default WalletFilters;
