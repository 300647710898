import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { styled } from '@mui/material/styles';
import Swal from "sweetalert2";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

const IntegrationSwitch = ({ id, initialActive, enableAndDisableIntegration }) => {
    const [checked, setChecked] = useState(initialActive);

    const handleToggle = async (event) => {
        const newActive = event.target.checked;

        const result = await Swal.fire({
            title: "Tem certeza?",
            text: `Você deseja ${newActive ? "ativar" : "desativar"} esta integração?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Sim, ${newActive ? "ativar" : "desativar"}!`,
            confirmButtonColor: `${newActive ? "#50A773" : "#EA1D2C"}`,
            cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
            setChecked(newActive); // Atualiza o estado apenas se confirmado
            await enableAndDisableIntegration(id, newActive);
        }
    };

    return <IOSSwitch checked={checked} onChange={handleToggle} />;
};

export default IntegrationSwitch;
