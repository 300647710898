import { useContext, useEffect, useMemo, useState } from "react";
import StoreService from "../../../../../services/StoreService";
import { UserContext } from "../../../../context/UserContext";
import CreateDeliveryFee from './components/CreateDeliveryFee';
import { Oval } from "react-loader-spinner";
import '../../assets/scss/deliveryfees.scss';
import Conversor from "../../../../../helper/Conversor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faHandshake, faMoneyBillTransfer, faMoneyBillTrendUp, faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { CurrencyInput } from 'react-currency-mask';
import { TextField, Tooltip } from '@mui/material';

export default function DeliveryFees() {
    const { selectedStore } = useContext(UserContext);
    const [spinner, setSpinner] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tarrifs, setTarrifs] = useState({
        ratePerKm: 0,
        serviceFee: 0,
        licensedFee: 0,
        fees: []
    });

    const [modalOpen, setModalOpen] = useState(false);

    const storeService = useMemo(() => new StoreService(), []);

    async function loadDeliveryFees() {
        if (loading)
            return;

        setLoading(true);
        setSpinner(true);

        const response = await storeService.getDeliveryFees(selectedStore._id);
        setTimeout(() => {
            setTarrifs(response.tariffs);
            setSpinner(false);
        }, 1000);
    }

    async function submitDeliveryFees() {
        setSpinner(true);
        await storeService.storeDeliveryFees(selectedStore._id, tarrifs);
        setTimeout(() => {
            Swal.fire({
                title: 'Tarifas definidas com sucesso!',
                text: 'As novas tarifas começam a valer a partir de agora',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            });
            setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        loadDeliveryFees();
        // eslint-disable-next-line
    }, [loading]);

    function addDeliveryFee(fee) {
        console.log(tarrifs);
        setTarrifs(prevState => ({
            ...prevState,
            fees: [...prevState.fees, fee]
        }));
    }

    function removeDeliveryFee(indexToRemove) {
        setTarrifs(prevState => ({
            ...prevState,
            fees: prevState.fees.filter((_, index) => index !== indexToRemove)
        }));
    }

    return (
        <div className={`delivery-fees-container ${spinner ? 'loading-fees' : ''}`}>
            {(spinner) ? (
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            ) : (
                <div className="delivery-fee-content">
                    <div className="delivery-fees-inputs">
                        <div className="mode">
                            <FontAwesomeIcon icon={selectedStore.mode === "CLOUD" ? faCloudArrowDown : faHandshake} />
                            <div className="mode-text">VOCÊ ESTÁ NO MODO {selectedStore.mode === "CLOUD" ? "NUVEM" : "PARCEIRO"}</div>
                            <div className="mode-warning">
                                {selectedStore.mode === "CLOUD" ? "Não é possível alterar as tarifas, contate o suporte para negociações." : "Você pode definir suas tarifas de entrega"}
                            </div>
                        </div>
                        <div className="fee-input">
                            <CurrencyInput
                                value={tarrifs.ratePerKm}
                                onChangeValue={(event, originalValue, maskedValue) => {
                                    setTarrifs(prev => ({
                                        ...prev,
                                        ratePerKm: originalValue > 0 ? originalValue : 0.01
                                    }));
                                }}
                                InputElement={<TextField label="Tarifa por KM" size="small" disabled={selectedStore.mode === "CLOUD"} />}
                            />
                            <Tooltip title="Caso a loja não tiver uma regra de tarifa para aquela corrida, utiliza a tarifa por KM" arrow>
                                <span>
                                    <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                                </span>
                            </Tooltip>
                        </div>
                        <div className="fee-input">
                            <CurrencyInput
                                value={tarrifs.licensedFee && tarrifs.licensedFee > 0 ? tarrifs.serviceFee + tarrifs.licensedFee : tarrifs.serviceFee}
                                InputElement={<TextField label="Taxa de Serviço" size="small" disabled={true} />}
                            />
                            <Tooltip title="Essa é sua taxa de serviço de uso da plataforma Motum" arrow>
                                <span>
                                    <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                                </span>
                            </Tooltip>
                        </div>
                        {selectedStore.mode === "DEDICATED" &&
                            <div className="delivery-fees-actions">
                                <button type="button" className="add-fee-btn" onClick={() => setModalOpen(true)}>
                                    <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                    Adicionar Tarifa
                                </button>
                                <button type="button" className="submit-fees-btn" onClick={submitDeliveryFees}>
                                    <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                                    Submeter Tarifas
                                </button>
                            </div>
                        }
                    </div>
                    <div className="delivery-fees-list">
                        <h4>Tarifas da Loja</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Distância</th>
                                    <th>Valor</th>
                                    {selectedStore.mode === "DEDICATED" && <th className="td-actions">Ações</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {tarrifs && tarrifs.fees.map((deliveryFee, index) => (
                                    <tr key={index}>
                                        <td>{deliveryFee.minDistance}km - {deliveryFee.maxDistance}km</td>
                                        <td>{Conversor.converterStringReal(deliveryFee.fee)}</td>
                                        {selectedStore.mode === "DEDICATED" &&
                                            <td className="td-actions">
                                                <button type="button" className="fee-remove-btn" onClick={() => removeDeliveryFee(index)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <CreateDeliveryFee
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        onSubmit={addDeliveryFee}
                    />
                </div>
            )}

        </div>
    );
}