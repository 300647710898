import { useContext, useEffect, useMemo, useState } from "react";
import IntegrationService from "../../../../../services/IntegrationService";
import { Oval } from "react-loader-spinner";
import Conversor from "../../../../../helper/Conversor";
import './assets/scss/integration.scss';
import './assets/scss/main.scss';
import './assets/scss/list.scss';
import { UserContext } from "../../../../context/UserContext";
import ifoodLogo from '../../../../../assets/img/ifood_logo.png';
import anotaAiLogo from '../../../../../assets/img/anota_ai_logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faChevronLeft, faChevronRight, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import IntegrationSwitch from "./components/IntegrationSwitch";
import Swal from "sweetalert2";
import CopyInput from "./components/CopyInput";
import IntegrationsFilters from "./components/IntegrationsFilters";

export default function Integrations(props) {
    const { stores } = useContext(UserContext);
    const [integrations, setIntegrations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [filters, setFilters] = useState({
        storeId: stores.map((store) => store._id)
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalIntegrations: 0,
        totalPages: 1,
    });
    const integrationService = useMemo(() => new IntegrationService(), []);

    const previousPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage - 1
        }));
        setLoading(false);
    }

    const nextPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage + 1
        }));
        setLoading(false);
    }

    async function loadIntegration() {
        if (loading)
            return;
        setLoading(true);
        setSpinner(true);
        const response = await integrationService.getIntegrationsByUser(pagination.currentPage, filters.storeId);
        setTimeout(() => {
            setPagination((prevFilters) => ({
                ...prevFilters,
                totalIntegrations: response.pagination.totalIntegrations,
                totalPages: response.pagination.totalPages
            }));
            setIntegrations(response.data);
            setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    async function enableAndDisableIntegration(id, active) {
        setSpinner(true);
        await integrationService.enableAndDisable(id, active);
        setTimeout(() => {
            Swal.fire({
                title: `Integração ${active ? "Ativada" : "Desativada"} com sucesso`,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            });
            setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    async function aproveOrReproveIntegration(id, status) {
        const result = await Swal.fire({
            title: `Você deseja ${status === "APPROVED" ? "aprovar" : "reprovar"} esta integração?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Sim, ${status === "APPROVED" ? "aprovar" : "reprovar"}!`,
            confirmButtonColor: `${status === "APPROVED" ? "#50A773" : "#EA1D2C"}`,
            cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
            setSpinner(true);
            await integrationService.updateIntegrationStatus(id, status);
            setTimeout(() => {
                Swal.fire({
                    title: `Integração ${status === "APPROVED" ? "aprovada" : "reprovada"} com sucesso`,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                });
                setSpinner(false);
                setLoading(false);
            }, 1000);
        }
    }

    async function removeIntegration(id) {
        const result = await Swal.fire({
            title: "Você deseja remover esta integração?",
            text: "Essa ação é irreversível",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, remover!",
            confirmButtonColor: "#EA1D2C",
            cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
            setSpinner(true);
            await integrationService.destroy(id);
            setTimeout(() => {
                Swal.fire({
                    title: `Integração removida com sucesso`,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                });
                setSpinner(false);
                setLoading(false);
            }, 1000);
        }

    }

    useEffect(() => {
        loadIntegration();
        // eslint-disable-next-line
    }, [filters]);

    return (
        <div className={`integrations-container ${spinner ? `loading-integration` : ``}`}>
            <div className="integrations-header">
                <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <div className="integrations-title">Integrações</div>
            </div>
            <div className="integrations-search">
                <IntegrationsFilters filters={filters} setFilters={setFilters} />
            </div>
            <div className={`${spinner ? "support-integrations-list-loading" : "support-integrations-list"}`}>
                {spinner ? (
                    <Oval
                        visible={loading}
                        height="50"
                        width="50"
                        color="#000"
                        secondaryColor="#ccc"
                        radius="1"
                    />
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Loja</th>
                                <th>Canal de Vendas</th>
                                <th>Token/Merchant ID</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {integrations.length > 0 && integrations.map((integration) => (
                                <tr className="integration-item" key={integration._id}>
                                    <td>{integration.store.companyName} - {Conversor.converterFormatoTelefone(String(integration.store.cellNumber))}</td>
                                    <td className="type-td">
                                        <span className={`type ${integration.type === "IFOOD" ? 'ifood-type' : 'anota-ai-type'}`}>
                                            {integration.type === "IFOOD" ? <img className='type-logo' src={ifoodLogo} alt='IFood Logo'></img> : <img className='type-logo' src={anotaAiLogo} alt='Anota AI Logo'></img>}
                                        </span>
                                    </td>
                                    <td className="token-td">
                                        <CopyInput integration={integration} />
                                    </td>
                                    <td className="actions-buttons">
                                        {integration.status === "APPROVED" ?
                                            <IntegrationSwitch id={integration._id} initialActive={integration.active} enableAndDisableIntegration={enableAndDisableIntegration} /> :
                                            <>
                                                <button type="button" className="approved" onClick={() => aproveOrReproveIntegration(integration._id, "APPROVED")}><FontAwesomeIcon icon={faCheck} /></button>
                                                <button type="button" className="denied" onClick={() => aproveOrReproveIntegration(integration._id, "DENIED")}><FontAwesomeIcon icon={faXmark} /></button>
                                            </>
                                        }
                                        <button type="button" className="destroy" onClick={() => removeIntegration(integration._id)}><FontAwesomeIcon icon={faTrash} /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="pagination-container">
                {pagination.currentPage > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                {pagination.totalPages > 1 && <span>{pagination.currentPage}</span>}
                {pagination.currentPage < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
            </div>
        </div>
    );
}