import axios from "axios";
import HttpService from "./HttpService.js";

export default class AdminService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/admin`;
        this._urlUser = `${process.env.REACT_APP_DELIVERIES_API}/users`;
        this._urlWallet = `${process.env.REACT_APP_DELIVERIES_API}/wallet`;
        this._urlDeliveryman = `${process.env.REACT_APP_DELIVERIES_API}/deliveryman`;
    }

    async getLogs(userId, page) {
        const response = await axios.get(this._urlBase+`/logs/${userId}/${page}`);
        return response.data;
    }

    async getUsers(userId) {
        const response = await this._http.get(`${this._urlBase}/users/${userId}`);
        return response;
    }

    async updateUser(userId, userData) {
        const response = await this._http.put(this._urlUser+`/id/${userId}`, userData);
        return response;
    }

    async getWallets() {
        const response = await this._http.get(this._urlBase+"/list_users_with_wallets");
        return response;
    }

    async getSupports(userId, page) {
        const response = await this._http.get(`${this._urlBase}/get_supports/${userId}/${page}`);
        return response;
    }

    async createSupport(support) {
        const response = await this._http.post(this._urlBase+`/create_support`, support);
        return response;
    }

    async addBalance(walletId, value) {
        const response = await this._http.post(this._urlWallet+`/${walletId}/add_transaction`, {
            "type": "CREDIT",
            "status": "APPROVED",
            "amount": {
              "value": value,
              "currency": "BRL"
            },
            "description": "Depósito realizado"
        });
        return response;
    }

    async removeBalance(walletId, value) {
        const response = await this._http.post(this._urlWallet+`/${walletId}/add_transaction`, {
            "type": "DEBIT",
            "status": "APPROVED",
            "amount": {
              "value": value,
              "currency": "BRL"
            },
            "description": "Saldo removido"
        });
        return response;
    }

    async getPendingUsers(userId, page, filters) {
        const queryParams = new URLSearchParams();
        if (filters.name) queryParams.append('name', filters.name);
        if (filters.cpf) queryParams.append('cpf', filters.cpf);
        if (page) queryParams.append('page', page.toString());

        const url = `${this._urlUser}/get_pending_users/${userId}?${queryParams.toString()}`;

        const response = await axios.get(url);
        return response.data;
    }

    async updateUserStatus(id, status, email) {
        const response = await this._http.put(this._urlUser+`/updateStatus/${id}`, {
            status: status,
            email: email
        });
        return response;
    }

    async updateDeliveryManStatus(id, status) {
        const response = await this._http.put(this._urlDeliveryman+`/${id}`, {
            status: status
        });
        return response;
    }

    async getStoreByUser(userId) {
        const response = await axios.get(`${this._urlBase}/get_store_by_user/${userId}`);
        return response.data;
    }

    async storeDeliveryFees(tarrifs) {
        const response = await this._http.put(`${this._urlBase}/change_delivery_fees`, tarrifs);
        return response
    }
}
