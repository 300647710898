import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { CurrencyInput } from "react-currency-mask";

const CreateDeliveryFee = ({ open, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
        minDistance: "",
        maxDistance: "",
        fee: ""
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleValue = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formattedData = {
            minDistance: Number(formData.minDistance),
            maxDistance: Number(formData.maxDistance),
            fee: Number(formData.fee)
        };

        onSubmit(formattedData);
        onClose();
        setFormData({ minDistance: "", maxDistance: "", fee: "" });
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2" mb={2}>Criar nova tarifa</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Início do intervalo (km)."
                        placeholder="Digite em formato numéro. Ex.: 2.5"
                        name="minDistance"
                        type="number"
                        value={formData.minDistance}
                        onChange={handleChange}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        label="Fim do intervalo (km)"
                        placeholder="Digite em formato numéro. Ex.: 5.5"
                        name="maxDistance"
                        type="number"
                        value={formData.maxDistance}
                        onChange={handleChange}
                        margin="normal"
                    />

                    <CurrencyInput
                        name="fee"
                        onChangeValue={(event, originalValue, maskedValue) => {
                            handleValue("fee", originalValue);
                        }}
                        value={formData.fee}
                        InputElement={
                        <TextField
                            fullWidth
                            label="Taxa"
                            margin="normal"
                        />}
                    />

                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button variant="contained" style={{ backgroundColor: '#6c6768' }} onClick={onClose}>Cancelar</Button>
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#000' }}>Salvar</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateDeliveryFee;