import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';

function StoreForm(props) {

    const formDataUser = props.formUserData;
    const formDataStore = props.formStoreData;
    const handleChangeUser = props.handleChangeUser;
    const handleChangeStore = props.handleChangeStore;

    useEffect(() => {
        const password = document.getElementById("password");
        const confirm_password = document.getElementById("confirmpassword");

        const validatePassword = () => {
            if (password.value !== confirm_password.value) {
                confirm_password.setCustomValidity("Senhas diferentes!");
            } else {
                confirm_password.setCustomValidity('');
            }
        };

        // Adiciona os event listeners
        password.addEventListener('change', validatePassword);
        confirm_password.addEventListener('keyup', validatePassword);

        // Limpa os event listeners ao desmontar o componente
        return () => {
            password.removeEventListener('change', validatePassword);
            confirm_password.removeEventListener('keyup', validatePassword);
        };
    }, []); // O array vazio garante que o useEffect só roda uma vez (montagem)

    return (
        <div className='store-form'>
            <div className='form-title'>Dados adicionais</div>
            <div className='form-subtitle'>Identificação</div>
            <label className='form-label mb1'>
                <div className='label-text'>CPF</div>
                <InputMask
                    className="form-control-solo"
                    placeholder='Digite seu CPF'
                    type="text"
                    name="cpf"
                    mask="999.999.999-99"
                    value={formDataUser.cpf}
                    onChange={handleChangeUser}
                    required
                />
            </label>
            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Senha</div>
                    <input
                        className="form-control"
                        type="password"
                        placeholder='Digite sua senha'
                        name="password"
                        id="password"
                        value={formDataUser.password}
                        onChange={handleChangeUser}
                        required
                    />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Confirmar Senha</div>
                    <input
                        className="form-control"
                        type="password"
                        placeholder='Confirme sua senha'
                        name="confirmpassword"
                        id="confirmpassword"
                        value={formDataUser.confirmpassword}
                        onChange={handleChangeUser}
                        required
                    />
                </label>
            </div>
            <div className='form-subtitle'>Dados da empresa</div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>CNPJ</div>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu CPNJ'
                        type="text"
                        name="cnpj"
                        mask="99.999.999/9999-99" // Máscara para CNPJ
                        value={formDataStore.cnpj}
                        onChange={handleChangeStore}
                        required
                    />
                </label>
                <label className='form-label mb2'>
                    <div className='label-text'>Nome Fantasia da Empresa*</div>
                    <input className="form-control" placeholder='Digite o nome da empresa' type="text" name="fantasyName" value={formDataStore.fantasyName} onChange={handleChangeStore} required />
                </label>
            </div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>CEP</div>
                    <InputMask
                        className="form-control"
                        placeholder='Digite o CEP da loja'
                        type="text"
                        name="cep"
                        mask="99999-999" // Máscara para CEP
                        value={formDataStore.cep}
                        onChange={handleChangeStore}
                        required
                    />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Rua</div>
                    <input className="form-control" placeholder='Digite a rua da loja' type="text" name="street" value={formDataStore.street} onChange={handleChangeStore} required />
                </label>
            </div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Nº</div>
                    <input className="form-control" placeholder='Digite o nº da loja' type="number" name="number" value={formDataStore.number} onChange={handleChangeStore} required />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Bairro</div>
                    <input className="form-control" placeholder='Digite o bairro da loja' type="text" name="neighborhood" value={formDataStore.neighborhood} onChange={handleChangeStore} required />
                </label>
            </div>

            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Complemento</div>
                    <input className="form-control" placeholder='Digite o complemento da loja' type="text" name="complement" value={formDataStore.complement} onChange={handleChangeStore} required />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>Cidade</div>
                    <input className="form-control" placeholder='Digite a cidade da loja' type="text" name="city" value={formDataStore.city} onChange={handleChangeStore} required/>
                </label>
            </div>
            <button
                type="submit"
                className="progress-button"
                >
                    Concluir
                </button>
        </div>
    );
}

export default StoreForm;
