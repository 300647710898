const kmMarks = [
    {
        value: 10,
        label: '10 km',
    },
    {
        value: 20,
        label: '20 km',
    },
    {
        value: 30,
        label: '30 km',
    },
    {
        value: 40,
        label: '40 km',
    },
    {
        value: 50,
        label: '50 km',
    },
    {
        value: 60,
        label: '60 km',
    },
    {
        value: 70,
        label: '70 km',
    },
    {
        value: 80,
        label: '80 km',
    },
    {
        value: 90,
        label: '90 km',
    },
    {
        value: 100,
        label: '100 km',
    }
];

export { kmMarks };