import React, { useEffect, useState, useMemo } from 'react';
import FirstForm from '../../layout/Register/FirstForm';
import SecondForm from '../../layout/Register/SecondForm';
import CepService from '../../../services/CepService';
import StoreService from '../../../services/StoreService';
import { Navigate } from 'react-router';
import './style.scss';
import Swal from 'sweetalert2';
import AnotaAi from '../../../assets/img/anota_ai_logo.png'
import Ifood from '../Home/img/ifood-logo-3.png'
import UserService from '../../../services/UserService';
import createUserName from './utils/createUserName';

function Register(props) {
    const [formUserData, setFormUserData] = useState({
        name: '',
        email: '',
        cpf: '',
        cellNumber: '',
        cep: '',
        city: '',
        street: '',
        address_number: '',
        neighborhood: '',
        complement: '',
        type: '',
        status: 'PENDING',
        password: '',
        confirmpassword: '',
        position: ''
    });
    const [formStoreData, setFormStoreData] = useState({
        companyName: '',
        fantasyName: '',
        username: '',
        cnpj: '',
        cellNumber: '',
        status: 'PENDING',
        type: '',
        cep: '',
        city: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
        deliveryLane: '',
    });

    const cepService = useMemo(() => new CepService(), []);
    const storeService = useMemo(() => new StoreService(), []);
    const userService = useMemo(() => new UserService(), []);
    const [redirect, setRedirect] = useState(false);
    const [formStep, setFormStep] = useState(1);
    const [formComplete, setFormComplete] = useState(false);

    // O endereço do lojista será o mesmo endereço da loja
    useEffect(() => {
        setFormUserData((prev) => ({
            ...prev,
            type: formStoreData.type === "STORE" ? "SHOPKEEPER" : formStoreData.type,
            cep: formStoreData.cep,
            city: formStoreData.city,
            street: formStoreData.street,
            address_number: formStoreData.number,
            neighborhood: formStoreData.neighborhood,
            complement: formStoreData.complement
        }));
    }, [formStoreData]);

    useEffect(() => {
        setFormStoreData((prev) => ({
            ...prev,
            cellNumber: formUserData.cellNumber
        }))
    }, [formUserData]);

    useEffect(() => {
        if (formComplete) {
            setFormStep(2);
        }
    }, [formComplete]);

    const handleChangeUser = async (e) => {
        const { name, value } = e.target;
        let valueConvert = value.replace(/\D/g, '');
        if (name === "cep" && valueConvert.length === 8) {
            const addressFromCep = await cepService.buscarEndereco(valueConvert);
            setFormUserData(prevState => ({
                ...prevState,
                city: addressFromCep._city,
                complement: addressFromCep._complement,
                neighborhood: addressFromCep._neighborhood,
                address_number: addressFromCep._number,
                street: addressFromCep._street,
                state: addressFromCep._state
            }));
        }
        setFormUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeStore = async (e) => {
        const { name, value } = e.target;
        let valueConvert = value.replace(/\D/g, '');
        if (name === "cep" && valueConvert.length === 8) {
            const addressFromCep = await cepService.buscarEndereco(valueConvert);
            setFormStoreData(prevState => ({
                ...prevState,
                cep: valueConvert,
                city: addressFromCep._city,
                complement: addressFromCep._complement,
                neighborhood: addressFromCep._neighborhood,
                number: addressFromCep._number,
                street: addressFromCep._street,
                state: addressFromCep._state
            }));
        } else if (name === "companyName") {
            setFormStoreData(prevState => ({
                ...prevState,
                companyName: value,
                fantasyName: value,
                username: createUserName(value)
            }));
        } else {
            setFormStoreData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        if (formStep === 1) {
            setFormStep(2);
            e.preventDefault();
        }
        else {
            e.preventDefault();
            props.setSpinner(true);


            let addressFromStore = {
                cep: formStoreData.cep.replace(/\D/g, ''),
                city: formStoreData.city,
                street: formStoreData.street,
                number: formStoreData.number,
                neighborhood: formStoreData.neighborhood,
                complement: formStoreData.complement,
                state: formStoreData.state
            };


            // Buscar coordenadas
            const coordinates = await cepService.buscarLatitudeLongitude(addressFromStore);

            addressFromStore.location = {
                type: "Point",
                coordinates: coordinates
            }

            const formUserDataToSend = {
                name: formUserData.name,
                email: formUserData.email,
                cpf: formUserData.cpf.replace(/\D/g, ''),
                cellNumber: formUserData.cellNumber.replace(/\D/g, ''),
                address: {
                    cep: formUserData.cep.replace(/\D/g, ''),
                    city: formUserData.city,
                    street: formUserData.street,
                    address_number: formUserData.address_number,
                    neighborhood: formUserData.neighborhood,
                    complement: formUserData.complement
                },
                type: formUserData.type,
                status: formUserData.status,
                password: formUserData.password,
            };

            setTimeout(async () => {
                try {
                    const userResponse = await userService.registerUser(formUserDataToSend);
                    if (userResponse) {
                        const formStoreDataToSend = {
                            companyName: formStoreData.companyName,
                            fantasyName: formStoreData.fantasyName,
                            username: formStoreData.username,
                            cnpj: formStoreData.cnpj.replace(/\D/g, ''),
                            cellNumber: formStoreData.cellNumber.replace(/\D/g, ''),
                            status: formStoreData.status,
                            userId: userResponse.user_id,
                            type: formStoreData.type,
                            address: addressFromStore
                        };

                        const storeResponse = await storeService.registerStore(formStoreDataToSend);

                        props.setSpinner(false);

                        if (storeResponse.id) {
                            Swal.fire({
                                title: 'Cadastro realizado com sucesso!',
                                text: 'Agora é só aguardar o suporte de integração aprovar seu cadastro',
                                icon: 'success',
                                timer: 2000,
                                showConfirmButton: false,
                                didClose: () => {
                                    setRedirect(true);
                                }
                            });
                        }
                    }
                } catch (error) {
                    props.setSpinner(false);
                    Swal.fire({
                        title: 'Ocorreu um erro!',
                        text: error.message,
                        icon: 'error',
                        timer: 3000,
                        showConfirmButton: false,
                    });
                }
            }, 1000);
        };
    }

    if (redirect) {
        return <Navigate to={'/login'} />;
    }

    return (
        <div className="register-container">
            <form className="register-form" onSubmit={handleSubmit} data-form>
                <div className="form-container">
                    <div className="left-container">
                        <div className="title-left-container">
                            Com a Motum, sua estratégia de entregas está pronta para escalar.
                        </div>
                        <div className="subtitle-left-container">
                            Plataformas Parceiras da Motum que você certamente conhece
                        </div>
                        <div className="subtitle-left-container">
                            <img src={AnotaAi} className="icon-left-container-anotaai" alt='Anota Ai Logo' />
                            <img src={Ifood} className="icon-left-container-ifood" alt='Ifood Logo' />
                        </div>
                    </div>
                    <div className="right-container">
                        {formStep === 1 && (
                            <FirstForm formUserData={formUserData} formStoreData={formStoreData} handleChangeUser={handleChangeUser} handleChangeStore={handleChangeStore} setFormComplete={setFormComplete} />
                        )}
                        {formStep === 2 && (
                            <SecondForm formUserData={formUserData} formStoreData={formStoreData} handleChangeUser={handleChangeUser} handleChangeStore={handleChangeStore} />
                        )}
                        {formStep === 1 && (
                            <div className='footer-form-label'>*A Motum utiliza essas informações para entrar em contato com você em relação a produtos e serviços.</div>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Register;
