import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faLayerGroup, faLocationDot, faPhone, faStore } from '@fortawesome/free-solid-svg-icons';
import ifoodLogo from '../../../../assets/img/ifood_logo.png';
import anotaAiLogo from '../../../../assets/img/anota_ai_logo.png';
import motumLogo from '../../../../assets/img/motum.svg'
import formatarData from '../utils/formatarDate';
import Conversor from '../../../../helper/Conversor';
import { status_map, status_colors, payment_map } from '../utils/map';
import '../assets/scss/card.scss';
import helmetIcon from '../assets/img/capacete-desenho.png';

export default function OrderCard({ order, acceptOrder, handleShowModal, handleGroupOrder, showGroupCheck }) {
    const isIFood = order.orderDetails.isIfood ? true : false;
    const isAnotaAi = order.orderDetails.isAnotaAi ? true : false;

    return (
        <div className="order-card" onClick={() => handleShowModal(order)}>
            <div className="order-card-header">
                <div className="order-card-key">
                    {(showGroupCheck && !order.orderDetails.isGrouped) && <input type="checkbox" name="group-order" id={`group-order-${order._id}`} style={{ marginRight: 10, cursor: 'pointer', height: 20 }} onClick={(event) => { event.stopPropagation(); handleGroupOrder(order, event); }} />}
                    <FontAwesomeIcon icon={faKey} />
                    <span>#{order.orderDetails.deliveryCode}</span>
                    {order.orderDetails.isGrouped && <FontAwesomeIcon icon={faLayerGroup} title="Agrupamento" style={{ marginLeft: 5, height: 12, color: '#0656d1' }} />}
                </div>
                {order.orderDetails.status === "return" && <div className='return-code'><div className="return-code-title">Código de devolução</div><div className="return-code-text">{order.orderDetails.returnCode}</div></div>}
                <div className="order-card-actions">
                    <div className="order-sales-channel" style={isIFood ? { backgroundColor: "#EA1D2C" } : isAnotaAi ? { backgroundColor: "#0098FC" } : { backgroundColor: "#000" }}>
                        <img src={isIFood ? ifoodLogo : isAnotaAi ? anotaAiLogo : motumLogo} alt="IFood Logo" />
                    </div>
                </div>
            </div>
            <div className="order-card-body">
                <div className="order-store">
                    <div className="order-store-icon">
                        <FontAwesomeIcon icon={faStore} />
                    </div>
                    <div className="order-store-content">
                        <div className="order-store-name">{order.store.companyName}</div>
                    </div>
                </div>
                {(order.deliveryman && order.deliveryman.id) &&
                <div className="driver-info">
                    <div className="driver-icon">
                        <img src={helmetIcon} alt="Ícone de capacete" />
                    </div>
                    <div className="driver-content">
                        <div className="driver-name">{order.deliveryman.name}</div>
                        <div className="driver-phone"><FontAwesomeIcon icon={faPhone} /> {Conversor.converterFormatoTelefone(String(order.deliveryman.cellNumber))}</div>
                    </div>
                </div>}
                <div className="delivery-at">
                    <div className="delivery-at-icon">
                        <FontAwesomeIcon icon={faLocationDot} />
                    </div>
                    <div className="delivery-at-content">
                        {(order.orderDetails.status !== "unconfirmed" && order.orderDetails.status !== "canceled") && <div className="delivery-at-customer"> {order.requester.name}</div>}
                        <div className="delivery-at-address">{order.requester.address.street}, nº{order.requester.address.number} - {order.requester.address.neighborhood}, {order.requester.address.city} - {order.requester.address.state}</div>
                    </div>
                </div>
                <div className="order-details">
                    <div className="order-payment">
                        <div className="method">{payment_map[order.orderDetails.payment.type] === "DINHEIRO" ? (
                            <span>
                                {` DINHEIRO ${order.orderDetails.payment.change > 0 ?
                                    '(TROCO PARA ' + Conversor.converterStringReal(order.orderDetails.payment.change) + ')' : ('(Não precisa de troco)')}`}
                            </span>
                        ) : (
                            <span>
                                {` ${order.orderDetails.payment.brand ? order.orderDetails.payment.brand.toUpperCase() + ` ` : ``}`}
                                {payment_map[order.orderDetails.payment.type]}
                            </span>
                        )}</div>
                        <div className="value">{Conversor.converterStringReal(order.orderDetails.payment.price)}</div>
                    </div>
                </div>
            </div>
            <div className="order-card-footer">
                <div className="order-card-status-container">
                    <div className="order-card-status">
                        <div className="status-point" style={{ backgroundColor: status_colors[order.orderDetails.status] }}></div>
                        <span>{status_map[order.orderDetails.status]}</span>
                    </div>
                    <div className="order-card-date">{formatarData(order.orderDetails.createdAt)}</div>
                </div>
                {
                    ((isIFood || isAnotaAi) && order.orderDetails.status === "unconfirmed") &&
                    <button type='button' className="order-card-button"
                        onClick={(event) => {
                            event.stopPropagation();
                            acceptOrder(isIFood, isAnotaAi, order._id, order.store.id);
                        }}>Aceitar pedido
                    </button>
                }
            </div>
        </div>
    );
}