import { useContext, useEffect, useMemo, useState } from "react";
import AdminService from "../../../../../services/AdminService";
import Conversor from "../../../../../helper/Conversor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowTrendDown, faArrowTrendUp, faCheckSquare, faChevronLeft, faChevronRight, faClock, faMoneyBillTransfer, faMoneyBillTrendUp, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Inputmask from 'inputmask';
import WalletService from "../../../../../services/WalletService";
import { Oval } from "react-loader-spinner";
import { UserContext } from "../../../../context/UserContext";
import WalletFilters from "./components/WalletFilters";
import './assets/scss/main.scss';

export default function ManageWallet(props) {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [spinnerTransactions, setSpinnerTransactions] = useState(false);
    const [wallet, setWallet] = useState(null);
    const [transactions, setTransactions] = useState([]);

    const actualDate = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).format(new Date()).split('/').reverse().join('-');

    const [filters, setFilters] = useState({
        startDate: actualDate,
        endDate: actualDate,
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalIntegrations: 0,
        totalPages: 1,
    });
    const adminService = useMemo(() => new AdminService(), []);
    const walletService = useMemo(() => new WalletService(), []);

    const previousPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage - 1
        }));
        setLoadingTransactions(false);
    }

    const nextPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage + 1
        }));
        setLoadingTransactions(false);
    }

    async function loadWallets() {
        if (loading) {
            return;
        }
        setLoading(true);
        setSpinner(true);
        const response = await walletService.loadWallet(props.selectedUser._id);
        setWallet(response.wallet);
        setTimeout(async () => {
            setSpinner(false)
        }, 1000);
    }

    async function loadTransactions() {
        if (loadingTransactions) {
            return;
        }
        setLoadingTransactions(true);
        setSpinnerTransactions(true);
        const response = await walletService.loadTransactions(wallet._id, filters, pagination.currentPage);
        setTransactions(response.data);
        setPagination((prevFilters) => ({
            ...prevFilters,
            totalIntegrations: response.pagination.totalTransactions,
            totalPages: response.pagination.totalPages
        }));
        setTimeout(async () => {
            setSpinnerTransactions(false)
            setLoadingTransactions(false);
        }, 1000);
    }

    useEffect(() => {
        loadWallets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (wallet) loadTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet, filters, pagination]);

    const transactionMap = {
        "CREDIT": "CRÉDITO",
        "DEBIT": "DÉBITO",
        "PENDING": "PENDENTE",
        "APPROVED": "APROVADO",
        "CANCELLED": "CANCELADO"
    }

    const transactionIconMap = {
        "APPROVED": <FontAwesomeIcon icon={faCheckSquare} color="#168821ff" style={{ marginRight: 10 }} />,
        "PENDING": <FontAwesomeIcon icon={faClock} color="#ffcd07ff" style={{ marginRight: 10 }} />,
        "CANCELLED": <FontAwesomeIcon icon={faWindowClose} color="#e52207ff" style={{ marginRight: 10 }} />,
    };

    const handleBilledAcount = async () => {
        const result = await Swal.fire({
            title: `Deseja ${!wallet.billedAccount ? "tornar essa carteira faturada" : "retirar o faturamento dessa carteira"}?`,
            text: !wallet.billedAccount ? 'Carteiras faturadas podem solicitar mesmo sem saldo!' : "Carteiras não faturadas precisam adicionar saldo para utilizar a plataforma",
            icon: 'warning',
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            showCancelButton: true,
            confirmButtonText: `Sim, ${!wallet.billedAccount ? "tornar faturado" : "encerrar faturamento"}!`,
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            const response = await walletService.updateWallet(wallet._id, { "billedAccount": !wallet.billedAccount ? true : false});
            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: `${!wallet.billedAccount ? "Faturamento definido" : "Faturamento encerrado"} com sucesso!`,
                    showConfirmButton: false,
                    timer: 2000,
                    didClose: loadWallets
                });
            }
        }
    }

    const handleRemoveBalance = async () => {
        const { value: saldo } = await Swal.fire({
            title: 'Remover Saldo',
            html: `<input id="saldo-input" type="text" class="swal2-input" placeholder="R$ 0,00" />`,
            showCancelButton: true,
            confirmButtonText: 'Remover',
            confirmButtonColor: '#e52207ff',
            cancelButtonText: 'Cancelar',
            didOpen: () => {
                // Aplica a máscara ao campo de entrada usando Inputmask
                const input = document.getElementById('saldo-input');
                Inputmask({
                    alias: 'numeric',
                    groupSeparator: '.',
                    radixPoint: ',',
                    prefix: 'R$ ',
                    autoGroup: true,
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0',
                    clearMaskOnLostFocus: false
                }).mask(input);
            },
            preConfirm: () => {
                // Retorna o valor do input sem a máscara
                const input = document.getElementById('saldo-input').value;
                const numericValue = parseFloat(input.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));

                if (isNaN(numericValue) || numericValue <= 0) {
                    Swal.showValidationMessage('Por favor, insira um valor válido!');
                    return null;
                }

                return numericValue;
            }
        });

        if (saldo) {
            try {   
                props.setSpinner(true);
                await adminService.removeBalance(wallet._id, saldo);
                props.setSpinner(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Saldo removido!',
                    text: `Você removeu R$${saldo.toFixed(2).replace('.', ',')}`,
                    showConfirmButton: false,
                    timer: 2000
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro!',
                    text: 'Tente novamente',
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }
    }

    const handleAddBalance = async () => {
        const { value: saldo } = await Swal.fire({
            title: 'Adicionar Saldo',
            html: `<input id="saldo-input" type="text" class="swal2-input" placeholder="R$ 0,00" />`,
            showCancelButton: true,
            confirmButtonText: 'Adicionar',
            confirmButtonColor: '#000',
            cancelButtonText: 'Cancelar',
            didOpen: () => {
                // Aplica a máscara ao campo de entrada usando Inputmask
                const input = document.getElementById('saldo-input');
                Inputmask({
                    alias: 'numeric',
                    groupSeparator: '.',
                    radixPoint: ',',
                    prefix: 'R$ ',
                    autoGroup: true,
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0',
                    clearMaskOnLostFocus: false
                }).mask(input);
            },
            preConfirm: () => {
                // Retorna o valor do input sem a máscara
                const input = document.getElementById('saldo-input').value;
                const numericValue = parseFloat(input.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));

                if (isNaN(numericValue) || numericValue <= 0) {
                    Swal.showValidationMessage('Por favor, insira um valor válido!');
                    return null;
                }

                return numericValue;
            }
        });

        if (saldo) {
            try {
                props.setSpinner(true);
                await adminService.addBalance(wallet._id, saldo);
                props.setSpinner(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Saldo adicionado!',
                    text: `Você adicionou R$${saldo.toFixed(2).replace('.', ',')}`,
                    showConfirmButton: false,
                    timer: 2000
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro!',
                    text: 'Tente novamente',
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        }
    }

    return (
        <div className={`manage-wallet-container ${spinner ? `loading-integration` : ``}`}>
            <div className="manage-wallet-header">
                <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <div className="manage-wallet-title">Gerenciar Carteira</div>
            </div>
            {spinner ? (
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            ) :
                <div className="selected-wallet-container">
                    <div className="wallet-header-container">
                        <div className="wallet-details-container">
                            <div className="wallet-title">Detalhes da Carteira</div>
                            <div className="wallet-user-name">
                                <div className="user-content">{wallet ? wallet.userId.name : "***********"}</div>
                            </div>
                            <div className="wallet-balance">
                                <div className="amount-title">Saldo Disponível</div>
                                <div className="amount-value">{wallet ? Conversor.converterStringReal(wallet.amount.value) : "***********"}</div>
                            </div>

                        </div>
                        <div className="wallet-actions">
                            {(user.type === "ADMIN" || user.type === "SUPPORT") && <button type="button" className="box-success" onClick={handleAddBalance}><FontAwesomeIcon icon={faMoneyBillTrendUp} style={{ marginBottom: 10, height: 30 }} />Depositar fundos</button>}
                            {(user.type === "ADMIN" || user.type === "SUPPORT") && <button type="button" className="box-danger" onClick={handleRemoveBalance}><FontAwesomeIcon icon={faMoneyBillTransfer} style={{ marginBottom: 10, height: 30 }} />Sacar fundos</button>}
                            {(user.type === "ADMIN" || user.type === "SUPPORT") && <button type="button" className="box-danger" onClick={handleBilledAcount}><FontAwesomeIcon icon={wallet && !wallet.billedAccount ? faArrowTrendUp : faArrowTrendDown} style={{ marginBottom: 10, height: 30 }} />{wallet && !wallet.billedAccount ? "Tornar faturado" : "Encerrar faturamento"}</button>}
                        </div>
                    </div>
                    <div className="wallet-list-transactions">
                        <WalletFilters filters={filters} setFilters={setFilters} />
                        {spinnerTransactions ? (
                            <div className="loading-transactions">
                                <Oval
                                    visible={loading}
                                    height="50"
                                    width="50"
                                    color="#000"
                                    secondaryColor="#ccc"
                                    radius="1"
                                />
                            </div>
                        ) : (
                            <table>
                                <thead>
                                    <tr>
                                        <th className="transaction-date">Data da transação</th>
                                        <th className="transaction-value">Valor</th>
                                        <th className="transaction-description">Descrição</th>
                                        <th className="transaction-status">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.map((transaction) => (
                                        <tr key={transaction._id}>
                                            <td className="transaction-date">{Conversor.converterData(transaction.movementAt)}</td>
                                            <td className={`transaction-value${transaction.type === "CREDIT" ? "-credit" : "-debit"}`}>{transaction.type === "CREDIT" ? "+ " : "- "}{Conversor.converterStringReal(transaction.amount.value)}</td>
                                            <td className="transaction-description">{transaction.description ? transaction.description : "Não informado"}</td>
                                            <td className="transaction-status">{transactionIconMap[transaction.status]}{transactionMap[transaction.status]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        <div className="pagination-container">
                            {pagination.currentPage > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                            {pagination.totalPages > 1 && <span>{pagination.currentPage}</span>}
                            {pagination.currentPage < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}