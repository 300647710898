import React from "react";
import "./css/helpcenter.css"

export default function HelpCenter() {
    return (
        <div className="help-center-container">
            <iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://motumbrasil.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Central+de+Ajuda&submitTitle=Enviar+Ticket&submitThanks=Ticket+enviado+com+sucesso!" scrolling="no" height="500px" width="100%" frameborder="0" >
            </iframe>
        </div>
    );
}