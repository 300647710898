import React, { useContext, useEffect, useMemo, useState } from 'react';
import LoginService from '../../../services/LoginService';
import { Navigate } from 'react-router';
import { UserContext } from '../../context/UserContext';
import { AppContext } from '../../context/AppContext';
import { Link } from "react-router-dom";
import AuthService from '../../../services/AuthService';
import Swal from 'sweetalert2';
import vanMotum from "../../../assets/img/van-motum.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logo_map from '../../../helper/logos';
import './style.scss';

const Login = (props) => {
  const { user, updateUser, stayConnected, updateStayConnected } = useContext(UserContext);
  const [content, setContent] = useState("LOGIN_FORM");
  const [code, setCode] = useState(["", "", "", ""]);
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [newConfirmPasswordVisible, setNewConfirmPasswordVisible] = useState(false);

  const authService = useMemo(() => new AuthService(), []);
  const loginService = useMemo(() => new LoginService(), []);

  const handleCodeChange = (index, value) => {
    const newCode = [...code];

    if (value.match(/^[0-9a-zA-Z]$/)) {
      newCode[index] = value.toUpperCase();
      setCode(newCode);
      if (index < 3) document.getElementById(`code-${index + 1}`).focus();
    } else if (value === "") {
      newCode[index] = "";
      setCode(newCode);
      if (index > 0) document.getElementById(`code-${index - 1}`).focus();
    }
  };

  const changeStayConnected = (event) => {
    updateStayConnected(event.target.checked);
  }

  const handleEmail = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValid(validateEmail(newEmail));

  }

  const handlePassword = (event) => {
    setPassword(event.target.value);
  }

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  }

  const handleNewConfirmPassword = (event) => {
    setNewConfirmPassword(event.target.value);
  }

  const togglePasswordVisibility = (name) => {
    switch (name) {
      case "PASSWORD":
        setPasswordVisible(!passwordVisible);
        break;
      case "NEW_PASSWORD":
        setNewPasswordVisible(!newPasswordVisible);
        break;
      case "CONFIRM_NEW_PASSWORD":
        setNewConfirmPasswordVisible(!newConfirmPasswordVisible);
        break;
      default:
        break;
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const credentials = `${email}:${password}`;
    const encodedCredentials = btoa(credentials);
    props.setSpinner(true);
    setTimeout(async () => {
      try {
        const response = await loginService.sendLoginRequest(encodedCredentials);
        if (response) {
          props.setSpinner(false);
          Swal.fire({
            title: 'Login bem-sucedido!',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            didClose: () => {
              updateUser(response);
              setRedirect(true);
            }
          });
        }
      } catch (error) {
        props.setSpinner(false);
        Swal.fire({
          title: 'Ocorreu um erro ao realizar o login!',
          text: error.message,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
          didClose: () => {
            props.setSpinner(false);
          }
        });
      }
    }, 2000);
  };

  const forgotPassword = async () => {
    if (!isValid) return;
    props.setSpinner(true);
    setTimeout(async () => {
      try {
        const response = await authService.forgotPassword(email);
        if (response) {
          props.setSpinner(false);
          Swal.fire({
            title: 'Código enviado!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            didClose: () => {
              setContent("AUTH_TOKEN");
              props.setSpinner(false);
            }
          });
        }
      } catch (error) {
        console.error(error);
        props.setSpinner(false);
        Swal.fire({
          title: 'Não foi possível enviar o código!',
          text: error.message,
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
          didClose: () => {
            props.setSpinner(false);
          }
        });
      }
    }, 2000);
  }

  const checkToken = async () => {
    props.setSpinner(true);
    setTimeout(async () => {
      try {
        const response = await authService.checkToken(email, code.join(""));
        if (response) {
          props.setSpinner(false);
          Swal.fire({
            title: 'Código verificado!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            didClose: () => {
              setContent("NEW_PASSWORD");
              props.setSpinner(false);
            }
          });
        }
      } catch (error) {
        props.setSpinner(false);
        Swal.fire({
          title: 'Não foi possível verificar o código!',
          text: error.message,
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
          didClose: () => {
            props.setSpinner(false);
          }
        });
      }
    }, 2000);
  }

  const resetPassword = async () => {
    props.setSpinner(true);
    if (newPassword !== newConfirmPassword) {
      props.setSpinner(false);
      Swal.fire({
        title: 'Não foi possível verificar o código!',
        text: "As senhas não coincidem",
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
        didClose: () => {
          props.setSpinner(false);
        }
      });
      return;
    }
    setTimeout(async () => {
      try {
        const response = await authService.resetPassword(email, code.join(""), newPassword);
        if (response) {
          props.setSpinner(false);
          Swal.fire({
            title: 'A senha foi restaurada!',
            text: "Já é possível fazer login",
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            didClose: () => {
              setContent("LOGIN_FORM");
              props.setSpinner(false);
            }
          });
        }
      } catch (error) {
        props.setSpinner(false);
        Swal.fire({
          title: 'Não foi possível restaurar a senha!',
          text: error.message,
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
          didClose: () => {
            props.setSpinner(false);
          }
        });
      }
    }, 2000);
  }

  const { hostname } = useContext(AppContext);
  const logoPath = logo_map[hostname] || logo_map['motumbrasil.com'];

  useEffect(() => {
    document.title = `Login`;
  }, [user]);

  if (redirect) {
    return <Navigate to={'/ordermanagement'} />
  }

  return (
    <React.Fragment>
      <div className="main-login-container">
        <div className="left-login-image-container">
          <div className="login-image-info">
            <h1>De Onde Você Estiver.</h1>
            <h1>Para Qualquer Lugar do Brasil.</h1>
            <p>Mais rápido e mais acessível!</p>
          </div>
          <div className="login-image">
            <img src={vanMotum} alt="Mapa Motum" />
          </div>
        </div>
        <div className="main-login-form">
          {content === "LOGIN_FORM" &&
            <form onSubmit={onSubmit} data-form>
              <div className="form-login-logo">
                <img src={logoPath} alt="Logo" />
              </div>
              <div className="form-login-title"><h1>Entre na sua conta</h1></div>
              <div className="login-form-control">
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Digite seu e-mail"
                  onChange={handleEmail}
                />
                {!isValid && email.length > 0 && (
                  <p style={{ color: "red", fontSize: 12, marginTop: 5 }}>Digite um e-mail válido</p>
                )}
              </div>
              <div className="login-form-control">
                <label htmlFor="password">Senha</label>
                <div className="password-input">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Digite sua senha"
                    onChange={handlePassword}
                  />
                  <div className="password-visible" onClick={() => togglePasswordVisibility("PASSWORD")}>
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                    />
                  </div>
                </div>
              </div>
              <div className="login-form-check">
                <div className="logged-check">
                  <input type="checkbox" name="logged" id="logged" checked={stayConnected} onChange={changeStayConnected} />
                  <span>Matenha-me conectado</span>
                </div>
                <div className="forgot-password">
                  <span onClick={() => setContent("FORGOT_PASSWORD")}>Esqueceu sua senha?</span>
                </div>
              </div>
              <button className="login-submit-btn" data-button-submit>Entrar</button>
              <div className="main-footer-login">
                <span className="footer-login-text">Ainda não tem uma conta?</span>
                <span className="footer-login-link"><Link to="/register">Cadastre-se</Link></span>
              </div>
            </form>
          }
          {content === "FORGOT_PASSWORD" &&
            <div className="form-forgot-password">
              <div className="form-login-logo">
                <img src={logoPath} alt="logotipo" />
              </div>
              <div className="form-login-title"><h1>Restaurar senha</h1></div>
              <div className="login-form-control">
                <label htmlFor="email">Informe seu e-mail</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Digite seu e-mail"
                  onChange={handleEmail}
                />
                {!isValid && email.length > 0 && (
                  <p style={{ color: "red", fontSize: 12, marginTop: 5 }}>Digite um e-mail válido</p>
                )}
              </div>
              <div className="code-actions">
                <button className="code-btn-send" onClick={forgotPassword}>Enviar</button>
                <button className="code-btn-resend" onClick={() => setContent("LOGIN_FORM")}>Cancelar</button>
              </div>
            </div>
          }
          {content === "AUTH_TOKEN" &&
            <div className="form-forgot-password">
              <div className="form-login-logo">
                <img src={logoPath} alt="logotipo" />
              </div>
              <div className="form-login-title" style={{ height: "5%" }}><h1>Restaurar senha</h1></div>
              <div className="code-label">Insira o código enviado para {email}</div>
              <div className="code-inputs">
                {code.map((digit, index) => (
                  <input
                    key={index}
                    id={`code-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && !code[index]) {
                        if (index > 0) document.getElementById(`code-${index - 1}`).focus();
                      }
                    }}
                  />
                ))}
              </div>
              <div className="code-actions">
                <button className="code-btn-send" onClick={checkToken}>Verificar</button>
                <button className="code-btn-resend" onClick={forgotPassword}>Reenviar código</button>
              </div>
            </div>
          }
          {content === "NEW_PASSWORD" &&
            <div className="form-forgot-password">
              <div className="form-login-logo">
                <img src={logoPath} alt="logotipo" />
              </div>
              <div className="form-login-title"><h1>Restaurar senha</h1></div>
              <div className="login-form-control">
                <label htmlFor="password">Nova senha</label>
                <div className="password-input">
                  <input
                    type={newPasswordVisible ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Digite a nova senha"
                    onChange={handleNewPassword}
                  />
                  <div className="password-visible" onClick={() => togglePasswordVisibility("NEW_PASSWORD")}>
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                    />
                  </div>
                </div>
              </div>
              <div className="login-form-control">
                <label htmlFor="password">Confirme a senha</label>
                <div className="password-input">
                  <input
                    type={newConfirmPasswordVisible ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Confirma a senha"
                    onChange={handleNewConfirmPassword}
                  />
                  <div className="password-visible" onClick={() => togglePasswordVisibility("CONFIRM_NEW_PASSWORD")}>
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                    />
                  </div>
                </div>
              </div>
              <button className="login-submit-btn" onClick={resetPassword}>Confirmar</button>
            </div>
          }
        </div>
      </div>

    </React.Fragment>
  );
};

export default Login;
