import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock,
    faPhone,
    faLocationDot,
    faStore,
    faClipboardList,
    faImage,
    faTruckFast,
    faPercent,
    faMoneyBills,
    faMoneyBillTransfer,
    faCheck,
    faAddressCard,
    faTicket,
    faCommentDots,
    faBagShopping,
    faXmark,
    faTruckLoading,
    faBox,
    faObjectUngroup,
    faRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import Conversor from "../../../../helper/Conversor";
import OrderService from "../../../../services/OrderService";
import IFoodModal from "./IFoodModal";
import { ThreeCircles } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import convertDateToHoursAndMinutes from "../utils/convertDateToHoursAndMinutes";
import { anotaAiStatusMap, benefitMap, payment_map, status_map, statusMap } from "../utils/map";
import renderPaymentMethod from "../utils/renderPaymentMethod";
import calculateTimeInterval from "../utils/calculateTimeInterval";
import formatarDataISO from "../utils/formatarDataISO";
import formatHoursAndMinutes from "../utils/formatHoursAndMinutes";
import is0800Number from "../utils/is0800Number";
import anotaAiCancellationReasons from "../utils/anotaAiCancellationReasons";
import helmetIcon from "../assets/img/capacete-desenho.png";
import '../assets/scss/details.scss';
import OrderGroupingService from "../../../../services/OrderGroupingService";

function OrderDetails({ order, setSpinner, handleShowModal }) {
    const [cancellationReasons, setCancellationReasons] = useState([]);
    const [selectedCancellationOption, setSelectedCancellationOption] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const orderService = useMemo(() => new OrderService(), []);
    const orderGroupingService = useMemo(() => new OrderGroupingService(), []);

    const isIFood = order.orderDetails.isIfood ? true : false;
    const isAnotaAi = order.orderDetails.isAnotaAi ? true : false;

    // eslint-disable-next-line no-unused-vars
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = async () => {
        setIsModalOpen(false);
    };

    const handleOptionChange = (event) => {
        setSelectedCancellationOption(JSON.parse(event.target.value));
    };

    async function loadCancellationReasons(orderId, storeId) {
        try {
            const reasons = await orderService.getCancellationReasons(orderId, storeId);
            if (reasons.length) {
                setTimeout(() => {
                    setCancellationReasons(reasons);
                }, 1500);
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 4000,
                showConfirmButton: false,
            });
        }

    }

    async function requestReturn(orderId) {
        const result = await Swal.fire({
            title: 'Deseja solicitar retorno a loja?',
            text: order.orderDetails.mode === "CLOUD" ? "A devolução não será cobrada, porém não haverá estorno da entrega" : "A devolução não será cobrada e a entrega será reembolsada",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Sim, solicitar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            setIsModalOpen(false);
            handleShowModal(null);
            setSpinner(true);
            const response = await orderService.cancelOrder(orderId);
            if (response.ok) {
                setSpinner(false);
                Swal.fire({
                    title: 'Retorno solicitado com sucesso!',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
                return response;
            } else {
                setSpinner(false);
                Swal.fire({
                    title: 'Ocorreu um erro ao solicitar o retorno!',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                })
            }
        }
    }

    async function cancelOrder(order_id) {
        const result = await Swal.fire({
            title: 'Deseja cancelar essa entrega?',
            text: 'Essa ação é irreversível!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Sim, cancelar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            setIsModalOpen(false);
            handleShowModal(null);
            setSpinner(true);
            const response = await orderService.cancelOrder(order_id);
            if (response.ok) {
                setSpinner(false);
                Swal.fire({
                    title: 'Entrega cancelada com sucesso!',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
                return response;
            } else {
                setSpinner(false);
                Swal.fire({
                    title: 'Ocorreu um erro ao cancelar a entrega!',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                })
            }
        }
    }

    async function setPendingGroup(groupingCode, driverId) {
        const result = await Swal.fire({
            title: 'Deseja solicitar um novo entregador?',
            text: 'O entregador atual será desvinculado e o pedido aguardará um novo entregador!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Sim, solicitar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            setIsModalOpen(false);
            handleShowModal(null);
            setSpinner(true);
            const response = await orderGroupingService.setPendingGroup(groupingCode, driverId);
            if (response.ok) {
                setSpinner(false);
                Swal.fire({
                    title: 'Entrega cancelada com sucesso!',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
                return response;
            } else {
                setSpinner(false);
                Swal.fire({
                    title: 'Ocorreu um erro ao cancelar a entrega!',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                })
            }
        }
    }

    async function setPendingOrder(order_id, deliveryman_id) {
        const result = await Swal.fire({
            title: 'Deseja solicitar um novo entregador?',
            text: 'O entregador atual será desvinculado e o pedido aguardará um novo entregador!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Sim, cancelar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            setIsModalOpen(false);
            handleShowModal(null);
            setSpinner(true);
            const response = await orderService.setPendingOrder(order_id, deliveryman_id);
            if (response) {
                setSpinner(false);
                Swal.fire({
                    title: 'Entrega cancelada com sucesso!',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                });
                return response;
            } else {
                setSpinner(false);
                Swal.fire({
                    title: 'Ocorreu um erro ao cancelar a entrega!',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                })
            }
        }
    }

    async function ungroupOrders(groupingCode) {
        const result = await Swal.fire({
            title: 'Deseja desafezer o agrupamento?',
            text: 'Essa ação é irreversível!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Sim, desfazer!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            setIsModalOpen(false);
            handleShowModal(null);
            setSpinner(true);
            const response = await orderGroupingService.ungroupOrders(groupingCode);
            if (response.ok) {
                setSpinner(false);
                Swal.fire({
                    title: 'Agrupamento desfeito com sucesso!',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false
                });
                return response;
            } else {
                setSpinner(false);
                Swal.fire({
                    title: 'Ocorreu um erro ao desfazer o agrupamento!',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                })
            }
        }
    }

    async function requestCancellation(orderId, storeId, isIFood = true) {
        try {
            setIsModalOpen(false);
            handleShowModal(null);
            setSpinner(true);
            const response = await orderService.requestCancellation(orderId, storeId,
                selectedCancellationOption.cancelCodeId, selectedCancellationOption.description, isIFood);
            if (response) {
                setSpinner(false);
                Swal.fire({
                    title: 'Sua solicitação de cancelamento do pedido foi enviada!',
                    text: 'O canal de vendas analisará sua solicitação.',
                    icon: 'success',
                    timer: 4000,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            setSpinner(false);
            console.error(error);
            Swal.fire({
                title: 'Ocorreu um erro!',
                text: error,
                icon: 'error',
                timer: 4000,
                showConfirmButton: false,
            });
        }
    }

    useEffect(() => {
        if (isModalOpen && cancellationReasons.length === 0 && isIFood) {
            loadCancellationReasons(order._id, order.store.id);
        }
        // eslint-disable-next-line
    }, [isModalOpen]);

    const statusMapAt = {
        "PLACED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.createdAt) : ''}`,
        "CONFIRMED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.confirmedAt) : ''}`,
        "DISPATCHED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.dispatchedAt) : ''}`,
        "CONCLUDED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.concludedAt) : ''}`,
        "CANCELLED": `${order ? calculateTimeInterval(order.orderDetails.ifoodDetails.cancelledAt) : ''}`,
        "READY_TO_PICKUP": "Aguarde a retirada pelo cliente",
    }

    // MODAL DE DETALHES DE PEDIDO IFOOD
    if (isIFood) {
        return (
            <div className="management-order-details">
                <div className="order-details-selected-order">
                    <header>
                        <div className="requester-name">{order.requester.name}</div>
                        <div className="close-modal" onClick={() => handleShowModal(null)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </header>
                    <div className="first-header">
                        <span className="display-id">Pedido #{order.orderDetails.ifoodDetails.displayId}</span>
                        <span>●</span>
                        <span className="created-at">Feito às <b>{convertDateToHoursAndMinutes(order.orderDetails.ifoodDetails.createdAt)}</b></span>
                        <span>●</span>
                        <span className="localizer-number">Localizador do pedido <b>{order.orderDetails.ifoodDetails.localizer}</b></span>
                    </div>
                    <div className="second-header">
                        <div className="header-box">
                            <FontAwesomeIcon icon={faClock} style={{ marginRight: 5, height: 10 }} />
                            {order.orderDetails.ifoodDetails.orderTiming === "SCHEDULED" ? (
                                <span>Entregar entre {formatarDataISO(order.orderDetails.ifoodDetails.schedule.deliveryDateTimeStart)} e {formatarDataISO(order.orderDetails.ifoodDetails.schedule.deliveryDateTimeEnd)}</span>
                            ) : (
                                <span>{order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? `Entrega prevista: ${formatHoursAndMinutes(order.orderDetails.ifoodDetails.deliveryDateTime)}` : `Retirada prevista: ${formatHoursAndMinutes(order.orderDetails.ifoodDetails.takeout.takeoutDateTime)}`} </span>
                            )}
                        </div>
                        <div className="header-box">
                            <FontAwesomeIcon icon={faPhone} style={{ marginRight: 5, height: 10 }} />
                            {order.requester.cellNumber}
                            {is0800Number(order.requester.cellNumber) ? " ID: " + order.orderDetails.ifoodDetails.localizer : ""}
                        </div>
                    </div>

                    <div className="status-container">
                        <div className="status-details">
                            <h6>Status do pedido</h6>
                            <div className="status-text">{statusMap[order.orderDetails.ifoodDetails.status]}</div>
                            <h6>{statusMapAt[order.orderDetails.ifoodDetails.status]}</h6>
                        </div>
                        {order.deliveryman &&
                            <div className="driver-info">
                                <div className="driver-details">
                                    <div className="driver-name">{order.deliveryman.name}</div>
                                    <div className="driver-phone">{Conversor.converterFormatoTelefone(String(order.deliveryman.cellNumber))}</div>
                                </div>
                                <div className="driver-icon">
                                    <img src={helmetIcon} alt="Ícone de capacete" />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='order-details-follow-up'>
                        <div className={`status-box status-box-active`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faClock} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Aguardando entregador</div>
                                <div className="status-time">{Conversor.converterData(order.orderDetails.createdAt)}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.acceptedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faTruckFast} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Entregador a caminho</div>
                                <div className="status-time">{order.orderDetails.acceptedAt ? Conversor.converterData(order.orderDetails.acceptedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.arrivedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faStore} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Entregador chegou na loja</div>
                                <div className="status-time">{order.orderDetails.arrivedAt ? Conversor.converterData(order.orderDetails.arrivedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.withdrawnedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faTruckLoading} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Saiu para entrega</div>
                                <div className="status-time">{order.orderDetails.withdrawnedAt ? Conversor.converterData(order.orderDetails.withdrawnedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.deliveredAt ? `status-box status-box-active` : `status-box`} style={{ width: '9% ' }}>
                            <div className="status-circle-box" style={{ width: '100% ' }}>
                                <div className="circle-container" style={{ width: '100% ' }}>
                                    <div className="circle"><FontAwesomeIcon icon={faCheck} color="#fff" /></div>
                                </div>
                            </div>
                            <div className="status-info-text" style={{ width: '100% ' }}>
                                <div className="status-name">Entrega realizada</div>
                                <div className="status-time">{order.orderDetails.deliveredAt ? Conversor.converterData(order.orderDetails.deliveredAt) : ""}</div>
                            </div>
                        </div>
                    </div>

                    <div className="customer-address-container">
                        <div className="address">
                            <div className="location-icon">
                                <FontAwesomeIcon icon={faLocationDot} style={{ height: 10 }} />
                            </div>

                            {order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? (
                                <span>
                                    {order.requester.address.street} ,
                                    {' ' + order.requester.address.number}  -
                                    {' ' + order.requester.address.neighborhood}  -
                                    {order.requester.address.city} ●
                                    {' ' + order.requester.cellNumber} ●
                                    {' ' + order.requester.address.complement} ●
                                    {' ' + order.requester.address.state}
                                </span>) : (<span>Cliente irá retirar no restaurante. Não é necessário enviar este pedido</span>)}

                        </div>
                        <div className="delivery-type">
                            <FontAwesomeIcon icon={order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? faStore : faBagShopping} style={{ height: 10, marginRight: 5 }} />
                            {order.orderDetails.ifoodDetails.orderType === "DELIVERY" ? (`${order.orderDetails.ifoodDetails.delivery.deliveredBy === "MERCHANT" ? "Entrega Motum" : "Entrega iFood"}`) : "Retirada"}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="items-header">
                            <FontAwesomeIcon icon={faClipboardList} style={{ height: 15, marginRight: 5 }} />
                            Itens do pedido
                        </div>
                        <div className="items-map">
                            {order.orderDetails.ifoodDetails.items.map((item) => (
                                <div className="item-content" key={item.index}>
                                    <div className="item-details">
                                        <div className="main-item">
                                            <div className="item-image"><FontAwesomeIcon icon={faImage} style={{ height: 18 }} /></div>
                                            <div className="item-quantity">{item.quantity}x</div>
                                            <div className="item-name">{item.name}</div>
                                        </div>
                                        <div className="item-options">
                                            {item.options && item.options.map((option) => (
                                                <div className="options-container">
                                                    <div className="option-info">
                                                        <div className="vertical-line"></div>
                                                        <div className="option-quantity" style={{ marginRight: 10 }}>{option.quantity}x</div>
                                                        <div className="option-name">{option.name}</div>
                                                    </div>
                                                    <div className="option-price">{Conversor.converterStringReal(option.price)}</div>
                                                </div>
                                            ))}
                                        </div>
                                        {item.observations ? (
                                            <div className="item-observations">
                                                <FontAwesomeIcon icon={faCommentDots} height={10} style={{ marginRight: 8 }} />
                                                {item.observations}
                                            </div>
                                        ) : (null)}
                                    </div>
                                    <div className="item-price" style={{ marginTop: 15 }}>{Conversor.converterStringReal(item.totalPrice)}</div>
                                </div>
                            ))};
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faTruckFast} height={10} style={{ marginRight: 10 }} />
                                Taxa de entrega
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.payment.shipping + order.orderDetails.payment.serviceFee)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faPercent} height={10} style={{ marginRight: 10 }} />
                                Taxa de Serviço
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.ifoodDetails.total.additionalFees)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faMoneyBills} height={10} style={{ marginRight: 10 }} />
                                Subtotal
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.ifoodDetails.total.subTotal)}</div>
                        </div>

                        {order.orderDetails.ifoodDetails.benefits ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.ifoodDetails.benefits ? (
                            <div className="price-container">
                                <div className="price-info" style={{ display: 'inline-flex' }}>
                                    <FontAwesomeIcon icon={faTicket} height={10} style={{ marginRight: 10 }} />
                                    <div className="benefit-info">
                                        <div className="show-benefits">
                                            {order.orderDetails.ifoodDetails.benefits.find(benefit => benefit.value > 0).sponsorshipValues.find(shipValue => shipValue.value > 0).description}
                                        </div>
                                        <div className="benefit-subtitle">
                                            <span>{benefitMap[order.orderDetails.ifoodDetails.benefits.find(benefit => benefit.value > 0).sponsorshipValues.find(shipValue => shipValue.value > 0).name]}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="price-total">-{Conversor.converterStringReal(order.orderDetails.ifoodDetails.benefits.find(benefit => benefit.value > 0).sponsorshipValues.find(shipValue => shipValue.value > 0).value)}</div>
                            </div>
                        ) : (null)}

                        <div className="line"></div>
                        <div className="price-container">
                            <div className="payment-methods-info">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} height={10} style={{ marginRight: 10 }} />
                                <div className="payment-info">
                                    <div className="payment-title" style={{ marginBottom: 5 }}>{renderPaymentMethod(order.orderDetails.ifoodDetails.payments)}</div>
                                    <div className="payment-subtitle">{order.orderDetails.ifoodDetails.payments.pending > 0 ? 'O entregador deve cobrar este valor no ato da entrega' : 'O iFood já recebeu este valor e vamos repassar a sua loja'}</div>
                                </div>
                            </div>
                            <div className="price-total">{order.orderDetails.ifoodDetails.payments.pending > 0 ? Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.pending) : '-' + Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.prepaid)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faCheck} height={10} style={{ marginRight: 10 }} />
                                {order.orderDetails.ifoodDetails.payments.pending === 0 ? (
                                    "Pago via iFood, não precisa cobrar na entrega"
                                ) : (renderPaymentMethod(order.orderDetails.ifoodDetails.payments))}
                            </div>
                        </div>

                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (
                            <div className="price-container">Valor a receber em dinheiro: {Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.methods[0].cash.changeFor)}</div>
                        ) : ("")}

                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.ifoodDetails.payments.methods[0].method === "CASH" ? (
                            <div className="price-container">Valor para levar de troco: {Conversor.converterStringReal(order.orderDetails.ifoodDetails.payments.methods[0].cash.changeFor - order.orderDetails.ifoodDetails.payments.methods[0].value)}</div>
                        ) : ("")}

                        <div className="line"></div>
                        {order.requester.cpf ? (
                            <div className="pending-info-container">
                                <FontAwesomeIcon icon={faAddressCard} height={10} style={{ marginRight: 10 }} />
                                Incluir CPF na nota fiscal {order.requester.cpf}
                            </div>
                        ) : ("")}
                    </div>

                    {(order.orderDetails.status !== "withdrawn" && order.orderDetails.status !== "delivered" && order.orderDetails.status !== "canceled") &&
                        <div className="cancel-order-container">
                            {(!order.deliveryman && order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => ungroupOrders(order.orderDetails.groupingCode)}><FontAwesomeIcon icon={faObjectUngroup} style={{ height: 14, marginRight: 10 }} />Desfazer agrupamento</button>}

                            {(order.deliveryman && !order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => setPendingOrder(order._id, order.deliveryman.id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Solicitar outro entregador</button>}

                            {(order.deliveryman && order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => setPendingGroup(order.orderDetails.groupingCode, order.deliveryman.id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Solicitar outro entregador</button>}

                            {(!order.deliveryman && !order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => cancelOrder(order._id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Cancelar entrega</button>}

                            {/* {(!order.orderDetails.isGrouped && (order.orderDetails.ifoodDetails.status === "PLACED" || order.orderDetails.ifoodDetails.status === "CONFIRMED")) && <button type="button" className="cancel-order-button" onClick={handleOpenModal}><FontAwesomeIcon icon={faBan} style={{ height: 14, marginRight: 10 }} />Cancelar pedido</button>} */}
                        </div>
                    }

                    {order.orderDetails.status === "withdrawn" &&
                        <div className="cancel-order-container">
                            <button type="button" className="cancel-order-button" onClick={() => requestReturn(order._id)}><FontAwesomeIcon icon={faRotateLeft} style={{ height: 14, marginRight: 10 }} />Solicitar retorno a loja</button>
                        </div>
                    }

                    {/* Modal de Cancelamento */}
                    <IFoodModal show={isModalOpen} onClose={handleCloseModal}>
                        <div className="cancellation-ifood-modal-title">Cancelar pedido</div>
                        <div className="cancellation-ifood-modal-subtitle">
                            Cancelar muitos pedidos pode afetar o desempenho da sua loja no iFood. Assim que possível,
                            ajuste sua operação para não cancelar novos pedidos pelo mesmo motivo.
                        </div>
                        <div className="cancellation-ifood-modal-danger">
                            ATENÇÃO: Muitos cancelamentos pela falta de confirmação podem fechar o seu restaurante na plataforma
                        </div>
                        <div className="cancellation-ifood-modal-subtitle">Selecione o motivo pelo qual você não pode aceitar esse pedido:</div>
                        {cancellationReasons.length ? (
                            <div className="cancellation-ifood-radio-select">
                                {cancellationReasons.map(option => (
                                    <div className="map-option" key={option.cancelCodeId}>
                                        <input
                                            type="radio"
                                            name={option.cancelCodeId}
                                            id={option.cancelCodeId}
                                            value={JSON.stringify(option)}
                                            checked={selectedCancellationOption && (selectedCancellationOption.cancelCodeId === option.cancelCodeId)}
                                            onChange={handleOptionChange}
                                        />
                                        <label htmlFor={option.cancelCodeId}>{option.description}</label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="loading-cancellations-reasons-spinner">
                                <ThreeCircles
                                    visible={true}
                                    height="50"
                                    width="50"
                                    color="#EA1D2C"
                                    radius="1"
                                    wrapperClass="loading-interruptions-spinner"
                                />
                            </div>
                        )}

                        <div className="cancellation-ifood-modal-actions">
                            <button className="modal-back-action" onClick={handleCloseModal}>Voltar</button>
                            <button className="modal-cancel-action" onClick={async () => await requestCancellation(order._id, order.store.id)} disabled={!selectedCancellationOption}
                                style={selectedCancellationOption ? { color: '#fff', backgroundColor: '#EA1D2C', cursor: 'pointer' } :
                                    { color: '#DCDCDC', backgroundColor: 'transparent', cursor: 'not-allowed' }}>Cancelar pedido</button>
                        </div>
                    </IFoodModal>
                </div>
            </div>
        );

        // MODAL DE DETALHES DE PEDIDO ANOTA AI
    } else if (isAnotaAi) {
        return (
            <div className="management-order-details">
                <div className="order-details-selected-order">
                    <header>
                        <div className="requester-name">{order.requester.name}</div>
                        <div className="close-modal" onClick={() => handleShowModal(null)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </header>
                    <div className="first-header">
                        <span className="display-id">Entrega #{order.orderDetails.deliveryCode}</span>
                        <span>●</span>
                        <span className="created-at">Feito às <b>{convertDateToHoursAndMinutes(order.orderDetails.anotaAiDetails.createdAt)}</b></span>
                    </div>
                    <div className="second-header">
                        <div className="header-box">
                            <FontAwesomeIcon icon={faClock} style={{ marginRight: 5, height: 10 }} />
                            {order.orderDetails.anotaAiDetails.schedule_order ? (
                                <span>Entregar entre {order.orderDetails.anotaAiDetails.schedule_order.start} e {order.orderDetails.anotaAiDetails.schedule_order.end}</span>
                            ) : (
                                <span>{`Entrega prevista: ${formatHoursAndMinutes(order.orderDetails.anotaAiDetails.time_max)}`} </span>
                            )}
                        </div>
                        <div className="header-box">
                            <FontAwesomeIcon icon={faPhone} style={{ marginRight: 5, height: 10 }} />
                            {order.requester.cellNumber}
                        </div>
                    </div>
                    <div className="status-container">
                        <div className="status-details">
                            <h6>Status do pedido</h6>
                            <div className="status-text">
                                {anotaAiStatusMap[order.orderDetails.anotaAiDetails.check]}
                            </div>
                        </div>
                        {order.deliveryman &&
                            <div className="driver-info">
                                <div className="driver-details">
                                    <div className="driver-name">{order.deliveryman.name}</div>
                                    <div className="driver-phone">{Conversor.converterFormatoTelefone(String(order.deliveryman.cellNumber))}</div>
                                </div>
                                <div className="driver-icon">
                                    <img src={helmetIcon} alt="Ícone de capacete" />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='order-details-follow-up'>
                        <div className={`status-box status-box-active`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faClock} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Aguardando entregador</div>
                                <div className="status-time">{Conversor.converterData(order.orderDetails.createdAt)}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.acceptedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faTruckFast} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Entregador a caminho</div>
                                <div className="status-time">{order.orderDetails.acceptedAt ? Conversor.converterData(order.orderDetails.acceptedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.arrivedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faStore} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Entregador chegou na loja</div>
                                <div className="status-time">{order.orderDetails.arrivedAt ? Conversor.converterData(order.orderDetails.arrivedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.withdrawnedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faTruckLoading} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Saiu para entrega</div>
                                <div className="status-time">{order.orderDetails.withdrawnedAt ? Conversor.converterData(order.orderDetails.withdrawnedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.deliveredAt ? `status-box status-box-active` : `status-box`} style={{ width: '9% ' }}>
                            <div className="status-circle-box" style={{ width: '100% ' }}>
                                <div className="circle-container" style={{ width: '100% ' }}>
                                    <div className="circle"><FontAwesomeIcon icon={faCheck} color="#fff" /></div>
                                </div>
                            </div>
                            <div className="status-info-text" style={{ width: '100% ' }}>
                                <div className="status-name">Entrega realizada</div>
                                <div className="status-time">{order.orderDetails.deliveredAt ? Conversor.converterData(order.orderDetails.deliveredAt) : ""}</div>
                            </div>
                        </div>
                    </div>

                    <div className="customer-address-container">
                        <div className="address">
                            <div className="location-icon">
                                <FontAwesomeIcon icon={faLocationDot} style={{ height: 10 }} />
                            </div>
                            {order.orderDetails.anotaAiDetails.type === "DELIVERY" ? (
                                <span>
                                    {order.requester.address.street} ,
                                    {' ' + order.requester.address.number}  -
                                    {' ' + order.requester.address.neighborhood}  -
                                    {' ' + order.requester.address.city} ●
                                    {' ' + order.requester.cellNumber} ●
                                    {' ' + order.requester.address.complement} ●
                                    {' ' + order.requester.address.state}
                                </span>) :
                                (<span>Cliente irá retirar no restaurante. Não é necessário enviar este pedido</span>)}
                        </div>
                        <div className="delivery-type">
                            <FontAwesomeIcon icon={faStore} style={{ height: 10, marginRight: 5 }} />
                            Entrega Motum
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="items-header">
                            <FontAwesomeIcon icon={faClipboardList} style={{ height: 15, marginRight: 5 }} />
                            Itens do pedido
                        </div>
                        <div className="items-map">
                            {order.orderDetails.anotaAiDetails.items.map((item) => (
                                <div className="item-content" key={item.index}>
                                    <div className="item-details">
                                        <div className="main-item">
                                            <div className="item-image"><FontAwesomeIcon icon={faImage} style={{ height: 18 }} /></div>
                                            <div className="item-quantity">{item.quantity}x</div>
                                            <div className="item-name">{item.name}</div>
                                        </div>
                                        <div className="item-options">
                                            {item.subItems && item.subItems.map((option) => (
                                                <div className="options-container">
                                                    <div className="option-info">
                                                        <div className="vertical-line"></div>
                                                        <div className="option-quantity" style={{ marginRight: 10 }}>{option.quantity}x</div>
                                                        <div className="option-name">{option.name}</div>
                                                    </div>
                                                    <div className="option-price">{Conversor.converterStringReal(option.totalPrice)}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="item-price" style={{ marginTop: 15 }}>{Conversor.converterStringReal(item.total)}</div>
                                </div>
                            ))};
                        </div>

                        {order.orderDetails.anotaAiDetails.observation && <div className="line"></div>}
                        {order.orderDetails.anotaAiDetails.observation &&
                            <div className="price-container">
                                <div className="price-info">
                                    <FontAwesomeIcon icon={faCommentDots} height={10} style={{ marginRight: 10 }} />
                                    Observação
                                </div>
                                <div className="price-total">{order.orderDetails.anotaAiDetails.observation}</div>
                            </div>
                        }

                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faTruckFast} height={10} style={{ marginRight: 10 }} />
                                Taxa de entrega
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.payment.shipping + order.orderDetails.payment.serviceFee)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faPercent} height={10} style={{ marginRight: 10 }} />
                                Taxa de Serviço
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.anotaAiDetails.additionalFees.reduce((acc, obj) => acc + obj.value, 0))}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faMoneyBills} height={10} style={{ marginRight: 10 }} />
                                Subtotal
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.anotaAiDetails.total)}</div>
                        </div>

                        {order.orderDetails.anotaAiDetails.discounts.length > 0 && <div className="line"></div>}
                        {order.orderDetails.anotaAiDetails.discounts.length > 0 &&
                            <div className="price-container">
                                <div className="price-info" style={{ display: 'inline-flex' }}>
                                    <FontAwesomeIcon icon={faTicket} height={10} style={{ marginRight: 10 }} />
                                    <div className="benefit-info">
                                        <div className="show-benefits">
                                            {order.orderDetails.anotaAiDetails.discounts.find(discount => discount.amount > 0).tag}
                                        </div>
                                    </div>
                                </div>
                                <div className="price-total">-{Conversor.converterStringReal(order.orderDetails.anotaAiDetails.discounts.find(discount => discount.amount > 0).amount)}</div>
                            </div>
                        }

                        <div className="line"></div>
                        <div className="price-container">
                            <div className="payment-methods-info">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} height={10} style={{ marginRight: 10 }} />
                                <div className="payment-info">
                                    <div className="payment-title" style={{ marginBottom: 5 }}>{renderPaymentMethod(order.orderDetails.anotaAiDetails.payments, false)}</div>
                                    <div className="payment-subtitle">{!order.orderDetails.anotaAiDetails.payments[0].prepaid ? 'O entregador deve cobrar este valor no ato da entrega' : 'O AnotaAi já recebeu este valor e irá repassar a sua loja'}</div>
                                </div>
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.anotaAiDetails.payments[0].value)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faCheck} height={10} style={{ marginRight: 10 }} />
                                {order.orderDetails.anotaAiDetails.payments[0].prepaid ? (
                                    "Pago via AnotaAi, não precisa cobrar na entrega"
                                ) : (renderPaymentMethod(order.orderDetails.anotaAiDetails.payments, false))}
                            </div>
                        </div>

                        {order.orderDetails.anotaAiDetails.payments[0].code === "money" ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.anotaAiDetails.payments[0].code === "money" ? (
                            <div className="price-container">Valor a receber em dinheiro: {Conversor.converterStringReal(order.orderDetails.anotaAiDetails.payments[0].changeFor)}</div>
                        ) : ("")}

                        {order.orderDetails.anotaAiDetails.payments[0].code === "money" ? (<div className="line"></div>) : (null)}
                        {order.orderDetails.anotaAiDetails.payments[0].code === "money" ? (
                            <div className="price-container">Valor para levar de troco: {Conversor.converterStringReal(order.orderDetails.anotaAiDetails.payments[0].changeFor - order.orderDetails.anotaAiDetails.payments[0].value)}</div>
                        ) : ("")}

                        <div className="line"></div>
                        {order.requester.cpf ? (
                            <div className="pending-info-container">
                                <FontAwesomeIcon icon={faAddressCard} height={10} style={{ marginRight: 10 }} />
                                Incluir CPF na nota fiscal {order.requester.cpf}
                            </div>
                        ) : ("")}



                    </div>

                    {(order.orderDetails.status !== "withdrawn" && order.orderDetails.status !== "delivered" && order.orderDetails.status !== "canceled") &&
                        <div className="cancel-order-container">
                            {(!order.deliveryman && order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => ungroupOrders(order.orderDetails.groupingCode)}><FontAwesomeIcon icon={faObjectUngroup} style={{ height: 14, marginRight: 10 }} />Desfazer agrupamento</button>}

                            {(order.deliveryman && !order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => setPendingOrder(order._id, order.deliveryman.id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Solicitar outro entregador</button>}

                            {(order.deliveryman && order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => setPendingGroup(order.orderDetails.groupingCode, order.deliveryman.id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Solicitar outro entregador</button>}

                            {(!order.deliveryman && !order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => cancelOrder(order._id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Cancelar entrega</button>}

                            {/* {(!order.orderDetails.isGrouped && (order.orderDetails.anotaAiDetails.check === 1 || order.orderDetails.anotaAiDetails.check === 0)) && <button type="button" className="cancel-order-button" onClick={handleOpenModal}><FontAwesomeIcon icon={faBan} style={{ height: 14, marginRight: 10 }} />Cancelar pedido</button>} */}
                        </div>
                    }

                    {order.orderDetails.status === "withdrawn" &&
                        <div className="cancel-order-container">
                            <button type="button" className="cancel-order-button" onClick={() => requestReturn(order._id)}><FontAwesomeIcon icon={faRotateLeft} style={{ height: 14, marginRight: 10 }} />Solicitar retorno a loja</button>
                        </div>
                    }

                    {/* Modal de Cancelamento */}
                    <IFoodModal show={isModalOpen} onClose={handleCloseModal}>
                        <div className="cancellation-ifood-modal-title">Cancelar pedido</div>
                        <div className="cancellation-ifood-modal-subtitle">
                            Cancelar muitos pedidos pode afetar o desempenho da sua loja no iFood. Assim que possível,
                            ajuste sua operação para não cancelar novos pedidos pelo mesmo motivo.
                        </div>
                        <div className="cancellation-ifood-modal-danger">
                            ATENÇÃO: Muitos cancelamentos pela falta de confirmação podem fechar o seu restaurante na plataforma
                        </div>
                        <div className="cancellation-ifood-modal-subtitle">Selecione o motivo pelo qual você não pode aceitar esse pedido:</div>
                        {anotaAiCancellationReasons.length ? (
                            <div className="cancellation-ifood-radio-select">
                                {anotaAiCancellationReasons.map(option => (
                                    <div className="map-option" key={option.cancelCodeId}>
                                        <input
                                            type="radio"
                                            name={option.cancelCodeId}
                                            id={option.cancelCodeId}
                                            value={JSON.stringify(option)}
                                            checked={selectedCancellationOption && (selectedCancellationOption.cancelCodeId === option.cancelCodeId)}
                                            onChange={handleOptionChange}
                                        />
                                        <label htmlFor={option.cancelCodeId}>{option.description}</label>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="loading-cancellations-reasons-spinner">
                                <ThreeCircles
                                    visible={true}
                                    height="50"
                                    width="50"
                                    color="#EA1D2C"
                                    radius="1"
                                    wrapperClass="loading-interruptions-spinner"
                                />
                            </div>
                        )}

                        <div className="cancellation-ifood-modal-actions">
                            <button className="modal-back-action" onClick={handleCloseModal}>Voltar</button>
                            <button className="modal-cancel-action" onClick={async () => await requestCancellation(order._id, order.store.id, false)} disabled={!selectedCancellationOption}
                                style={selectedCancellationOption ? { color: '#fff', backgroundColor: '#EA1D2C', cursor: 'pointer' } :
                                    { color: '#DCDCDC', backgroundColor: 'transparent', cursor: 'not-allowed' }}>Cancelar pedido</button>
                        </div>
                    </IFoodModal>
                </div>
            </div>
        );
    } else {
        return (
            <div className="management-order-details">
                <div className="order-details-selected-order">
                    <header>
                        <div className="requester-name">{order.requester.name ? order.requester.name : "Nome do cliente não informado"}</div>
                        <div className="close-modal" onClick={() => handleShowModal(null)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </header>
                    <div className="first-header">
                        <span className="display-id">Entrega #{order.orderDetails.deliveryCode}</span>
                        <span>●</span>
                        <span className="created-at">Feito às <b>{convertDateToHoursAndMinutes(order.orderDetails.createdAt)}</b></span>
                    </div>
                    <div className="second-header">
                        <div className="header-box">
                            <FontAwesomeIcon icon={faPhone} style={{ marginRight: 5, height: 10 }} />
                            {order.requester.cellNumber ? order.requester.cellNumber : "Não informado"}
                        </div>
                    </div>
                    <div className="status-container">
                        <div className="status-details">
                            <h6>Status da entrega</h6>
                            <div className="status-text">
                                {status_map[order.orderDetails.status]}
                            </div>
                        </div>
                        {order.deliveryman &&
                            <div className="driver-info">
                                <div className="driver-details">
                                    <div className="driver-name">{order.deliveryman.name}</div>
                                    <div className="driver-phone">{Conversor.converterFormatoTelefone(String(order.deliveryman.cellNumber))}</div>
                                </div>
                                <div className="driver-icon">
                                    <img src={helmetIcon} alt="Ícone de capacete" />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='order-details-follow-up'>
                        <div className={`status-box status-box-active`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faClock} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Aguardando entregador</div>
                                <div className="status-time">{Conversor.converterData(order.orderDetails.createdAt)}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.acceptedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faTruckFast} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Entregador a caminho</div>
                                <div className="status-time">{order.orderDetails.acceptedAt ? Conversor.converterData(order.orderDetails.acceptedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.arrivedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faStore} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Entregador chegou na loja</div>
                                <div className="status-time">{order.orderDetails.arrivedAt ? Conversor.converterData(order.orderDetails.arrivedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.withdrawnedAt ? `status-box status-box-active` : `status-box`}>
                            <div className="status-circle-box">
                                <div className="circle-container">
                                    <div className="circle"><FontAwesomeIcon icon={faTruckLoading} color="#fff" /></div>
                                </div>
                                <div className="line"></div>
                            </div>
                            <div className="status-info-text">
                                <div className="status-name">Saiu para entrega</div>
                                <div className="status-time">{order.orderDetails.withdrawnedAt ? Conversor.converterData(order.orderDetails.withdrawnedAt) : ""}</div>
                            </div>
                        </div>
                        <div className={order.orderDetails.deliveredAt ? `status-box status-box-active` : `status-box`} style={{ width: '9% ' }}>
                            <div className="status-circle-box" style={{ width: '100% ' }}>
                                <div className="circle-container" style={{ width: '100% ' }}>
                                    <div className="circle"><FontAwesomeIcon icon={faCheck} color="#fff" /></div>
                                </div>
                            </div>
                            <div className="status-info-text" style={{ width: '100% ' }}>
                                <div className="status-name">Entrega realizada</div>
                                <div className="status-time">{order.orderDetails.deliveredAt ? Conversor.converterData(order.orderDetails.deliveredAt) : ""}</div>
                            </div>
                        </div>
                    </div>

                    <div className="customer-address-container">
                        <div className="address">
                            <div className="location-icon">
                                <FontAwesomeIcon icon={faLocationDot} style={{ height: 10 }} />
                            </div>
                            <span>
                                {order.requester.address.street} ,
                                {' ' + order.requester.address.number}  -
                                {' ' + order.requester.address.neighborhood}  -
                                {' ' + order.requester.address.city} ●
                                {' ' + (order.requester.cellNumber ? order.requester.cellNumber : "Tel. não informado")} ●
                                {' ' + (order.requester.address.complement ? order.requester.address.complement : "Casa")} ●
                                {' ' + order.requester.address.state}
                            </span>
                        </div>
                        <div className="delivery-type">
                            <FontAwesomeIcon icon={faStore} style={{ height: 10, marginRight: 5 }} />
                            Entrega Motum
                        </div>
                    </div>
                    <div className="details-container">
                        {order.orderDetails.message && <div className="line"></div>}
                        {order.orderDetails.message &&
                            <div className="price-container">
                                <div className="price-info">
                                    <FontAwesomeIcon icon={faCommentDots} height={10} style={{ marginRight: 10 }} />
                                    Observação
                                </div>
                                <div className="price-total">{order.orderDetails.message}</div>
                            </div>
                        }
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faTruckFast} height={10} style={{ marginRight: 10 }} />
                                Taxa de entrega
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.payment.shipping + order.orderDetails.payment.serviceFee)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="price-info">
                                <FontAwesomeIcon icon={faPercent} height={10} style={{ marginRight: 10 }} />
                                Taxa de Serviço
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.payment.serviceFee)}</div>
                        </div>
                        <div className="line"></div>
                        <div className="price-container">
                            <div className="payment-methods-info">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} height={10} style={{ marginRight: 10 }} />
                                <div className="payment-info">
                                    <div className="payment-title" style={{ marginBottom: 5 }}>{payment_map[order.orderDetails.payment.type]}</div>
                                    <div className="payment-subtitle">{(order.orderDetails.payment.type !== "paid" && order.orderDetails.payment.type !== "Pago") ? 'O entregador deve cobrar este valor no ato da entrega' : 'Não é preciso cobrar no ato da entrega'}</div>
                                </div>
                            </div>
                            <div className="price-total">{Conversor.converterStringReal(order.orderDetails.payment.price)}</div>
                        </div>
                        {payment_map[order.orderDetails.payment.type] === "DINHEIRO" ? (<div className="line"></div>) : (null)}
                        {payment_map[order.orderDetails.payment.type] === "DINHEIRO" ? (
                            <div className="price-container">Valor a receber em dinheiro: {Conversor.converterStringReal(order.orderDetails.payment.change)}</div>
                        ) : ("")}

                        {payment_map[order.orderDetails.payment.type] === "DINHEIRO" ? (<div className="line"></div>) : (null)}
                        {payment_map[order.orderDetails.payment.type] === "DINHEIRO" ? (
                            <div className="price-container">Valor para levar de troco: {Conversor.converterStringReal(order.orderDetails.payment.change - order.orderDetails.payment.price)}</div>
                        ) : ("")}

                        <div className="line"></div>
                        {order.requester.cpf ? (
                            <div className="pending-info-container">
                                <FontAwesomeIcon icon={faAddressCard} height={10} style={{ marginRight: 10 }} />
                                Incluir CPF na nota fiscal {order.requester.cpf}
                            </div>
                        ) : ("")}



                    </div>

                    {(order.orderDetails.status !== "withdrawn" && order.orderDetails.status !== "delivered" && order.orderDetails.status !== "canceled") &&
                        <div className="cancel-order-container">
                            {(!order.deliveryman && order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => ungroupOrders(order.orderDetails.groupingCode)}><FontAwesomeIcon icon={faObjectUngroup} style={{ height: 14, marginRight: 10 }} />Desfazer agrupamento</button>}

                            {(order.deliveryman && !order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => setPendingOrder(order._id, order.deliveryman.id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Solicitar outro entregador</button>}

                            {(order.deliveryman && order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => setPendingGroup(order.orderDetails.groupingCode, order.deliveryman.id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Solicitar outro entregador</button>}

                            {(!order.deliveryman && !order.orderDetails.isGrouped) && <button type="button" className="cancel-order-button" onClick={() => cancelOrder(order._id)}><FontAwesomeIcon icon={faBox} style={{ height: 14, marginRight: 10 }} />Cancelar entrega</button>}
                        </div>
                    }

                    {order.orderDetails.status === "withdrawn" &&
                        <div className="cancel-order-container">
                            <button type="button" className="cancel-order-button" onClick={() => requestReturn(order._id)}><FontAwesomeIcon icon={faRotateLeft} style={{ height: 14, marginRight: 10 }} />Solicitar retorno a loja</button>
                        </div>
                    }
                </div>
            </div>
        );
    }

}

export default OrderDetails;