import { useContext, useEffect, useMemo, useState } from "react";
import Conversor from "../../../../../helper/Conversor";
import { UserContext } from "../../../../context/UserContext";
import AdminService from "../../../../../services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckSquare, faChevronLeft, faChevronRight, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import './style.scss';
import { Oval } from "react-loader-spinner";

export default function Logs(props) {
    const [logs, setLogs] = useState([]);
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalLogs: 0,
        totalPages: 1,
    });
    const adminService = useMemo(() => new AdminService(), []);

    const previousPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage - 1
        }));
        setLoading(false);
    }

    const nextPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage + 1
        }));
        setLoading(false);
    }

    async function loadLogs() {
        if (loading) {
            return;
        }
        setLoading(true);
        setSpinner(true);
        const response = await adminService.getLogs(user._id, pagination.currentPage);
        setTimeout(async () => {
            setLogs(response.data);
            setPagination(response.pagination)
            setSpinner(false);
        }, 1000);
    }

    useEffect(() => {
        loadLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <div className={`logs-container ${spinner ? `loading-integration` : ``}`}>
            <div className="activity-logs-header">
                <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <div className="activity-logs-title">Atividades no Sistema</div>
            </div>
            {spinner ? (
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            ) : (
                <table className="activity-logs-list">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Tipo</th>
                            <th>Usuário</th>
                            <th>Descrição</th>
                            <th>Status</th>
                            <th>Recurso</th>
                            <th>Localização</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.length > 0 && logs.map((log) => (
                            <tr>
                                <td>{Conversor.converterData(log.timestamp)}</td>
                                <td>{log.activityType}</td>
                                <td>{log.userName}</td>
                                <td>{log.description}</td>
                                <td className='status-label'>{log.status === "Success" ? <FontAwesomeIcon icon={faCheckSquare} color='green' /> : <FontAwesomeIcon icon={faSquareXmark} color='red' />}</td>
                                <td>{log.resourceId}</td>
                                <td>{log.ipAddress}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <div className="pagination-container">
                {pagination.currentPage > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                {pagination.totalPages > 1 && <span>{pagination.currentPage}</span>}
                {pagination.currentPage < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
            </div>
        </div>
    );
}