import React from "react";
import "./style.scss";

const DataPrivacyPolicy = () => {
  return (
    <div className="data-privacy-policy">
      <h1 className="policy-title">Termos de Uso e Politica de Privacidade</h1>
      <div className="policy-intro">
        <h1 classTitle="section-title">
          <strong>Termos de Uso</strong>
        </h1>
        <p className="policy-text">
          Estes Termos de Uso (“Termos”) regem o acesso e a utilização do
          aplicativo Motum (“Aplicativo”), desenvolvido para conectar lojistas
          e entregadores, facilitando a realização de entregas por meio de uma
          plataforma digital que utiliza a localização em segundo plano para
          otimizar a experiência e a eficiência dos serviços prestados.
        </p>
        <hr />
        <h2>
          <strong>1. Aceitação dos Termos</strong>
        </h2>
        <p className="policy-text">
          Ao acessar e utilizar o Aplicativo, o USUÁRIO (seja lojista ou
          entregador) concorda em cumprir estes Termos, bem como todas as
          políticas, diretrizes e regras aplicáveis. Caso não concorde com os
          termos aqui estabelecidos, o USUÁRIO deverá interromper
          imediatamente o uso do Aplicativo.
        </p>
        <hr />
        <h2 class="">
          <strong>2. Definições</strong>
        </h2>
        <ul class="bulleted-list">
          <li>
            <strong>Aplicativo:</strong> Plataforma digital que conecta
            lojistas e entregadores para facilitar a realização de entregas.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            <strong>Lojista:</strong> Pessoa física ou jurídica que utiliza o
            Aplicativo para ofertar seus produtos ou serviços e solicitar
            entregas.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            <strong>Entregador:</strong> Pessoa física que se cadastra no
            Aplicativo para realizar entregas de produtos.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            <strong>Usuário:</strong> Qualquer pessoa que utiliza o
            Aplicativo, seja como lojista ou entregador.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            <strong>Localização em Segundo Plano:</strong> Funcionalidade que
            permite ao Aplicativo acessar a localização do dispositivo do
            USUÁRIO mesmo quando o Aplicativo não está em uso ativo, com o
            objetivo de otimizar a gestão de entregas e a segurança do
            serviço.
          </li>
        </ul>
        <hr />
        <h2 class="">
          <strong>
            3. DOS REQUISITOS PESSOAIS E DA CONCORDÂNCIA COM NOSSOS TERMOS DE
            USO
          </strong>
        </h2>
        <h3 class="">
          <strong>3.1. Idade Mínima</strong>
        </h3>
        <p class="">
          Você, na condição de USUÁRIO, deve ter, no mínimo, 18 (dezoito) anos
          de idade para criar uma conta no MOTUM e utilizar o Serviço.
        </p>
        <h3 class="">
          <strong>
            3.1.1. Ao criar uma conta e utilizar o Serviço, você declara e
            garante que:
          </strong>
        </h3>
        <ul class="bulleted-list">
          <li>
            Possui a idade mínima exigida, não havendo qualquer
            responsabilidade do MOTUM quanto à veracidade das declarações
            realizadas pelo USUÁRIO, em conformidade com as condições
            constantes dos presentes Termos;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Pode se vincular ao MOTUM para fins de cumprimento das obrigações
            recíprocas previstas neste instrumento;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Não está impedido de utilizar o Serviço nos termos das leis do
            Brasil ou de outra jurisdição aplicável; e
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Cumprirá estes Termos e todas as leis, normas e regulamentos
            municipais, estaduais, nacionais e internacionais aplicáveis.
          </li>
        </ul>
        <h3 class="">
          <strong>3.2. Documentação dos USUÁRIOS</strong>
        </h3>
        <p class="">
          O envio da documentação correspondente para a realização do cadastro
          na Plataforma MOTUM caberá ao USUÁRIO, cuja veracidade e
          autenticidade deverão ser por ele confirmadas, responsabilizando-se
          integralmente por eventual falsidade ou imprecisão eventualmente
          cometida.
        </p>
        <hr />
        <h2 class="">
          <strong>4. DO CADASTRO NO APLICATIVO E DO REGISTRO</strong>
        </h2>
        <h3 class="">
          <strong>4.1. Declaração de Veracidade dos Dados</strong>
        </h3>
        <p class="">
          Ao completar o pré-cadastro realizado por sua OPERADORA LOGÍSTICA, o
          USUÁRIO atesta que todos os dados fornecidos são verdadeiros,
          completos e precisos.
        </p>
        <h3 class="">
          <strong>
            4.2. Responsabilidade pela Prestação de Informações Falsas,
            Incompletas, Imprecisas ou Falsas
          </strong>
        </h3>
        <p class="">
          Fornecer informações incompletas, imprecisas ou falsas constitui
          violação dos deveres previstos nestes Termos, estando o USUÁRIO
          inteiramente responsável pelos danos decorrentes de tal violação.
        </p>
        <h3 class="">
          <strong>
            4.3. Possibilidade de Fornecimento de Informações Complementares
          </strong>
        </h3>
        <p class="">
          O MOTUM poderá, a qualquer tempo, necessitar de mais informações e
          documentos sobre o USUÁRIO, seja para melhor identificá-lo ou para
          conduzir diligências internas. Neste caso, o USUÁRIO será contatado
          para fornecê-las. O USUÁRIO poderá optar por não fornecer tais
          informações, ciente de que poderá excluir seu cadastro da
          Plataforma.
        </p>
        <h3 class="">
          <strong>4.4. Obrigação de Atualização dos Dados</strong>
        </h3>
        <p class="">
          O USUÁRIO compromete-se a manter atualizadas as informações
          fornecidas no cadastro, alterando-as tão logo ocorra alguma
          modificação.
        </p>
        <h3 class="">
          <strong>
            4.5. Conferência Periódica da Veracidade das Informações
          </strong>
        </h3>
        <p class="">
          O MOTUM poderá utilizar todas as formas lícitas para verificar, a
          qualquer tempo, se as informações prestadas são verdadeiras.
        </p>
        <h3 class="">
          <strong>
            4.6. Suspensão ou Cancelamento da Conta por Falha Informacional
          </strong>
        </h3>
        <p class="">
          Caso o MOTUM constate que as informações fornecidas sejam de alguma
          forma incompletas, imprecisas ou falsas, a conta do USUÁRIO poderá
          ser suspensa ou cancelada, a exclusivo critério do MOTUM, sem
          prejuízo de outras medidas aplicáveis, não sendo devidos quaisquer
          reembolsos.
        </p>
        <h3 class="">
          <strong>4.7. Cadastro Pessoal e Intransferível</strong>
        </h3>
        <p class="">
          O cadastro no Aplicativo é pessoal e intransferível.
        </p>
        <h3 class="">
          <strong>4.8. Cadastro Único</strong>
        </h3>
        <p class="">
          Cada USUÁRIO poderá manter apenas um único cadastro junto ao
          Aplicativo.
        </p>
        <h3 class="">
          <strong>
            4.9. Responsabilidade pelo Sigilo e Segurança do Cadastro
          </strong>
        </h3>
        <p class="">
          Embora o MOTUM empregue seus melhores esforços para garantir a
          segurança do cadastro, o USUÁRIO é o único e exclusivo responsável
          pelo sigilo e segurança do seu nome de usuário e senha.
        </p>
        <hr />
        <h2 class="">
          <strong>5. DO ACESSO À CONTA PELO BENEFICIÁRIO</strong>
        </h2>
        <h3 class="">
          <strong>5.1. Da Política de Privacidade</strong>
        </h3>
        <p class="">
          Para obter mais informações sobre os dados que coletamos e como os
          utilizamos, consulte a seção Política de Privacidade, disponível no
          endereço: https://motumbrasil.com/politicas-de-privacidade/
        </p>
        <h3 class="">
          <strong>5.2. Dos Dados para Acesso à Conta</strong>
        </h3>
        <p class="">
          Os USUÁRIOS acessarão sua conta na Plataforma utilizando login e
          senha, cuja manutenção, segurança e confidencialidade são de
          responsabilidade exclusiva do USUÁRIO, comprometendo-se a não
          compartilhar tais dados com terceiros.
        </p>
        <h3 class="">
          <strong>
            5.3. Da Responsabilidade pelas Atividades Executadas no Uso do
            Aplicativo
          </strong>
        </h3>
        <p class="">
          Os USUÁRIOS são os únicos responsáveis por todas as atividades
          associadas ao uso de suas credenciais, devendo adotar o máximo de
          cuidado e zelo na gestão dessas informações, sob pena de responder
          integralmente por usos indevidos do seu cadastro.
        </p>
        <h3 class="">
          <strong>5.4. Do Dever de Proteção da Conta</strong>
        </h3>
        <p class="">
          O USUÁRIO é responsável por manter seu dispositivo e conta
          protegidos, comprometendo-se a informar imediatamente o MOTUM acerca
          de qualquer uso não autorizado ou violação de segurança, a fim de
          evitar danos a outros usuários ou terceiros. Caso identifique acesso
          indevido, deverá entrar em contato pelo e-mail:
          suporte@motumbrasil.com.br.
        </p>
        <h3 class="">
          <strong>
            5.5. Da Proibição de Transmissão da Conta a Terceiros
          </strong>
        </h3>
        <p class="">
          Em nenhuma hipótese será permitida a cessão, venda, aluguel ou
          qualquer forma de transferência da conta do USUÁRIO.
        </p>
        <hr />
        <h2 class="">
          <strong>6. DESCRIÇÃO DOS SERVIÇOS</strong>
        </h2>
        <p class="">
          O Aplicativo MOTUM permite a conexão entre lojistas e entregadores,
          oferecendo as seguintes funcionalidades, entre outras:
        </p>
        <ul class="bulleted-list">
          <li>
            Cadastro e gerenciamento de perfil;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Solicitação e aceite de entregas;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Rastreamento de entregas em tempo real, utilizando a
            funcionalidade de localização em segundo plano;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Comunicação direta entre lojistas e entregadores.
          </li>
        </ul>
        <hr />
        <h2 class="">
          <strong>
            7. AUTORIZAÇÃO PARA USO DE LOCALIZAÇÃO EM SEGUNDO PLANO
          </strong>
        </h2>
        <p class="">
          Para oferecer uma experiência aprimorada e garantir a eficiência nas
          operações de entrega, o Aplicativo requer a autorização do USUÁRIO
          para acessar sua localização em segundo plano. Ao conceder essa
          permissão, o USUÁRIO autoriza o Aplicativo a:
        </p>
        <ul class="bulleted-list">
          <li>
            Monitorar e registrar sua localização de forma contínua ou
            periódica;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Utilizar os dados de localização para determinar a rota mais
            eficiente e estimar os prazos de entrega;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Melhorar a segurança e a coordenação entre lojistas e
            entregadores.
          </li>
        </ul>
        <p class="">
          <strong>Considerações Importantes:</strong>
        </p>
        <ul class="bulleted-list">
          <li>
            O uso da localização em segundo plano é estritamente limitado às
            finalidades descritas e não será compartilhado com terceiros sem o
            consentimento explícito do USUÁRIO, salvo quando exigido por lei.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Os USUÁRIOS podem ajustar as configurações de privacidade e a
            autorização de localização a qualquer momento por meio das
            configurações do dispositivo, cientes de que isso poderá impactar
            a qualidade e a funcionalidade do serviço.
          </li>
        </ul>
        <hr />
        <h2 class="">
          <strong>8. RESPONSABILIDADES DOS USUÁRIOS</strong>
        </h2>
        <h3 class="">
          <strong>8.1. Lojistas</strong>
        </h3>
        <ul class="bulleted-list">
          <li>
            Fornecer informações precisas e atualizadas sobre seus
            estabelecimentos e produtos.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Garantir a veracidade dos dados inseridos no Aplicativo.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Utilizar a plataforma para os fins a que se destina, não
            empregando o serviço para práticas ilícitas ou fraudulentas.
          </li>
        </ul>
        <h3 class="">
          <strong>8.2. Entregadores</strong>
        </h3>
        <ul class="bulleted-list">
          <li>
            Manter as informações de cadastro atualizadas e precisas.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Utilizar o Aplicativo de acordo com as leis e regulamentos locais.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Responsabilizar-se pela condução segura durante a realização das
            entregas.
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Concordar com a utilização da localização em segundo plano para
            fins de segurança e otimização de rotas.
          </li>
        </ul>
        <hr />
        <h2 class="">
          <strong>9. RESPONSABILIDADE DO APLICATIVO</strong>
        </h2>
        <p class="">
          A MOTUM compromete-se a:
        </p>
        <ul class="bulleted-list">
          <li>
            Proporcionar um serviço estável e seguro, adotando medidas
            técnicas e administrativas para proteger os dados dos USUÁRIOS;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Utilizar as informações de localização de forma responsável e em
            conformidade com a legislação aplicável;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Fornecer suporte técnico e atendimento ao USUÁRIO para solucionar
            dúvidas ou problemas relacionados ao uso do Aplicativo.
          </li>
        </ul>
        <hr />
        <h2 class="">
          <strong>10. PRIVACIDADE E PROTEÇÃO DE DADOS</strong>
        </h2>
        <p class="">
          A coleta, o armazenamento e o tratamento dos dados pessoais,
          incluindo os dados de localização, serão realizados de acordo com a
          nossa Política de Privacidade, que integra estes Termos. O USUÁRIO
          declara estar ciente e concordar com os procedimentos adotados,
          garantindo transparência, segurança e conformidade com as
          legislações vigentes, como a Lei Geral de Proteção de Dados (LGPD).
        </p>
        <hr />
        <h2 class="">
          <strong>11. MODIFICAÇÕES DOS TERMOS</strong>
        </h2>
        <p class="">
          A MOTUM reserva-se o direito de modificar estes Termos a qualquer
          momento. As alterações serão comunicadas aos USUÁRIOS por meio do
          próprio Aplicativo ou por e-mail. O uso continuado do serviço será
          considerado como aceitação dos novos termos.
        </p>
        <hr />
        <h2 class="">
          <strong>
            12. DA LIMITAÇÃO DE RESPONSABILIDADE, AUSÊNCIA DE VÍNCULAÇÃO
            SOCIETÁRIA E/OU EMPREGATÍCIA DA MOTUM COM AS EMPRESAS PARCEIRAS E
            COM OS ANUNCIANTES
          </strong>
        </h2>
        <h3 class="">
          <strong>12.1. Excludentes de Responsabilidade da MOTUM</strong>
        </h3>
        <p class="">
          A MOTUM não se responsabiliza por quaisquer danos, prejuízos ou
          responsabilidades decorrentes da relação entre USUÁRIOS, parceiros
          comerciais ou anunciantes, ressalvadas as hipóteses em que a lei
          determine responsabilidade do Aplicativo.
        </p>
        <hr />
        <h2 class="">
          <strong>13. LEGISLAÇÃO APLICÁVEL E FORO</strong>
        </h2>
        <p class="">
          Estes Termos serão regidos e interpretados de acordo com as leis
          brasileiras. Para dirimir quaisquer controvérsias oriundas deste
          instrumento, as partes elegem o foro da comarca de Aracaju/SE,
          renunciando a qualquer outro, por mais privilegiado que seja.
        </p>
        <hr />
        <h2 class="">
          <strong>14. DAS DISPOSIÇÕES GERAIS</strong>
        </h2>
        <h3 class="">
          <strong>14.1. Conjunto de Documentos Contratuais</strong>
        </h3>
        <p class="">
          Estes Termos de Uso, juntamente com a Política de Privacidade e
          quaisquer termos divulgados ao USUÁRIO, constituem o contrato
          integral entre o USUÁRIO e a MOTUM, regulando o relacionamento com o
          Aplicativo e as regras de utilização do Serviço.
        </p>
        <h3 class="">
          <strong>14.2. Do Máximo Aproveitamento dos Termos de Uso</strong>
        </h3>
        <p class="">
          Se qualquer disposição deste contrato for considerada inválida, as
          demais disposições destes Termos de Uso permanecerão em pleno vigor
          e efeito.
        </p>
        <h3 class="">
          <strong>14.3. Da Tolerância</strong>
        </h3>
        <p class="">
          A incapacidade da Motum em exercer ou exigir qualquer direito ou
          disposição destes Termos não constituirá renúncia a esse direito ou
          disposição, mas mera tolerância temporária.
        </p>
        <h3 class="">
          <strong>14.4. Pessoalidade da Conta</strong>
        </h3>
        <p class="">
          O USUÁRIO concorda que a sua conta na Motum é intransferível e que
          todos os direitos relacionados à sua conta e ao seu conteúdo se
          encerram com o seu falecimento.
        </p>
        <h3 class="">
          <strong>14.5. Ausência de Vínculo Societário com a Motum</strong>
        </h3>
        <p class="">
          Nenhuma relação de agência, parceria, joint venture ou outra relação
          de contratação é criada como resultado destes Termos, não
          autorizando que USUÁRIO, ANUNCIANTES ou EMPRESAS PARCEIRAS façam
          quaisquer declarações em nome da MOTUM.
        </p>
        <h3 class="">
          <strong>
            14.6. Declaração de Fornecimento de Informações Verídicas
          </strong>
        </h3>
        <p class="">
          O USUÁRIO declara ter fornecido apenas informações reais e
          fidedignas.
        </p>
        <h3 class="">
          <strong>
            14.7. Declaração de Compreensão e Aceitação Plena e Irrestrita dos
            Termos
          </strong>
        </h3>
        <p class="">
          O USUÁRIO declara, de forma expressa e inequívoca, ter lido,
          entendido e aceitado a totalidade das disposições constantes nestes
          Termos de Uso, comprometendo-se ao cumprimento integral dos deveres,
          obrigações, ações e omissões aqui expressadas.
        </p>
        <h3 class="">
          <strong>
            14.8. Declaração de Disponibilidade de Esclarecimento de Dúvidas
          </strong>
        </h3>
        <p class="">
          O USUÁRIO declara que teve a oportunidade de sanar quaisquer dúvidas
          acerca destes Termos de Uso por meio do canal de Suporte ao USUÁRIO:
        </p>
        <p class="">
          <a href="https://motumbrasil.freshdesk.com/">
            https://motumbrasil.freshdesk.com/
          </a>
        </p>
        <p class="">
          <strong>14.9. Atualização do Aplicativo</strong>
        </p>
        <p class="">
          O USUÁRIO se compromete a manter o aplicativo para celular sempre
          atualizado, utilizando a última versão disponível nas lojas oficiais
          (App Store para dispositivos Apple e Google Play Store para
          dispositivos Android). Ademais, o USUÁRIO concorda em possuir um
          smartphone que, a critério da MOTUM, atenda aos requisitos mínimos
          para o uso e atualização do Aplicativo sempre que necessário.
        </p>
        <hr />
        <h2 class="">
          <strong>15. CONTATO</strong>
        </h2>
        <p class="">
          Para esclarecimentos, dúvidas ou sugestões sobre estes Termos de
          Uso, os USUÁRIOS poderão entrar em contato por meio do e-mail:
          suporte@motumbrasil.com ou por outros canais de atendimento
          disponibilizados no Aplicativo.
        </p>
        <hr />
        <p class="">
          <em>Última atualização: 25/03/2025</em>
        </p>

        <h2 className="policy-text">
          <strong>POLÍTICA DE PRIVACIDADE</strong>
        </h2>
        <h3 class="">
          <strong>MOTUM INOVA SIMPLES (I.S)</strong>
        </h3>
        <p class="">
          Esta Política de Privacidade (“Política de Privacidade”) é parte
          integrante dos Termos e Condições de Uso da MOTUM BRASIL INOVA
          SIMPLES (I.S)  (“MOTUM”) e constituem um acordo entre o USUÁRIO e a
          MOTUM, devidamente inscrita no Cadastro Nacional de Pessoa
          Jurídica/MF sob o nº 55.376.781/0001-05, com sede na Av. ENGENHEIRO
          LUIZ CARLOS BERRINI 1681 CIDADE MONCOES SÃO PAULO - SP 04571-010, e
          endereço eletrônico desenvolvimento@motumbrasil.com.
        </p>
        <p class="">
          Estamos comprometidos com a privacidade e a proteção de dados
          pessoais dos nossos usuários. Por isso, respeitamos e tratamos os
          seus dados utilizando as melhores diretrizes de proteção de dados,
          estabelecendo com o usuário o compromisso de respeitar todas as
          legislações vigentes. Nesta Política de Privacidade, iremos ajudá-lo
          a entender quais informações tratamos, o que fazemos com elas e como
          serão utilizadas.
        </p>
        <p class="">
          O aceite é manifestado pelo TITULAR a partir do momento em que
          realiza o seu cadastro ou utiliza a plataforma MOTUM. O aceite
          representa e confirma a concordância mútua do TITULAR com relação à
          esta Política de Privacidade e também à adesão integral aos Termos e
          Condições de Uso da plataforma Motum (disponível em
          https://motumbrasil.com/politicas-de-privacidade/), nos seguintes
          termos:
        </p>
        <p class="">
          TÍTULOS:
        </p>
        <p class="">
          INTRODUÇÃO
        </p>
        <p class="">
          DEFINIÇÕES
        </p>
        <p class="">
          1. INFORMAÇÕES GERAIS;
        </p>
        <p class="">
          2. DIREITOS DO TITULAR;
        </p>
        <p class="">
          3. INFORMAÇÕES TRATADAS;
        </p>
        <p class="">
          3.1. DADOS DE IDENTIFICAÇÃO DO ENTREGADOR;
        </p>
        <p class="">
          4. NECESSIDADE DO TRATAMENTO DE DADOS;
        </p>
        <p class="">
          5. SEGURANÇA DA INFORMAÇÃO;
        </p>
        <p class="">
          6. ARMAZENAMENTO DE DADOS;
        </p>
        <p class="">
          7. QUEM TERÁ ACESSO ÀS INFORMAÇÕES;
        </p>
        <p class="">
          8. DO ENCARREGADO DE PROTEÇÃO DE DADOS;
        </p>
        <p class="">
          9. DESCARTE DOS DADOS;
        </p>
        <p class="">
          10. DAS ATUALIZAÇÕES DA PRESENTE POLÍTICA;
        </p>
        <p class="">
          11. E-MAIL PARA DÚVIDAS, SUGESTÕES OU RECLAMAÇÕES;
        </p>
        <p class="">
          12. DA LEI APLICÁVEL E DO FORO DE ELEIÇÃO.
        </p>
        <p class="">
          <strong>INTRODUÇÃO:</strong>
        </p>
        <p class="">
          Respeitar a sua privacidade é a essência da Motum desde a sua origem
          (privacy by design). Nós temos como princípios oferecer serviços que
          agreguem valor aos nossos usuários, prezando pela total
          transparência em todas as formas de tratamento de dados pessoais,
          utilizando tão somente os dados que se mostrem fundamentais para
          oportunizar que os serviços sejam devidamente prestados, com o
          máximo de assertividade possível.
        </p>
        <p class="">
          <strong>DEFINIÇÕES:</strong>
        </p>
        <p class="">
          Para os fins da presente Política de Privacidade, considera-se:
        </p>
        <p class="">
          a) Anonimização – utilização de meios técnicos razoáveis e
          disponíveis no momento do tratamento, por meio dos quais um dado
          perde a possibilidade de associação, direta ou indireta, a um
          indivíduo;
        </p>
        <p class="">
          b) Autoridade Nacional de Proteção de Dados (ANPD) – órgão da
          administração pública responsável por zelar, implementar e
          fiscalizar o cumprimento desta Lei em todo o território nacional;
        </p>
        <p class="">
          c) Banco de Dados – conjunto estruturado de dados pessoais,
          estabelecido em um ou em vários locais, em suporte eletrônico ou
          físico;
        </p>
        <p class="">
          d) Consentimento – manifestação livre, informada e inequívoca pela
          qual o Titular concorda com o tratamento de seus dados pessoais para
          uma finalidade determinada;
        </p>
        <p class="">
          e) Controlador – pessoa natural ou jurídica, de direito público ou
          privado, a quem competem as decisões referentes ao tratamento de
          dados pessoais;
        </p>
        <p class="">
          f) Dado Anonimizado – dado relativo a titular que não possa ser
          identificado, considerando a utilização de meios técnicos razoáveis
          e disponíveis na ocasião de seu tratamento;
        </p>
        <p class="">
          g) Dado Pessoal – informação relacionada à pessoa natural
          identificada ou identificável;
        </p>
        <p class="">
          h) Dado Sensível – dado pessoal sobre origem racial ou étnica,
          convicção religiosa, opinião política, filiação a sindicato ou à
          organização de caráter religioso, filosófico ou político, dado
          referente à saúde ou à vida sexual, dado genético ou biométrico,
          quando vinculado a uma pessoa natural;
        </p>
        <p class="">
          i) Eliminação – exclusão de dado ou de conjunto de dados armazenados
          em banco de dados, independentemente do procedimento empregado
        </p>
        <p class="">
          j) Encarregado – pessoa indicada pelo Controlador e pelo Operador
          para atuar como canal de comunicação entre o Controlador, o Titular
          dos dados e a Autoridade Nacional de Proteção de Dados (ANPD);
        </p>
        <p class="">
          k) Operador – pessoa natural ou jurídica, de direito público ou
          privado, que realiza o tratamento de dados pessoais em nome do
          Controlador;
        </p>
        <p class="">
          l) Titular – pessoa natural a quem se referem os dados pessoais que
          são objeto de tratamento e que de alguma forma utiliza os nossos
          serviços;
        </p>
        <p class="">
          m) Tratamento – toda operação realizada com dados pessoais, como as
          que se referem à coleta, produção, recepção, classificação,
          utilização, acesso, reprodução, transmissão, distribuição,
          processamento, arquivamento, armazenamento, eliminação, avaliação ou
          controle da informação, modificação, comunicação, transferência,
          difusão ou extração; e
        </p>
        <p class="">
          n) Uso Compartilhado de Dados – comunicação, difusão, transferência
          internacional, interconexão de dados pessoais ou tratamento
          compartilhado de bancos de dados pessoais por órgãos e entidades
          públicas no cumprimento de suas competências legais, ou entre esses
          e entes privados, reciprocamente, com autorização específica, para
          uma ou mais modalidades de tratamento permitidas por esses entes
          públicos, ou entre entes privados.
        </p>
        <p class="">
          <strong>1. INFORMAÇÕES GERAIS</strong>
        </p>
        <p class="">
          Ao usar os nossos serviços, o TITULAR compartilhará algumas
          informações conosco. Sendo assim, a presente Política de Privacidade
          explica: quais informações serão tratadas, onde elas serão
          armazenadas, como serão utilizadas, quem terá acesso a essas
          informações, quais são as medidas utilizadas para oferecer controle
          e segurança às informações tratadas e como o TITULAR possui controle
          e acesso para atualizar ou apagar seus dados.
        </p>
        <p class="">
          <strong>2. DIREITOS DO TITULAR</strong>
        </p>
        <p class="">
          A MOTUM informa o compromisso de já estar adequada às normas
          previstas na Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de
          Proteção de Dados Pessoais – LGPD).
        </p>
        <p class="">
          O TITULAR possui os seguintes direitos, conferidos pela Lei Geral de
          Proteção de Dados Pessoais:
        </p>
        <p class="">
          a) Direito de confirmação da existência de tratamento;
        </p>
        <p class="">
          b) Direito de acesso;
        </p>
        <p class="">
          c) Direito de retificação;
        </p>
        <p class="">
          d) Direito de exclusão;
        </p>
        <p class="">
          e) Direito de restrição;
        </p>
        <p class="">
          f) Direito de oposição;
        </p>
        <p class="">
          g) Direito à portabilidade de dados;
        </p>
        <p class="">
          h) Direito à cópia.
        </p>
        <p class="">
          Da mesma forma, a MOTUM informa que promoverá as adequações
          necessárias para atuação em diferentes países, sempre aderindo às
          legislações nacionais e regionais de proteção de dados.
        </p>
        <p class="">
          Para maior detalhamento acerca dos direitos do TITULAR, é
          fundamental conferir os nossos Termos e Condições de Uso.
        </p>
        <p class="">
          <strong>3. INFORMAÇÕES TRATADAS</strong>
        </p>
        <p class="">
          O tratamento de dados do TITULAR será realizado em conformidade com
          o disposto na presente Política de Privacidade e, para o TITULAR
          domiciliado no Brasil, dependerá do seu consentimento, exceto nas
          hipóteses de tratamento previstas no art. 11, inciso II, da Lei
          Geral de Proteção de Dados Pessoais, quais sejam:
        </p>
        <p class="">
          “LGPD. Art. 11. O tratamento de dados pessoais sensíveis somente
          poderá ocorrer nas seguintes hipóteses: […] II – Sem fornecimento de
          consentimento do titular, nas hipóteses em que for indispensável
          para:
        </p>
        <p class="">
          a) cumprimento de obrigação legal ou regulatória pelo controlador;
        </p>
        <p class="">
          b) tratamento compartilhado de dados necessários à execução, pela
          administração pública, de políticas públicas previstas em leis ou
          regulamentos;
        </p>
        <p class="">
          c) realização de estudos por órgão de pesquisa, garantida, sempre
          que possível, a anonimização dos dados pessoais sensíveis;
        </p>
        <p class="">
          d) exercício regular de direitos, inclusive em contrato e em
          processo judicial, administrativo e arbitral, este último nos termos
          da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);
        </p>
        <p class="">
          e) proteção da vida ou da incolumidade física do titular, ou de
          terceiros;
        </p>
        <p class="">
          f) tutela da saúde, exclusivamente, em procedimento realizado por
          profissionais de saúde, serviços de saúde ou autoridade sanitária;
          ou
        </p>
        <p class="">
          g) garantia da prevenção à fraude e à segurança do titular, nos
          processos de identificação e autenticação de cadastro em sistemas
          eletrônicos, resguardados os direitos mencionados no art. 9º desta
          Lei e exceto no caso de prevalecerem direitos e liberdades
          fundamentais do titular que exijam a proteção dos dados pessoais.”
        </p>
        <p class="">
          Para prestar seus serviços, a MOTUM irá tratar os dados pessoais
          fornecidos pelo TITULAR na criação da Conta Digital Motum.
        </p>
        <p class="">
          Ocasionalmente, poderão ser tratados outros dados além daqueles
          mínimos necessários à finalidade específica de operacionalização do
          atendimento ou serviço desejado pelo titular. Nessas situações, ao
          TITULAR dos dados serão informadas as finalidades específicas de
          tais tratamentos, bem como as hipóteses legais adotadas, dentre
          aquelas previstas na Lei Geral de Proteção de Dados Pessoais.
        </p>
        <p class="">
          A Motum utilizará dados sensíveis do TITULAR a fim de
          operacionalizar a prestação de seus serviços, na forma de upload de
          uma selfie (foto/vídeo pessoal) necessária para prevenir fraudes,
          autenticar suas informações e garantir a sua segurança em nosso
          ambiente digital, conforme a Cláusula 3.1.
        </p>
        <p class="">
          <strong>3.1. DADOS DE IDENTIFICAÇÃO DO ENTREGADOR</strong>
        </p>
        <p class="">
          A utilização das funcionalidades da plataforma MOTUM pelo TITULAR
          dependerá da abertura da conta digital, sendo que, nestes casos, os
          seguintes dados poderão ser coletados e armazenados:
        </p>
        <ul class="bulleted-list">
          <li>Nome civil completo;</li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Nome social completo, se aplicável;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Número de inscrição no CPF e órgão emissor;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Documento de identificação com foto;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>Data de nascimento;</li>
        </ul>
        <ul class="bulleted-list">
          <li>Nome civil completo da mãe;</li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Endereço residencial (rua, número, bairro, cidade, UF, país, CEP);
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>Número de telefone;</li>
        </ul>
        <ul class="bulleted-list">
          <li>Endereço eletrônico;</li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Informações sobre renda, se aplicável;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Recurso de captura de vídeo pela funcionalidade “prova de vida”; e
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>Localização em tempo real.</li>
        </ul>
        <p class="">
          FINALIDADES
        </p>
        <ul class="bulleted-list">
          <li>
            Abertura da conta digital Motum do usuário entregador;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Fornecimento de acesso à plataforma Motum;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Prestação dos serviços pela Motum;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Verificação da identidade do usuário;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Identificação e contato com o usuário;
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Segurança e prevenção à fraudes; e
          </li>
        </ul>
        <ul class="bulleted-list">
          <li>
            Compartilhamento com a operadora logística do TITULAR para uso
            exclusivo na organização das escalas dos entregadores e gestão das
            entregas em tempo real.
          </li>
        </ul>
        <p class="">
          <strong>4. NECESSIDADE DO TRATAMENTO DE DADOS</strong>
        </p>
        <p class="">
          Fica explicitado que o efetivo tratamento dos dados do TITULAR
          dependerá da real necessidade, a depender do caso concreto e sempre
          de modo aderente ao princípio da necessidade. Assim, a Motum utiliza
          esses dados apenas para as finalidades descritas nesta Política de
          Privacidade e nos Termos e Condições de Uso e esclarece que tais
          dados são essenciais para a identificação do TITULAR e para a plena
          e adequada prestação dos serviços de responsabilidade da Motum ,
          aumentando a sua qualidade e assertividade, sempre em benefício do
          TITULAR. Como a prestação não poderá ser executada sem os dados
          tratados, seu tratamento é fundado especialmente nessa finalidade
          própria para tratamento.
        </p>
        <p class="">
          <strong>5. SEGURANÇA DA INFORMAÇÃO</strong>
        </p>
        <p class="">
          A Motum adota medidas de segurança adequadas com a finalidade de
          proteção contra o acesso não autorizado ou alteração, divulgação ou
          destruição não autorizada de dados. Essas medidas incluem revisões
          internas dos nossos procedimentos de cadastro, armazenamento e
          processamento de dados, bem como medidas de segurança física para
          proteção contra o acesso não autorizado a sistemas onde estão
          armazenados dados pessoais.
        </p>
        <p class="">
          Assim, são aplicados todos os esforços para proteger os seus dados
          pessoais de uso indevido, interferência, perda, acesso não
          autorizado, modificação ou divulgação. Para alcançar esse objetivo,
          a Motum conta com atualizadas ferramentas de segurança da
          informação. Dentre os recursos utilizados pela Motum, informa-se a
          observância de diretrizes fundamentais para o compliance de dados
          por meio da utilização das plataformas AWS e Banco MongoDB e
          Postgre, incrementando a proteção dos dados dos TITULARES.
        </p>
        <p class="">
          Além disso, todas as políticas e diretrizes internas foram
          atualizadas para garantir a segurança dos dados pessoais dos
          TITULARES. Porém, apesar das medidas adotadas, nenhum sistema é
          completamente seguro. Nesse sentido, a Motum se compromete a
          informar ao TITULAR em caso de qualquer incidente com seus dados
          pessoais, nos termos da legislação vigente.
        </p>
        <p class="">
          <strong>6. ARMAZENAMENTO DE DADOS</strong>
        </p>
        <p class="">
          Os dados operados pela Motum permanecerão armazenados de forma
          segura e sempre em observância às eventuais especificidades de
          atendimento de cada TITULAR e da operadora logística.
        </p>
        <p class="">
          Para a manutenção desses dados, a Motum observará as regras de
          tratamento previstas pela Lei 13.709/2018 (Lei Geral de Proteção de
          Dados Pessoais), bem como as leis e normas que lhe exigem ou lhe
          permitem a conservação e guarda por um tempo maior, também por um
          tempo mínimo, em conformidade com as especificações de determinados
          dados, sempre contando com fundamento legal próprio para a
          conservação.
        </p>
        <p class="">
          Os prazos poderão ser alterados sempre de forma a assegurar os
          direitos dos TITULARES, por mudanças nas respectivas leis, normas,
          regulamentos e afins, o que não implicará necessariamente a
          atualização imediata desta Política de Privacidade, caso a forma de
          tratamento de dados não seja impactada por essas alterações.
        </p>
        <p class="">
          Portanto, as bases legais existentes e vigentes que permitam à Motum
          realizar o tratamento de determinados dados pessoais, mesmo sem o
          consentimento do seu titular, sobrepõem-se à respectiva menção ou
          não neste documento.
        </p>
        <p class="">
          <strong>7. QUEM TERÁ ACESSO ÀS INFORMAÇÕES</strong>
        </p>
        <p class="">
          A Motum fornece os dados e as informações tratadas do TITULAR para
          sua equipe técnica e especializada, podendo ser formada por
          colaboradores e/ou funcionários sob a autorização de um gestor, os
          quais serão responsáveis por desenvolver, aperfeiçoar e administrar
          os serviços prestados, bem como pela segurança das informações
          armazenadas.
        </p>
        <p class="">
          Todos os integrantes da equipe técnica, sejam funcionários próprios
          ou parceiros, firmaram Termo de Confidencialidade declarando a
          obrigação de manter sob absoluto sigilo todas as informações
          tratadas dos TITULAR; ou seja, quaisquer dados revelados mutuamente
          em decorrência da manutenção, funcionamento e segurança dos serviços
          prestados pela Motum, abstendo-se de utilizá-las em proveito próprio
          ou de terceiros, comprometendo-se a assegurar que toda a cadeia de
          serviços também firme compromisso zelando pelas informações
          porventura acessadas, sujeitos à imposição de sanções legais, de
          ordens civil, administrativa e penal, a depender da gravidade do
          comportamento adotado, em caso de descumprimento das normas
          previstas na LGPD.
        </p>
        <p class="">
          Esclarece-se que a Motum apenas fornece dados pessoais do TITULAR a
          terceiros nos casos de: compartilhamento referente a armazenamento
          de dados em nuvem ou em servidores de armazenamento de dados; e
          compartilhamento dos dados com a operadora logística do TITULAR para
          uso exclusivo na organização das escalas dos entregadores, conforme
          a Cláusula 3.1.
        </p>
        <p class="">
          <strong>8. DO ENCARREGADO DE PROTEÇÃO DE DADOS</strong>
        </p>
        <p class="">
          O Encarregado de Proteção de Dados (Data Protection Officer) é o
          profissional encarregado de informar, aconselhar e controlar o
          responsável pelo tratamento dos dados, bem como os trabalhadores que
          tratem os dados, a respeito das obrigações do aplicativo nos termos
          da Lei Geral de Proteção de Dados Pessoais e de outras disposições
          de proteção de dados presentes na legislação nacional e
          internacional, em cooperação com a autoridade de controle
          competente.
        </p>
        <p class="">
          O TITULAR poderá entrar em contato com a Motum para a resolução de
          assuntos referentes à proteção de dados (Data Protection Officer)
          por meio do endereço eletrônico suporte@motumbrasil.com.
        </p>
        <p class="">
          <strong>9. DESCARTE DOS DADOS</strong>
        </p>
        <p class="">
          Ao final do período de temporalidade de armazenamento e desde que
          não exista uma razão válida para a manutenção das informações, os
          dados pessoais mantidos em cópias físicas serão destruídos como
          resíduo confidencial e aqueles mantidos eletronicamente serão
          eliminados dos sistemas da Motum.
        </p>
        <p class="">
          A existência de processos judiciais e/ou administrativos, bem como
          investigações em andamento, são motivos válidos para manutenção dos
          dados na base da Motum e, nesses casos, independente de
          consentimento, o período de armazenamento poderá ser prorrogado.
        </p>
        <p class="">
          No caso da Motum ter o interesse em estender o prazo de
          armazenamento e não se enquadrando nos casos de excludente de
          consentimento, deverá notificar o TITULAR dos dados pessoais por
          escrito e, com antecedência razoável da data de término do período
          de retenção, solicitará a esse manifestação expressa, com vistas a
          renovar o consentimento no tratamento.
        </p>
        <p class="">
          Na hipótese do TITULAR optar por exercer seu direito de eliminação
          dessas informações, seus dados pessoais deverão ser descartados
          prontamente pela Motum, excetuadas nas hipóteses de cumprimento de
          obrigação legal ou regulatória.
        </p>
        <p class="">
          <strong>10. DAS ATUALIZAÇÕES DA PRESENTE POLÍTICA</strong>
        </p>
        <p class="">
          A Motum poderá, unilateralmente, revisar, aprimorar, modificar e/ou
          atualizar, a qualquer momento, qualquer cláusula ou disposição
          contidas nesta Política de Privacidade ou nos seus Termos e
          Condições de Uso. A versão mais atualizada das políticas serão
          divulgadas ao TITULAR e estarão disponíveis na plataforma Motum.
        </p>
        <p class="">
          <strong>11. E-MAIL PARA DÚVIDAS, SUGESTÕES OU RECLAMAÇÕES</strong>
        </p>
        <p class="">
          Para saber mais sobre o tratamento dos dados pessoais, o interessado
          poderá contatar a Motum através do seguinte endereço eletrônico:
          contato@motumbrasil.com.
        </p>
        <p class="">
          <strong>12. DA LEI APLICÁVEL E DO FORO DE ELEIÇÃO</strong>
        </p>
        <p class="">
          Para dirimir quaisquer controvérsias oriundas do presente
          Instrumento, a Motum e o TITULAR elegem o Foro da Comarca de
          Aracaju/SE, renunciando a qualquer outro por mais privilegiado que
          seja, sendo aplicáveis as Leis da República Federativa do Brasil.
        </p>
      </div>
    </div>
  );
};

export default DataPrivacyPolicy;
