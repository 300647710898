import { useContext, useEffect, useMemo, useState } from "react";
import StoreService from "../../../../../services/StoreService";
import { UserContext } from "../../../../context/UserContext";
import CreateDeliveryFee from './components/CreateDeliveryFee';
import { Oval } from "react-loader-spinner";
import Conversor from "../../../../../helper/Conversor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillTransfer, faMoneyBillTrendUp, faQuestionCircle, faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { CurrencyInput } from 'react-currency-mask';
import { TextField, Tooltip } from '@mui/material';
import './style.scss';

export default function ManageFees(props) {
    const { stores, user } = useContext(UserContext);

    const storeFromUser = stores.find(st => st.userId === props.selectedUser._id);

    if (!storeFromUser)
        props.handleBackMenu();

    const [spinner, setSpinner] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tarrifs, setTarrifs] = useState({
        ratePerKm: 0,
        serviceFee: 0,
        licensedFee: 0,
        fees: []
    });

    const [modalOpen, setModalOpen] = useState(false);

    const storeService = useMemo(() => new StoreService(), []);

    async function loadDeliveryFees() {
        if (loading)
            return;

        setLoading(true);
        setSpinner(true);

        const response = await storeService.getDeliveryFees(storeFromUser._id);
        setTimeout(() => {
            setTarrifs(response.tariffs);
            setSpinner(false);
        }, 1000);
    }

    async function submitDeliveryFees() {
        setSpinner(true);
        await storeService.storeDeliveryFees(storeFromUser._id, tarrifs);
        setTimeout(() => {
            Swal.fire({
                title: 'Tarifas definidas com sucesso!',
                text: 'As novas tarifas começam a valer a partir de agora',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            });
            setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        loadDeliveryFees();
        // eslint-disable-next-line
    }, [loading]);

    function addDeliveryFee(fee) {
        console.log(tarrifs);
        setTarrifs(prevState => ({
            ...prevState,
            fees: [...prevState.fees, fee]
        }));
    }

    function removeDeliveryFee(indexToRemove) {
        setTarrifs(prevState => ({
            ...prevState,
            fees: prevState.fees.filter((_, index) => index !== indexToRemove)
        }));
    }

    // return (
    //     <div className="manage-fees-container">
    //         <div className="manage-fees-header">
    //             <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
    //             <div className="manage-fees-title">Controle de Tarifas - {storeFromUser.companyName}</div>
    //         </div>
    //         <div className="manage-fees-content">
    //             <div className="manage-fees-actions">
    //                 <button type="button" className="add-fee-btn" onClick={() => setModalOpen(true)}>
    //                     <FontAwesomeIcon icon={faMoneyBillTransfer} />
    //                     Adicionar Tarifa
    //                 </button>
    //                 <button type="button" className="submit-fees-btn" onClick={submitDeliveryFees}>
    //                     <FontAwesomeIcon icon={faMoneyBillTrendUp} />
    //                     Submeter Tarifas
    //                 </button>
    //             </div>
    //             <div className="manage-fees-list">
    //                 {spinner ? (
    //                     <Oval
    //                         visible={loading}
    //                         height="50"
    //                         width="50"
    //                         color="#000"
    //                         secondaryColor="#ccc"
    //                         radius="1"
    //                     />
    //                 ) : (
    //                     <table>
    //                         <thead>
    //                             <tr>
    //                                 <th>Distância</th>
    //                                 <th>Valor</th>
    //                                 {storeFromUser.mode === "DEDICATED" && <th className="td-actions">Ações</th>}
    //                             </tr>
    //                         </thead>
    //                         <tbody>
    //                             {deliveryFees.length > 0 && deliveryFees.map((deliveryFee, index) => (
    //                                 <tr key={index}>
    //                                     <td>{deliveryFee.minDistance}km - {deliveryFee.maxDistance}km</td>
    //                                     <td>{Conversor.converterStringReal(deliveryFee.fee)}</td>
    //                                     {storeFromUser.mode === "DEDICATED" &&
    //                                         <td className="td-actions">
    //                                             <button type="button" className="fee-remove-btn" onClick={() => removeDeliveryFee(index)}>
    //                                                 <FontAwesomeIcon icon={faTrash} />
    //                                             </button>
    //                                         </td>
    //                                     }
    //                                 </tr>
    //                             ))}
    //                         </tbody>
    //                     </table>
    //                 )}
    //             </div>

    //         </div>
    //         <CreateDeliveryFee
    //             open={modalOpen}
    //             onClose={() => setModalOpen(false)}
    //             onSubmit={addDeliveryFee}
    //         />
    //     </div>

    // );

    return (
        <div className={`manage-fees-container ${spinner ? 'loading-fees' : ''}`}>
            <div className="manage-fees-header">
                <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <div className="manage-fees-title">Controle de Tarifas - {storeFromUser.companyName}</div>
            </div>
            {(spinner) ? (
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            ) : (
                <div className="manage-fees-content">
                    <div className="manage-fees-inputs">
                        <div className="fee-input">
                            <CurrencyInput
                                value={tarrifs.ratePerKm}
                                onChangeValue={(event, originalValue, maskedValue) => {
                                    setTarrifs(prev => ({
                                        ...prev,
                                        ratePerKm: originalValue > 0 ? originalValue : 0.01
                                    }));
                                }}
                                InputElement={<TextField label="Tarifa por KM" size="small" disabled={user.type !== "ADMIN" && user.type !== "SUPPORT"} />}
                            />
                            <Tooltip title="Caso a loja não tiver uma regra de tarifa para aquela corrida, utiliza a tarifa por KM" arrow>
                                <span>
                                    <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                                </span>
                            </Tooltip>
                        </div>
                        <div className="fee-input">
                            <CurrencyInput
                                value={tarrifs.serviceFee}
                                onChangeValue={(event, originalValue, maskedValue) => {
                                    setTarrifs(prev => ({
                                        ...prev,
                                        serviceFee: originalValue > 0 ? originalValue : 0.01
                                    }));
                                }}
                                InputElement={<TextField label="Taxa de Serviço" size="small" disabled={user.type !== "ADMIN" && user.type !== "SUPPORT"} />}
                            />
                            <Tooltip title="Essa é sua taxa de serviço de uso da plataforma Motum" arrow>
                                <span>
                                    <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                                </span>
                            </Tooltip>
                        </div>
                        {(tarrifs.licensedFee && tarrifs.licensedFee > 0) &&
                            <div className="fee-input">
                                <CurrencyInput
                                    value={tarrifs.licensedFee}
                                    onChangeValue={(event, originalValue, maskedValue) => {
                                        console.log(originalValue);
                                        setTarrifs(prev => ({
                                            ...prev,
                                            licensedFee: originalValue > 0 ? originalValue : 0.01
                                        }));
                                    }}
                                    InputElement={<TextField label="Taxa do Licenciado" size="small" />}
                                />
                                <Tooltip title="Essa é a taxa de serviço do licenciado" arrow>
                                    <span>
                                        <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                                    </span>
                                </Tooltip>
                            </div>
                        }
                        <div className="manage-fees-actions">
                            {(storeFromUser.mode === "CLOUD" && (user.type === "ADMIN" || user.type === "SUPPORT")) &&
                            <button type="button" className="add-fee-btn" onClick={() => setModalOpen(true)}>
                                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                Adicionar Tarifa
                            </button>}
                            <button type="button" className="submit-fees-btn" onClick={submitDeliveryFees}>
                                <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                                Submeter Tarifas
                            </button>
                        </div>
                    </div>
                    <div className="manage-fees-list">
                        <h4>Tarifas da Loja</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Distância</th>
                                    <th>Valor</th>
                                    {(storeFromUser.mode === "CLOUD" && (user.type === "ADMIN" || user.type === "SUPPORT")) && <th className="td-actions">Ações</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {tarrifs && tarrifs.fees.map((deliveryFee, index) => (
                                    <tr key={index}>
                                        <td>{deliveryFee.minDistance}km - {deliveryFee.maxDistance}km</td>
                                        <td>{Conversor.converterStringReal(deliveryFee.fee)}</td>
                                        {(storeFromUser.mode === "CLOUD" && (user.type === "ADMIN" || user.type === "SUPPORT")) &&
                                            <td className="td-actions">
                                                <button type="button" className="fee-remove-btn" onClick={() => removeDeliveryFee(index)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <CreateDeliveryFee
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        onSubmit={addDeliveryFee}
                    />
                </div>
            )}

        </div>
    );
}