import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import Conversor from "../../../../../../helper/Conversor";

const UserModal = ({ user, openModal, setOpenModal }) => {
    if (!user)
        return;

    const handleCloseModal = () => {
        setOpenModal(false);
        
    };
    return (
        <Modal open={openModal} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Detalhes do Usuário
                </Typography>
                <Typography><strong>Nome:</strong> {user.name}</Typography>
                <Typography><strong>Email:</strong> {user.email}</Typography>
                <Typography><strong>Telefone:</strong> {Conversor.converterFormatoTelefone(String(user.cellNumber))}</Typography>
            </Box>
        </Modal>
    );
}

export default UserModal;