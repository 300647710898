import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import StoreService from "../../../../../services/StoreService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset, faLocationDot, faMoneyBill, faPhone, faStore, faUserTie, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import Conversor from "../../../../../helper/Conversor";
import { Oval } from "react-loader-spinner";
import './style.scss';
import Swal from "sweetalert2";
import UserService from "../../../../../services/UserService";

export default function LicensedConnection() {
    const { selectedStore } = useContext(UserContext);
    const [activeConnection, setActiveConnection] = useState(null);
    const [pendingConnections, setPendingConnections] = useState([]);
    const [loading, setLoading] = useState(true);

    const storeService = useMemo(() => new StoreService(), []);
    const userService = useMemo(() => new UserService(), []);

    async function loadConnections() {
        setLoading(true);

        const response = await storeService.getMyConnection(selectedStore._id);

        setTimeout(() => {
            setActiveConnection(response.activeConnection);
            setPendingConnections(response.pendingConnections);
            setLoading(false);
        }, 1000);
    }

    useEffect(() => {
        loadConnections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    async function updateConnectionStatus(connectionId, status) {
        if (activeConnection && status === "ACCEPTED") {
            Swal.fire({
                title: "Você tem um licenciado ativo",
                text: "Desconecte-se primeiro antes de aceitar outra conexão",
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            });
            return;
        }

        setLoading(true);

        const response = await userService.updateLicensedConnection(connectionId, status);

        setTimeout(() => {
            Swal.fire({
                title: response.message,
                icon: 'success',
                timer: 2000,
                didClose: () => {
                    loadConnections();
                },
                showConfirmButton: false,
            });
            setLoading(false);
        }, 1000);
    }

    async function removeConnection(connectionId) {
        const result = await Swal.fire({
            title: 'Deseja desconectar do licenciado atual?',
            text: 'O licenciado terá que solicitar novamente conexão e a taxa de serviço da plataforma voltará ao padrão',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#EA1D2C',
            cancelButtonColor: '#000',
            confirmButtonText: 'Sim, desconectar!',
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            setLoading(true);
    
            const response = await userService.destroyLicensedConnection(connectionId);
    
            setTimeout(() => {
                Swal.fire({
                    title: response.message,
                    icon: 'success',
                    timer: 2000,
                    didClose: () => {
                        loadConnections();
                    },
                    showConfirmButton: false,
                });
                setLoading(false);
            }, 1000);
        }

    }

    function renderAddressNumber(number) {
        return number ? `, nº${number}` : `, s/N`;
    }

    function renderAddressComplement(complement) {
        return complement ? `, ${complement}, ` : `, `;
    }

    if (loading) {
        return (
            <div className="loading-oval-content">
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            </div>
        );
    }

    return (
        <div className="licensed-connection-container">
            {activeConnection ? (
                <div className="my-licensed-connection-active">
                    <h4>Meu Licenciado</h4>
                    <div className="licensed-info-container">
                        <div className="licensed-info-profile">
                            <FontAwesomeIcon icon={faStore} />
                        </div>
                        <div className="licensed-info-details">
                            <div className="licensed-info"><FontAwesomeIcon icon={faUserTie} />{activeConnection.licensed.name}</div>
                            <div className="licensed-info"><FontAwesomeIcon icon={faHeadset} />{activeConnection.licensed.email}</div>
                            <div className="licensed-info"><FontAwesomeIcon icon={faPhone} />{Conversor.converterFormatoTelefone(String(activeConnection.licensed.cellNumber))}</div>
                            <div className="licensed-info"><FontAwesomeIcon icon={faMoneyBill} />Taxa: {Conversor.converterStringReal(activeConnection.licensed.metadata.licensedFee + selectedStore.tariffs.serviceFee)}/entrega</div>
                            <div className="licensed-info"><FontAwesomeIcon icon={faLocationDot} />
                                <span>
                                    {
                                        activeConnection.licensed.address.street +
                                        renderAddressNumber(activeConnection.licensed.address.address_number) + " - " +
                                        activeConnection.licensed.address.neighborhood +
                                        renderAddressComplement(activeConnection.licensed.address.complement) +
                                        activeConnection.licensed.address.city
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="licensed-info-actions">
                            <button type="button" onClick={() => removeConnection(activeConnection._id)}>DESCONECTAR</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="my-licensed-connection-active">
                    <h4>Nenhum licenciado ativo</h4>
                    <div className="not-found-connection">
                        <FontAwesomeIcon icon={faWarning} color="#DBA827" />
                        <span>Aceite uma conexão com um licenciado para reduzir a taxa de serviço da plataforma e ter um suporte próximo a você!</span>
                    </div>
                </div>
            )}
            <div className="pending-connections">
                <h4>Solicitações Pendentes</h4>
                <div className="pending-connections-list">
                    {pendingConnections && pendingConnections.map((connection) => (
                        <div className="store-box">
                            <div className="store-img">
                                <div className="profile">
                                    <FontAwesomeIcon icon={faStore} />
                                </div>
                            </div>
                            <div className="store-details">
                                <div className="store-name">
                                    {connection.licensed.name}
                                    {connection.status === "PENDING" &&
                                        <Tooltip title="Essa conexão está pendente de aprovação. Aceitar essa conexão irá encerrar sua conexão atual." arrow>
                                            <span>
                                                <FontAwesomeIcon icon={faWarning} color="#DBA827" />
                                            </span>
                                        </Tooltip>
                                    }
                                </div>
                                <div className="store-cell"><FontAwesomeIcon icon={faPhone} />{Conversor.converterFormatoTelefone(String(connection.licensed.cellNumber))}</div>
                                <div className="store-licensed-fee"><FontAwesomeIcon icon={faMoneyBill} />Taxa: {Conversor.converterStringReal(connection.licensed.metadata.licensedFee)}/entrega</div>
                                <div className="store-address"><FontAwesomeIcon icon={faLocationDot} />
                                    <span>
                                        {
                                            connection.licensed.address.street +
                                            renderAddressNumber(connection.licensed.address.number) + " - " +
                                            connection.licensed.address.neighborhood +
                                            renderAddressComplement(connection.licensed.address.complement) +
                                            connection.licensed.address.city + " - " +
                                            connection.licensed.address.state
                                        }
                                    </span>
                                </div>
                                <div className="store-actions">
                                    <button type="button" className="status-connection" style={{ backgroundColor: '#50A773' }} onClick={() => updateConnectionStatus(connection._id, "ACCEPTED")}>ACEITAR</button>
                                    <button type="button" className="status-connection" style={{ backgroundColor: '#EA1D2C' }} onClick={() => updateConnectionStatus(connection._id, "REJECTED")}>REJEITAR</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}