import { useContext, useEffect, useMemo, useState } from "react";
import StoreService from "../../../../../services/StoreService";
import UserService from "../../../../../services/UserService";
import { UserContext } from "../../../../context/UserContext";
import { CurrencyInput } from "react-currency-mask";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Slider, TextField, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { kmMarks } from "./utils/marks";
import NearbyStores from "./components/NearbyStores";
import MyConnections from "./components/MyConnections";
import './assets/scss/main.scss';
import { Oval } from "react-loader-spinner";
import Swal from "sweetalert2";
import Conversor from "../../../../../helper/Conversor";

export default function MyStores() {
    const { user, selectedStore, setUser } = useContext(UserContext);
    // eslint-disable-next-line no-unused-vars
    const [store, setStore] = useState(selectedStore);
    const [content, setContent] = useState("MY_STORES");
    const [serviceRadius, setServiceRadius] = useState(user.metadata.serviceRadius);
    const [licensedFee, setLicensedFee] = useState(user.metadata.licensedFee);

    const [loading, setLoading] = useState(true);

    const storeService = useMemo(() => new StoreService(), []);
    const userService = useMemo(() => new UserService(), []);

    async function loadingStore() {
        setLoading(true);

        const store = await storeService.showStore(selectedStore._id);

        setStore(store);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    async function submitFees() {
        const result = await Swal.fire({
            title: 'Deseja salvar as alterações da tarifa do licenciado?',
            text: `Você passará a receber a taxa de ${Conversor.converterStringReal(licensedFee)} de todas as lojas conectadas`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Sim, confirmar!',
            cancelButtonText: 'Voltar',
        });
        if (result.isConfirmed) {
            setLoading(true);
    
            await userService.updateLicensedFees(user._id, licensedFee, serviceRadius);
    
            const newUser = await userService.getUser(user._id);
    
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(newUser));
            setUser(newUser);
    
            setTimeout(() => {
                setLoading(false);
                Swal.fire({
                    title: `Regras do licenciado atualizadas com sucesso`,
                    icon: 'success',
                    timer: 2000,
                    didClose: () => {
                        loadingStore();
                    },
                    showConfirmButton: false,
                });
            }, 1000);
        }
    }

    useEffect(() => {
        loadingStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    if (loading) {
        return (
            <div className="my-stores-container">
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            </div>
        );
    }

    return (
        <div className="my-stores-container">
            <div className="fees-container">
                <div className="fee-input">
                    <CurrencyInput
                        value={licensedFee}
                        onChangeValue={(event, originalValue, maskedValue) => {
                            setLicensedFee(originalValue > 0 ? originalValue : 0.01)
                        }}
                        InputElement={<TextField label="Tarifa do Licenciado" size="small" />}
                    />
                    <Tooltip title="Essa tarifa será recebida a cada entrega solicitada dos seus lojistas" arrow>
                        <span>
                            <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                        </span>
                    </Tooltip>
                </div>
                <div className="fee-input-range">
                    <label htmlFor="">
                        Área de Atendimento
                        <Tooltip title="Define sua área de atendimento, todas as lojas nesse raio que não possuirem Hub associado você poderá se conectar" arrow>
                            <span>
                                <FontAwesomeIcon icon={faQuestionCircle} color="#000" />
                            </span>
                        </Tooltip>
                    </label>
                    <Slider
                        aria-label="serviceRadius"
                        defaultValue={serviceRadius}
                        onChange={(event, newValue) => setServiceRadius(newValue)}
                        valueLabelDisplay="auto"
                        color="#000"
                        shiftStep={30}
                        step={10}
                        marks={kmMarks}
                        min={10}
                        max={100}
                        size="large"
                    />
                </div>
                <div className="fee-submit">
                    <button type="button" onClick={submitFees}>Salvar Alterações</button>
                </div>
            </div>
            <div className="my-stores-navbar">
                <button type="button"
                    className={`nav-button ${content === "MY_STORES" ? "nav-active-button" : ""}`}
                    onClick={() => setContent("MY_STORES")}
                >Minhas Lojas</button>
                <button type="button"
                    className={`nav-button ${content === "NEARBY_STORES" ? "nav-active-button" : ""}`}
                    onClick={() => setContent("NEARBY_STORES")}>Lojas disponíveis</button>
            </div>
            <div className="my-stores-content">
                {content === "NEARBY_STORES" && <NearbyStores />}
                {content === "MY_STORES" && <MyConnections />}
            </div>
        </div>
    );
}