import { useEffect, useMemo, useState } from "react";
import AdminService from "../../../../../services/AdminService";
import StoreService from "../../../../../services/StoreService";
import InputMask from 'react-input-mask';
import './style.scss';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function ManageUsers(props) {
    const [user, setUser] = useState(props.selectedUser);
    const [store, setStore] = useState({
        address: {} // Garante que `store.address` exista
    });

    const adminService = useMemo(() => new AdminService(), []);
    const storeService = useMemo(() => new StoreService(), []);

    useEffect(() => {
        loadStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const store_type_map = {
        "LICENSED": "LICENCIADO",
        "HUB": "HUB",
        "STORE": "LOJISTA"
    }

    async function loadStore() {
        if (!user)
            return;

        const store = await adminService.getStoreByUser(user._id);
        setStore(store);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.includes('address.')) {
            const addressField = name.split('.')[1];
            setUser(prevUser => ({
                ...prevUser,
                address: {
                    ...prevUser.address,
                    [addressField]: value
                }
            }));
        } else if (name.includes('metadata.cnh')) {
            const cnhField = name.split('.')[1];
            setUser(prevUser => ({
                ...prevUser,
                metadata: {
                    ...prevUser.metadata,
                    [cnhField]: value
                }
            }));
        } else if (name.includes('metadata.vehicle.')) {
            const vehicleField = name.split('.')[2];
            setUser(prevUser => ({
                ...prevUser,
                metadata: {
                    ...prevUser.metadata,
                    vehicle: {
                        ...prevUser.metadata.vehicle,
                        [vehicleField]: value
                    }
                }
            }));

        } else {
            setUser(prevUser => ({
                ...prevUser,
                [name]: value
            }));
        }
    };

    const handleStoreChange = (e) => {
        const { name, value } = e.target;
        setStore(prevStore => {
            const keys = name.split('.');
            let updatedStore = { ...prevStore };
            let temp = updatedStore;
    
            for (let i = 0; i < keys.length - 1; i++) {
                temp[keys[i]] = temp[keys[i]] || {};
                temp = temp[keys[i]];
            }
    
            temp[keys[keys.length - 1]] = value;
            return updatedStore;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            props.setSpinner(true);
            await adminService.updateUser(user._id, user);
            await storeService.updateStore(store);
            props.setSpinner(false);
            Swal.fire({
                icon: 'success',
                title: 'Dados atualizado com sucesso!',
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    return (
        <div className="manage-users-container">
            <div className="manage-users-header">
                <button type="button" className="back-page" onClick={props.handleBackMenu}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <div className="manage-users-title">Alterar Cadastro</div>
            </div>
            <div className="form-title">Dados do Usuário</div>
            <div className="form-line"></div>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label>Nome:</label>
                    <input
                        disabled
                        type="text"
                        name="name"
                        value={user.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Email:</label>
                    <input
                        disabled
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>CPF:</label>
                    <InputMask
                        disabled
                        className="form-control"
                        placeholder='Digite seu CPF'
                        type="text"
                        name="cpf"
                        mask="999.999.999-99" // Máscara para CPF
                        value={user.cpf}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Telefone:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu telefone'
                        type="text"
                        name="cellNumber"
                        mask="(99) 99999-9999" // Máscara para Telefone
                        value={user.cellNumber}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>CEP:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu CEP'
                        type="text"
                        name="address.cep"
                        mask="99999-999" // Máscara para CEP
                        value={user.address.cep}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Cidade:</label>
                    <input
                        type="text"
                        name="address.city"
                        value={user.address.city}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Rua:</label>
                    <input
                        type="text"
                        name="address.street"
                        value={user.address.street}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Número:</label>
                    <input
                        type="number"
                        name="address.address_number"
                        value={user.address.address_number}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Bairro:</label>
                    <input
                        type="text"
                        name="address.neighborhood"
                        value={user.address.neighborhood}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Complemento:</label>
                    <input
                        type="text"
                        name="address.complement"
                        value={user.address.complement}
                        onChange={handleChange}
                    />
                </div>

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>CNH:</label>
                        <input
                            disabled
                            type="text"
                            name="metadata.cnh"
                            value={user.metadata.cnh}
                            onChange={handleChange}
                        />
                    </div>
                )}

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Modelo do Veículo</label>
                        <input
                            disabled
                            type="text"
                            name="metadata.vehicle.model"
                            value={user.metadata.vehicle.model}
                            onChange={handleChange}
                        />
                    </div>
                )}

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Placa do Veículo</label>
                        <input
                            disabled
                            type="text"
                            name="metadata.vehicle.vehiclePlate"
                            value={user.metadata.vehicle.vehiclePlate}
                            onChange={handleChange}
                        />
                    </div>
                )}

                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Tipo de Veículo:</label>
                        <select name="metadata.vehicle.type" id="" value={user.metadata.vehicle.type} onChange={handleChange} disabled>
                            <option value="motorcycle">Moto</option>
                            <option value="car">Carro</option>
                        </select>
                    </div>
                )}


                {user.type === "DRIVER" && (
                    <div className="form-control">
                        <label>Cor do Veículo</label>
                        <input
                            disabled
                            type="text"
                            name="metadata.vehicle.color"
                            value={user.metadata.vehicle.color}
                            onChange={handleChange}
                        />
                    </div>
                )}

                <br></br>

                {(user.type === "SHOPKEEPER" || user.type === "HUB" || user.type === "LICENSED") && (
                    <>
                        <div className="form-title">Dados da Loja ({store_type_map[store.type]})</div>
                        <div className="form-line"></div>

                        <div className="form-control">
                            <label>Razão Social:</label>
                            <input
                                disabled
                                type="text"
                                name="companyName"
                                value={store.companyName || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>Nome Fantasia:</label>
                            <input
                                disabled
                                type="text"
                                name="fantasyName"
                                value={store.fantasyName || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>CNPJ:</label>
                            <InputMask
                                disabled
                                className="form-control"
                                placeholder='Digite o CNPJ'
                                type="text"
                                name="cnpj"
                                mask="99.999.999/9999-99"
                                value={store.cnpj || ""}
                                onChange={handleStoreChange}
                            />
                        </div>

                        <div className="form-control">
                            <label>Telefone:</label>
                            <InputMask
                                className="form-control"
                                placeholder='Digite o telefone'
                                type="text"
                                name="cellNumber"
                                mask="(99) 99999-9999"
                                value={store.cellNumber || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>CEP:</label>
                            <InputMask
                                className="form-control"
                                placeholder='Digite o CEP'
                                type="text"
                                name="address.cep"
                                mask="99999-999"
                                value={store.address?.cep || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>Cidade:</label>
                            <input
                                type="text"
                                name="address.city"
                                value={store.address?.city || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>Rua:</label>
                            <input
                                type="text"
                                name="address.street"
                                value={store.address?.street || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>Número:</label>
                            <input
                                type="number"
                                name="address.number"
                                value={store.address?.number || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>Bairro:</label>
                            <input
                                type="text"
                                name="address.neighborhood"
                                value={store.address?.neighborhood || ""}
                                onChange={handleStoreChange}
                                required
                            />
                        </div>

                        <div className="form-control">
                            <label>Complemento:</label>
                            <input
                                type="text"
                                name="address.complement"
                                value={store.address?.complement || ""}
                                onChange={handleStoreChange}
                            />
                        </div>
                    </>
                )}


                <div className="form-control">
                    <button type="submit">Salvar Alterações</button>
                </div>
            </form>
        </div>
    );
}