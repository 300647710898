import React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
// import { LineChart } from '@mui/x-charts/LineChart';
import ReportsService from '../../../../../services/ReportsService'
import * as XLSX from 'xlsx';
import './style.scss';

// const OrdersData = {
//     data: [
//         { mes: "2024-01", deliveredOrders: 1200, canceledOrders: 150 },
//         { mes: "2024-02", deliveredOrders: 1100, canceledOrders: 120 },
//         { mes: "2024-03", deliveredOrders: 1300, canceledOrders: 110 },
//         { mes: "2024-04", deliveredOrders: 1050, canceledOrders: 90 },
//         { mes: "2024-05", deliveredOrders: 1050, canceledOrders: 90 },
//         { mes: "2024-06", deliveredOrders: 1050, canceledOrders: 90 }
//     ]
// };

// const TicketData = {
//     data: [
//         { mes: "2024-01", averageTicket: 10.5 },
//         { mes: "2024-02", averageTicket: 12.0 },
//         { mes: "2024-03", averageTicket: 9.5 },
//         { mes: "2024-04", averageTicket: 11.5 },
//         { mes: "2024-05", averageTicket: 9.8 },
//         { mes: "2024-06", averageTicket: 10.0 }
//     ]
// };

function formatPhoneNumber(cellNumber) {
    const str = cellNumber.toString();
    return `(${str.slice(0, 2)}) ${str.slice(2, 7)}-${str.slice(7)}`;
}

function formatCPF(cpf) {
    const str = cpf.toString();
    return `${str.slice(0, 3)}.${str.slice(3, 6)}.${str.slice(6, 9)}-${str.slice(9)}`;
}

function formatCNPJ(cnpj) {
    const str = cnpj.toString();
    return `${str.slice(0, 2)}.${str.slice(2, 5)}.${str.slice(5, 8)}/${str.slice(8, 12)}-${str.slice(12)}`;
}

function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
}

export default function Reports() {
    const generateExcel = async () => {
        const reportsService = new ReportsService();
        const data = await reportsService.getAllDebtors();

        if (data.length === 0) {
            alert("Nenhum dado encontrado para gerar o relatório.");
            return;
        }

        const tableData = data.map(item => ({
            "Valor devido": formatCurrency(item.amount.value),
            "Nome": item.user.name,
            "E-mail": item.user.email,
            "CPF": formatCPF(item.user.cpf),
            "Telefone": formatPhoneNumber(item.user.cellNumber),
            "Endereço": `${item.user.address.street}, ${item.user.address.address_number} - ${item.user.address.neighborhood}, ${item.user.address.city}`,
            "Loja": item.store.companyName,
            "CNPJ": formatCNPJ(item.store.cnpj),
            "Status": item.store.status
        }));

        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, 'tabela_lojistas.xlsx');
    };

    // const monthsOrdersData = OrdersData.data.map(item => item.mes);
    // const deliveredOrders = OrdersData.data.map(item => item.deliveredOrders);
    // const canceledOrders = OrdersData.data.map(item => item.canceledOrders);
    // const monthsTicketData = TicketData.data.map(item => item.mes);
    // const averageTicket = TicketData.data.map(item => item.averageTicket);

    return (
        <div className='reports-container'>
            {/* <div className="graphics-container">
                <div className="orders-graphic">
                    <h1>Relatório de Pedidos</h1>
                    <BarChart
                        className='bar-graphic'
                        xAxis={[
                            {
                                id: 'months',
                                data: monthsOrdersData,
                                scaleType: 'band',
                                label: 'Mês',
                                barGapRatio: 0.2,
                                categoryGapRatio: 0.3
                            }
                        ]}
                        series={[
                            {
                                data: deliveredOrders,
                                label: 'Pedidos Entregues',
                                color: '#000000'
                            },
                            {
                                data: canceledOrders,
                                label: 'Pedidos Cancelados',
                                color: '#808080'
                            }
                        ]}
                        height={300}
                        width={600}
                        fontSize='20px'
                    />
                </div>
                <div className="orders-graphic">
                    <h1>Ticket Médio da Loja</h1>
                    <LineChart
                        xAxis={[
                            {
                                id: 'months',
                                data: monthsTicketData,
                                scaleType: 'band',
                                label: 'Mês'
                            }
                        ]}
                        series={[
                            {
                                data: averageTicket,
                                color: '#000000'
                            },
                        ]}
                        height={300}
                        width={600}
                    />
                </div>
            </div> */}
            <div className="debtors-ranking">
                <h1>Ranking de Devedores</h1>
                <button className="debtors-button" onClick={generateExcel}>
                    Exportar
                </button>
            </div>
        </div>
    );
}