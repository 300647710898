import React, { useContext } from 'react';
import '../assets/scss/filters.scss';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from '@mui/material';
import { UserContext } from '../../../../../context/UserContext';

const IntegrationsFilters = ({ filters, setFilters }) => {
    const { stores } = useContext(UserContext);

    const handleChangeStore = (event) => {
        const { value } = event.target;

        // Se "TODAS" for selecionada, define todas as lojas, senão mantém a seleção
        if (value.includes("ALL")) {
            setFilters((prev) => ({
                ...prev,
                storeId: filters.storeId.length === stores.length
                    ? [] : stores.map((store) => store._id)
            }));
        } else {
            setFilters((prev) => ({
                ...prev,
                storeId: value
            }));
        }
    };

    return (
        <div className="integrations-filters">
            <div className="filters-fields">
                <FormControl fullWidth size="small">
                    <InputLabel>Loja</InputLabel>
                    <Select
                        multiple
                        value={filters.storeId}
                        onChange={handleChangeStore}
                        renderValue={(selected) =>
                            selected.length === stores.length ? "TODAS" : selected.map(id => stores.find(store => store._id === id)?.companyName).join(", ")
                        }
                    >
                        <MenuItem value="ALL">
                            <Checkbox checked={filters.storeId.length === stores.length} />
                            <ListItemText primary="TODAS" />
                        </MenuItem>
                        {stores.map((store) => (
                            <MenuItem key={store._id} value={store._id}>
                                <Checkbox checked={filters.storeId.includes(store._id)} />
                                <ListItemText primary={store.companyName} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default IntegrationsFilters;
