import React from 'react';
import '../assets/scss/filters.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { TextField, Button } from '@mui/material';
import InputMask from 'react-input-mask';

const PendingUsersFilters = ({ onApplyFilters, filters, setFilters }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "cpf") {
            setFilters((prev) => ({
                ...prev,
                [name]: value.replace(/\D/g, ''),
            }));
        } else {
            setFilters((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const cleanFilters = () => {
        onApplyFilters({
            name: "",
            cpf: ""
        });
    };

    return (
        <div className="pending-filters">
            <div className="filters-fields">
                <TextField
                    label="Nome do Usuário"
                    fullWidth
                    size="small"
                    name="name"
                    value={filters.name}
                    onChange={handleChange}
                    placeholder="Digite o nome do usuário"
                />

                <InputMask
                    mask="999.999.999-99"
                    value={filters.cpf}
                    onChange={handleChange}
                >
                    {() => (
                        <TextField
                            label="CPF"
                            fullWidth
                            size="small"
                            name="cpf"
                            placeholder="Digite o CPF"
                        />
                    )}
                </InputMask>
            </div>

            <div className="filters-buttons">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={cleanFilters}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
            </div>
        </div>
    );
};

export default PendingUsersFilters;
