import axios from "axios";
import HttpService from "./HttpService.js";

export default class OrderService {
    constructor() {
        this._http = new HttpService();
        this._urlBase = `${process.env.REACT_APP_DELIVERIES_API}/reports`;
    }

    async getAllDebtors() {
        const response = await axios.get(`${this._urlBase}/all_debtors`);
        return response.data;
    }
}
