import HttpService from "./HttpService.js";
import axios from "axios";

export default class WalletService {
    constructor() {
        this._http = new HttpService();
        this._baseUrl = `${process.env.REACT_APP_DELIVERIES_API}/wallet`;
    }

    async loadWallet(user_id) {
        const response = await this._http.get(this._baseUrl + `/${user_id}`);
        return response;
    }

    async updateWallet(id, data) {
        const response = await axios.put(`${this._baseUrl}/${id}`, data);
        return response;
    }

    async loadTransactions(walletId, filters, page) {
        const queryParams = new URLSearchParams();
        if (filters.startDate) queryParams.append('startDate', filters.startDate);
        if (filters.endDate) queryParams.append('endDate', filters.endDate);
        if (page) queryParams.append('page', page.toString());

        const url = `${this._baseUrl}/get_trasactions/${walletId}?${queryParams.toString()}`;
        const response = await axios.get(url);
        return response.data;
    }

    async newIntentDeposit(storeId, amount) {
        const url = this._baseUrl + `/${storeId}/deposits`;
        const body = {
            "amount": amount,
            "description": "Novo deposito via PIX"
        };
        const { data } = await axios.post(url, body);
        return data;
    }
}
