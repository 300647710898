import { useContext, useEffect, useMemo, useState } from "react";
import IntegrationService from "../../../../../services/IntegrationService";
import { Oval } from "react-loader-spinner";
import '../../assets/scss/integration.scss';
import { UserContext } from "../../../../context/UserContext";
import ifoodLogo from '../../../../../assets/img/ifood_logo.png';
import anotaAiLogo from '../../../../../assets/img/anota_ai_logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faPlus, faTrash, faWarning } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import IntegrationSwitch from "./components/IntegrationSwitch";
import Swal from "sweetalert2";
import CopyInput from "./components/CopyInput";
import CreateIntegrationModal from "./components/CreateIntegrationModal";

export default function Integrations() {
    const { selectedStore } = useContext(UserContext);
    const [integrations, setIntegrations] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const integrationService = useMemo(() => new IntegrationService(), []);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalIntegrations: 0,
        totalPages: 1,
    });

    const previousPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage - 1
        }));
        setLoading(false);
    }

    const nextPage = () => {
        setPagination((prevFilters) => ({
            ...prevFilters,
            currentPage: pagination.currentPage + 1
        }));
        setLoading(false);
    }

    async function loadIntegration() {
        if (loading)
            return;
        setLoading(true);
        setSpinner(true);
        const response = await integrationService.getIntegrationsByUser(pagination.currentPage, [selectedStore._id]);
        setTimeout(() => {
            setIntegrations(response.data);
            setSpinner(false);
        }, 1000);
    }

    async function handleCreateIntegration(data) {
        setSpinner(true);
        await integrationService.createIntegration(data);
        setTimeout(() => {
            Swal.fire({
                title: `Integração criada com sucesso`,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            });
            setSpinner(false);
            setLoading(false);
        }, 1000);
    };

    async function enableAndDisableIntegration(id, active) {
        setSpinner(true);
        await integrationService.enableAndDisable(id, active);
        setTimeout(() => {
            Swal.fire({
                title: `Integração ${active ? "Ativada" : "Desativada"} com sucesso`,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
            });
            setSpinner(false);
            setLoading(false);
        }, 1000);
    }

    async function removeIntegration(id) {
        const result = await Swal.fire({
            title: "Você deseja remover esta integração?",
            text: "Essa ação é irreversível",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, remover!",
            confirmButtonColor: "#EA1D2C",
            cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
            setSpinner(true);
            await integrationService.destroy(id);
            setTimeout(() => {
                Swal.fire({
                    title: `Integração removida com sucesso`,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                });
                setSpinner(false);
                setLoading(false);
            }, 1000);
        }

    }

    useEffect(() => {
        loadIntegration();
        // eslint-disable-next-line
    }, [loading]);

    return (
        <div className={`config-integration ${spinner ? `loading-integration` : ``}`}>
            <div className="config-integration-header">
                <button type="button" onClick={() => setModalOpen(true)}><FontAwesomeIcon icon={faPlus} style={{ height: 14, marginRight: 5 }} />Adicionar Integração</button>
            </div>

            {spinner ? (
                <Oval
                    visible={loading}
                    height="50"
                    width="50"
                    color="#000"
                    secondaryColor="#ccc"
                    radius="1"
                />
            ) :
                (
                    <table className="user-integrations">
                        <thead>
                            <tr>
                                <th>Canal de Vendas</th>
                                <th>Token/Merchant ID</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {integrations.length > 0 && integrations.map((integration) => (
                                <tr className="integration-item" key={integration._id}>
                                    <td className="type-td">
                                        <span className={`type ${integration.type === "IFOOD" ? 'ifood-type' : 'anota-ai-type'}`}>
                                            {integration.type === "IFOOD" ? <img className='type-logo' src={ifoodLogo} alt='IFood Logo'></img> : <img className='type-logo' src={anotaAiLogo} alt='Anota AI Logo'></img>}
                                        </span>
                                    </td>
                                    <td className="token-td">
                                        <CopyInput integration={integration} />
                                    </td>
                                    <td className="actions-buttons">
                                        {integration.status === "APPROVED" ?
                                            <IntegrationSwitch id={integration._id} initialActive={integration.active} enableAndDisableIntegration={enableAndDisableIntegration} /> :
                                            <Tooltip title="Essa integração está pendente de aprovação" arrow>
                                                <span>
                                                    <FontAwesomeIcon icon={faWarning} color="#DBA827" style={{ height: 24, cursor: "pointer" }} />
                                                </span>
                                            </Tooltip>}
                                        <button type="button" className="destroy" onClick={() => removeIntegration(integration._id)}><FontAwesomeIcon icon={faTrash} /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                )}
            <div className="pagination-container">
                {pagination.currentPage > 1 && <button type="button" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /></button>}
                {pagination.totalPages > 1 && <span>{pagination.currentPage}</span>}
                {pagination.currentPage < pagination.totalPages && <button type="button" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} /></button>}
            </div>
            <CreateIntegrationModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleCreateIntegration}
            />
        </div>
    );
}