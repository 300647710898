import React, { useContext, useState } from "react";
import { Modal, Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { UserContext } from "../../../../../context/UserContext";

const CreateIntegrationModal = ({ open, onClose, onSubmit }) => {
    const { selectedStore } = useContext(UserContext);
    const [formData, setFormData] = useState({
        type: "",
        platformId: "",
        token: "",
        store_id: selectedStore._id,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.type) newErrors.type = "Tipo é obrigatório";
        if (formData.type === "IFOOD" && !formData.platformId) newErrors.platformId = "Platform ID é obrigatório";
        if (formData.type === "ANOTA_AI" && !formData.token) newErrors.token = "Token é obrigatório";
        if (!formData.store_id) newErrors.store_id = "Store ID é obrigatório";
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const dataToSend = new FormData();
        dataToSend.append("type", formData.type);
        dataToSend.append("store_id", formData.store_id);

        if (formData.type === "IFOOD") {
            dataToSend.append("platformId", formData.platformId);
        } else if (formData.type === "ANOTA_AI") {
            dataToSend.append("token", formData.token);
        }

        onSubmit(Object.fromEntries(dataToSend)); // Converte FormData para objeto antes de enviar
        onClose();
        setFormData({ type: "", platformId: "", token: "", store_id: "" }); // Reseta o formulário
        setErrors({});
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2" mb={2}>
                    Criar Nova Integração
                </Typography>

                <form onSubmit={handleSubmit}>
                    {/* Select para Tipo */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Canal de Vendas</InputLabel>
                        <Select
                            value={formData.type}
                            name="type"
                            onChange={handleChange}
                            error={!!errors.type}
                        >
                            <MenuItem value="IFOOD">IFOOD</MenuItem>
                            <MenuItem value="ANOTA_AI">ANOTA AI</MenuItem>
                        </Select>
                        {errors.type && <Typography color="error">{errors.type}</Typography>}
                    </FormControl>

                    {/* Campo dinâmico baseado no Tipo */}
                    {formData.type === "IFOOD" && (
                        <TextField
                            fullWidth
                            label="Merchant ID"
                            variant="outlined"
                            name="platformId"
                            value={formData.platformId}
                            onChange={handleChange}
                            error={!!errors.platformId}
                            helperText={errors.platformId}
                            margin="normal"
                        />
                    )}

                    {formData.type === "ANOTA_AI" && (
                        <TextField
                            fullWidth
                            label="Token"
                            variant="outlined"
                            name="token"
                            value={formData.token}
                            onChange={handleChange}
                            error={!!errors.token}
                            helperText={errors.token}
                            margin="normal"
                        />
                    )}

                    {/* Botões */}
                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button variant="contained" style={{ backgroundColor: '#6c6768'}} onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#000'}}>
                            Criar Integração
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateIntegrationModal;
