import React from 'react';
import InputMask from 'react-input-mask';

function UserForm(props) {
    const formDataUser = props.formUserData;
    const formDataStore = props.formStoreData;
    const handleChangeUser = props.handleChangeUser;
    const handleChangeStore = props.handleChangeStore;

    return (
        <div className='User-form'>
            <div className='form-title'>Fale com um especialista</div>
            <div className='form-subtitle'>Preencha os campos abaixo e nosso time entrará em contato prontamente via email:</div>

            {/* Nome Completo */}
            <label className='form-label mb1'>
                <div className='label-text'>Nome Completo*</div>
                <input
                    className="form-control-solo"
                    type="text"
                    placeholder='Digite seu nome'
                    name="name"
                    value={formDataUser.name}
                    onChange={handleChangeUser}
                    required
                />
            </label>

            {/* Telefone e E-mail */}
            <div className="form-duo-input">
                <label className='form-label mb1'>
                    <div className='label-text'>Telefone*</div>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu telefone'
                        type="text"
                        name="cellNumber"
                        mask="(99) 99999-9999"
                        value={formDataUser.cellNumber}
                        onChange={handleChangeUser}
                        required
                    />
                </label>
                <label className='form-label mb1'>
                    <div className='label-text'>E-mail*</div>
                    <input
                        className="form-control"
                        type="email"
                        placeholder='Digite seu e-mail'
                        name="email"
                        value={formDataUser.email}
                        onChange={handleChangeUser}
                        required
                    />
                </label>
            </div>

            {/* Nome da Empresa e Cargo */}
            <div className="form-duo-input">
                <label className='form-label mb2'>
                    <div className='label-text'>Nome da Empresa*</div>
                    <input
                        className="form-control"
                        placeholder='Digite o nome da empresa'
                        type="text"
                        name="companyName"
                        value={formDataStore.companyName}
                        onChange={handleChangeStore}
                        required
                    />
                </label>
                <label className='form-label mb2'>
                    <div className='label-text'>Cargo*</div>
                    <input
                        className="form-control"
                        placeholder='Digite o seu cargo'
                        type="text"
                        name="position"
                        value={formDataUser.position}
                        onChange={handleChangeUser}
                        required
                    />
                </label>
            </div>

            {/* Nº de Entregas e Tipo de Estabelecimento */}
            <div className="form-duo-input">
                <label className='form-label mb2'>
                    <div className='label-text'>Nº de Entregas*</div>
                    <select
                        className="form-control-select"
                        name="deliveryLane"
                        value={formDataStore.deliveryLane}
                        onChange={handleChangeStore}
                        defaultValue={""}
                        required
                    >
                        <option value="" disabled>Selecione uma opção</option>
                        <option value="1-50">1-50</option>
                        <option value="51-200">51-200</option>
                        <option value="201-800">201-800</option>
                        <option value="800+">800+</option>
                    </select>
                </label>
                <label className='form-label mb2'>
                    <div className='label-text'>Tipo de Estabelecimento*</div>
                    <select
                        className="form-control-select"
                        name="type"
                        value={formDataStore.type}
                        onChange={handleChangeStore}
                        defaultValue={""}
                        required
                    >
                        <option value="" disabled>Selecione uma opção</option>
                        <option value="LICENSED">Licenciado</option>
                        <option value="HUB">Hub</option>
                        <option value="STORE">Loja/Restaurante</option>
                    </select>
                </label>
            </div>

            {/* Botão de envio */}
            <button type="submit" className="progress-button">
                Avançar
            </button>
        </div>
    );
}

export default UserForm;