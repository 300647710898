import React from "react";
import { Modal, Box, Typography, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";

const CreateVacancy = ({ open, onClose, onSubmit, formData, setFormData, isUpdated }) => {

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleStatusChange = (e) => {
        setFormData({ ...formData, status: e.target.checked ? "OPEN" : "CLOSED" });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const dataToSend = new FormData();
        dataToSend.append("observation", formData.observation);
        dataToSend.append("status", formData.status);

        onSubmit(Object.fromEntries(dataToSend)); // Converte FormData para objeto antes de enviar
        onClose();
        setFormData({ observation: "", status: "CLOSED" }); // Reseta o formulário
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2" mb={2}>
                    {isUpdated ? "Minha vaga anunciada" : "Anunciar nova vaga"}
                </Typography>

                <form onSubmit={handleSubmit}>
                    {/* Campo de detalhes da vaga */}
                    <TextField
                        fullWidth
                        label="Detalhes sobre a vaga"
                        placeholder="As tarifas repassadas na vaga estão na tela de controle de tarifas"
                        name="observation"
                        multiline
                        value={formData.observation}
                        onChange={handleChange}
                        margin="normal"
                    />

                    {/* Checkbox para status da vaga */}
                    <FormControlLabel
                        control={<Checkbox checked={formData.status === "OPEN"} onChange={handleStatusChange} />}
                        label={formData.status === "OPEN" ? "Vaga aberta" : "Vaga fechada"}
                    />

                    {/* Botões */}
                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button variant="contained" style={{ backgroundColor: '#6c6768' }} onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#000' }}>
                            {isUpdated ? "Salvar" : "Anunciar"}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateVacancy;